import { API, Auth } from 'aws-amplify';
import { Field, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Spinner from '../../components/Spinner';
import { UserContext } from '../../contexts/userContext';
import { getUser } from '../../graphql/queries';
import { toast } from 'react-toastify';
const formData = {
    phone: '',
    password: ''
}

const ConfirmCode = {
    code: '',
}

const formSchema = Yup.object().shape({
    phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required'),
    password: Yup.string().required('Required')
})

const confirmSchema = Yup.string().matches(/^[0-9]*$/, "Code must be number").min(6, "code is not valid").max(6, "code is not valid").required('Required')

const SignIn = () => {
    const navigate = useNavigate();
    const { updateUser } = useContext(UserContext);
    const [confirmedUser, setConfirmedUser] = useState(true);
    const [spinner, showSpinner] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [userName, setUserName] = useState('');
    const [error, setError] = useState('');

    const signIn = async (values) => {
        try {
            setUserName(`+91${values.phone}`)
            showSpinner(true)
            const cognitoUser = await Auth.signIn({ username: `+91${values.phone}`, password: values.password });
            if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') navigate('/reset-password')

            const userResponse = await API.graphql({
                query: getUser,
                variables: { id: cognitoUser.username }
            })

            const user = userResponse.data.getUser
            if (!user?.deleted) {
                updateUser(user)

                if (user.role === 'ADMIN' || user.role === 'OWNER') {
                    navigate('/youth-venture/dashboard')
                } else if (user.role === 'TEACHER') {
                    navigate('/youth-venture/course')
                } else {
                    toast.error('You don\'t have access')
                }
            } else {
                toast.error('You don\'t have access')
            }


            showSpinner(false)
        } catch (error) {
            console.log('error', error)
            if (error.message.toLowerCase() === 'user is not confirmed.') ResendConfirmationCode(`+91${values.phone}`);
            else if (error.name === 'NotAuthorizedException') setError(error.message)
            showSpinner(false)
        }
    }

    async function ResendConfirmationCode(username) {
        try {
            await Auth.resendSignUp(username);
            setConfirmedUser(false);
            toast.success('code sent successfully');
        } catch (err) {
            toast.error(error.message)
            console.log('error resending code: ', err);

        }
    }

    async function confirmSignUp(code) {
        try {
            showSpinner(true)
            await Auth.confirmSignUp(userName, code);
            setConfirmedUser(true)
            showSpinner(false)
        } catch (error) {
            toast.error(error.message)
            showSpinner(false)
            console.log('error confirming sign up', error);
        }
    }

    return (
        <div className="d-flex bg-auth " style={{ display: 'block' }}>
            <div className="container">
                <div className="row vh-100 align-items-center">
                    <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-lg-block">
                        <div className="text-center">
                            <img src="/img/login.svg" alt="..." className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                        <h1 className="display-3 text-center">
                            Going To School
                        </h1>
                        {confirmedUser ?
                            <div>
                                <h3 className="display-4 text-center mb-5">
                                    Sign In
                                </h3>
                                <p className='text-danger text-center'>{error}</p>

                                <Formik initialValues={formData} validationSchema={formSchema} onSubmit={(values) => { signIn(values) }}>
                                    {({ handleChange, handleSubmit, values, errors }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className='form-group'>
                                                <label htmlFor='phone' className='form-label'>Mobile Number</label>
                                                <input type='number' className='form-control' id='phone' name='phone' value={values.phone} onChange={handleChange} placeholder="e.g. 9988776655" />
                                                <span className='text-danger'>{errors.phone}</span>
                                            </div>
                                            <div className='form-group'>
                                                <div className="row">
                                                    <div className="col">
                                                        <label className="form-label">
                                                            Password
                                                        </label>
                                                    </div>
                                                    <div className="col-auto cursor-pointer" onClick={() => { navigate('/forgot-password') }}>
                                                        <p className="form-text small text-muted">
                                                            Forgot password?
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="input-group input-group-merge">
                                                    <Field className="form-control" type={showPassword ? 'text' : 'password'} name='password' value={values.password} placeholder="Enter your password" onChange={handleChange} />
                                                    <span className="input-group-text">
                                                        <i className={showPassword ? 'fe fe-eye' : 'fe fe-eye-off'} onClick={() => setShowPassword(!showPassword)}></i>
                                                    </span>
                                                </div>
                                                <span className='text-danger'>{errors.password}</span>
                                            </div>
                                            <Spinner display={spinner}>
                                                <div className='form-group'>
                                                    <button type='submit' className='btn btn-primary btn-lg w-100'>Continue</button>
                                                </div>
                                            </Spinner>
                                        </form>
                                    )}
                                </Formik>
                            </div> :
                            (<div>
                                <h3 className="display-4 text-center mb-5">
                                    Confirm User
                                </h3>
                                <Formik initialValues={ConfirmCode} validationSchema={confirmSchema} onSubmit={(values) => { confirmSignUp(values.code) }}>
                                    {({ handleChange, handleSubmit, values, errors }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className='form-group'>
                                                <label htmlFor='code' className='form-label'>Code</label>
                                                <input type={'text'} id='code' className='form-control mfa-code' format='######' mask='_' placeholder='0 0 0 0 0 0' value={values.code} maxLength="6" onChange={handleChange} disabled={values.code.length > 6} />
                                                {/* <input type={'number'} className='form-control' id='code' value={values.code} onChange={handleChange} placeholder="" /> */}
                                                <span className='text-danger'>{errors.code}</span>
                                            </div>
                                            <Spinner display={spinner}>
                                                <div className='form-group'>
                                                    <button type='submit' className='btn btn-primary btn-lg w-100'>Continue</button>
                                                </div>
                                            </Spinner>
                                        </form>
                                    )}
                                </Formik>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;