import { useEffect, useState } from "react";
import Header from "../../components/Header"
import InfoCard from "../../components/InfoCard"
import { API } from "aws-amplify";
import Spinner from "../../components/Spinner";
import moment from 'moment';

const listYouthVentureCities = /* GraphQL */ `
  query ListYouthVentureCities(
    $filter: ModelYouthVentureCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYouthVentureCities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

const searchUsers = /* GraphQL */ `
        query SearchUsers($filter: SearchableUserFilterInput) {
            searchUsers(filter: $filter) {
            total
            }
        }
        `;

const searchCoursesAndSessions = /* GraphQL */ `
        query SearchCoursesAndSessions(
            $courseFilter: SearchableCourseFilterInput
            $sessionFilter:SearchableSessionFilterInput
            $internshipFilter:SearchableInternshipFilterInput
            )
            {
            searchCourses(filter: $courseFilter) {
            total
            }
            searchSessions(filter: $sessionFilter) {
            total
            }
            searchInternships(filter:$internshipFilter){
                total
            }
        }
        `;


const cardData = /* GraphQL */ `
        query cardData(
            $courseFilter: SearchableCourseFilterInput
            $sessionFilter:SearchableSessionFilterInput
            $internshipFilter:SearchableInternshipFilterInput
            )
            {
            searchCourses(filter: $courseFilter) {
                total
            }
            searchSessions(filter: $sessionFilter) {
                total
            }
            searchInternships(filter: $internshipFilter){
                total
            }
        }
        `;

const upComingData = /* GraphQL */ `
        query upComingData(
            $upComingCourseFilter: SearchableCourseFilterInput
            $upComingSessionFilter:SearchableSessionFilterInput
            )
            {
            searchCourses(filter: $upComingCourseFilter) {
            total
            }
            searchSessions(filter: $upComingSessionFilter) {
            total
            }
        }
        `;
const searchCourses = /* GraphQL */ `
        query SearchCourses($filter: SearchableCourseFilterInput) {
            searchCourses(filter: $filter) {
            items {
                id
                name
                start
            }
            total
            }
        }
    `;

const Dashboard = () => {

    const [data, setData] = useState({
        banglore: 0, courses: 0, goa: 0, internships: 0, mumbai: 0, professors: 0, sessions: 0, users: 0
    })
    const [weeklyResult, setWeeklyResult] = useState({ weeklyCourses: 0, weeklySessions: 0, weeklyInternships: 0 })
    const [dailyResult, setDailyResult] = useState({ courses: 0, sessions: 0, internships: 0 })
    const [upComingCourses, setUpComingCourses] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [filteredCity, setFilteredCity] = useState('');
    const [cityList, setCityList] = useState([]);


    const getCities = async () => {
        try {
            let response = await API.graphql({
                query: listYouthVentureCities,
                variables: {
                    filter: { name: { ne: "GOA" } }
                }
            })

            response.data.listYouthVentureCities.items = response?.data?.listYouthVentureCities?.items?.map((city) => ({ name: city?.name, value: city?.id }))
            setCityList(response?.data?.listYouthVentureCities?.items)
        } catch (error) {
            console.log(error)
        }
    }


    const getStartOfWeek = () => {
        const today = new Date();
        const startOfWeek = today.getDate() - today.getDay();
        return new Date(today.setDate(startOfWeek));
    };

    const getEndOfWeek = () => {
        const today = new Date();
        const endOfWeek = today.getDate() + (6 - today.getDay());
        return new Date(today.setDate(endOfWeek));
    };

    function getStartOfDay() {
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);
        return startOfDay;
    }

    function getEndOfDay() {
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay;
    }


    const getDetails = async () => {
        setSpinner(true);
        try {
            let userFilter = { role: { eq: "USER" }, deleted: { eq: false } }
            let professorFilter = { deleted: { eq: false }, role: { eq: "TEACHER" } }
            let courseFilter = { deleted: { eq: false } }
            let sessionFilter = {
                deleted: { eq: false }, start: {
                    gte: getStartOfWeek().toISOString(),
                    lte: getEndOfWeek().toISOString(),
                }
            }
            let internshipFilter = { deleted: { eq: false } }
            if (filteredCity) {
                userFilter = { and: { role: { eq: "USER" }, deleted: { eq: false }, userCityId: { eq: filteredCity } } }
                professorFilter = { and: { deleted: { eq: false }, role: { eq: "TEACHER" }, userCityId: { eq: filteredCity } } }
                courseFilter = { and: { deleted: { eq: false }, courseCityId: { eq: filteredCity } } }
                internshipFilter = { and: { deleted: { eq: false }, internshipCityId: { eq: filteredCity } } }
                sessionFilter = {
                    deleted: { eq: false }, start: {
                        gte: getStartOfWeek().toISOString(),
                        lte: getEndOfWeek().toISOString(),
                    }, sessionCityId: { eq: filteredCity }
                }
            }
            let response = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: userFilter
                }
            })
            setData((prev) => ({ ...prev, users: response?.data?.searchUsers?.total }))

            response = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: professorFilter
                }
            })
            setData((prev) => ({ ...prev, professors: response?.data?.searchUsers?.total }))

            // response = await API.graphql({
            //     query: searchUsers,
            //     variables: {
            //         filter: { deleted: { eq: false }, role: { eq: "USER" }, userCityId: { eq: "f30acf5d-ac00-40b9-98a0-669063b378b0" } }
            //     }
            // })
            // setData((prev) => ({ ...prev, mumbai: response?.data?.searchUsers?.total }))

            // response = await API.graphql({
            //     query: searchUsers,
            //     variables: {
            //         filter: { deleted: { eq: false }, role: { eq: "USER" }, userCityId: { eq: "d37358ad-a7c9-42eb-b2a5-9da2b6fe10e3" } }
            //     }
            // })
            // setData((prev) => ({ ...prev, banglore: response?.data?.searchUsers?.total }))

            // response = await API.graphql({
            //     query: searchUsers,
            //     variables: {
            //         filter: { deleted: { eq: false }, role: { eq: "USER" }, userCityId: { eq: "7c966103-22b5-4ce5-819d-b99c2bcc89b4" } }
            //     }
            // })
            // setData((prev) => ({ ...prev, goa: response?.data?.searchUsers?.total }))

            response = await API.graphql({
                query: searchCoursesAndSessions,
                variables: {
                    courseFilter,
                    sessionFilter,
                    internshipFilter
                }
            })
            setData((prev) => ({ ...prev, courses: response?.data?.searchCourses?.total, sessions: response?.data?.searchSessions?.total, internships: response?.data?.searchInternships?.total }))
            setSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getWeeklyData = async () => {
        try {
            let courseFilter = {
                and: {
                    deleted: { eq: false }, start: {
                        lte: getEndOfWeek().toISOString(),
                    }, end: {
                        gte: getStartOfWeek().toISOString()
                    }
                }
            }
            let sessionFilter = {
                and: {
                    deleted: { eq: false }, start: {
                        gte: getStartOfWeek().toISOString(),
                        lte: getEndOfWeek().toISOString(),
                    }
                }
            }

            let internshipFilter = {
                and: {
                    deleted: { eq: false }, start: {
                        lte: getEndOfWeek().toISOString(),
                    }, end: {
                        gte: getStartOfWeek().toISOString()
                    }
                }
            }

            if (filteredCity) {
                courseFilter = { and: { deleted: { eq: false }, start: { lte: getEndOfWeek().toISOString(), }, end: { gte: getStartOfWeek().toISOString() }, courseCityId: { eq: filteredCity } } }
                sessionFilter = { and: { deleted: { eq: false }, start: { gte: getStartOfWeek().toISOString(), lte: getEndOfWeek().toISOString() }, sessionCityId: { eq: filteredCity } } }
                internshipFilter = { and: { deleted: { eq: false }, start: { lte: getEndOfWeek().toISOString(), }, end: { gte: getStartOfWeek().toISOString() }, internshipCityId: { eq: filteredCity } } }
            }

            let response = await API.graphql({
                query: cardData,
                variables: {
                    courseFilter,
                    sessionFilter,
                    internshipFilter
                }
            })
            setWeeklyResult((prev) => ({ ...prev, weeklyCourses: response?.data?.searchCourses?.total, weeklySessions: response?.data?.searchSessions?.total, weeklyInternships: response?.data?.searchInternships?.total }))
        } catch (error) {
            console.log(error)
        }
    }

    const getDailyData = async () => {
        try {
            let courseFilter = {
                and: {
                    deleted: { eq: false }, start: {
                        lte: getStartOfDay().toISOString(),
                    }, end: {
                        gte: getEndOfDay().toISOString()
                    }
                }
            }
            let sessionFilter = {
                and: {
                    deleted: { eq: false }, start: {
                        gte: getStartOfWeek().toISOString(),
                        lte: getEndOfWeek().toISOString(),
                    }
                }
            }

            let internshipFilter = {
                and: {
                    deleted: { eq: false }, start: {
                        lte: getStartOfDay().toISOString(),
                    }, end: {
                        gte: getEndOfDay().toISOString()
                    }
                }
            }

            if (filteredCity) {
                courseFilter = { and: { deleted: { eq: false }, start: { lte: getStartOfDay().toISOString(), }, end: { gte: getEndOfDay().toISOString() }, courseCityId: { eq: filteredCity } } }
                sessionFilter = { and: { deleted: { eq: false }, start: { gte: getStartOfWeek().toISOString(), lte: getEndOfWeek().toISOString() }, sessionCityId: { eq: filteredCity } } }
                internshipFilter = { and: { deleted: { eq: false }, start: { lte: getStartOfDay().toISOString(), }, end: { gte: getEndOfDay().toISOString() }, internshipCityId: { eq: filteredCity } } }
            }

            let response = await API.graphql({
                query: cardData,
                variables: {
                    courseFilter,
                    sessionFilter,
                    internshipFilter
                }
            })
            setDailyResult((prev) => ({ ...prev, courses: response?.data?.searchCourses?.total, sessions: response?.data?.searchSessions?.total, internships: response?.data?.searchInternships?.total }))
        } catch (error) {
            console.log(error)
        }
    }


    const getUpComingCourses = async () => {
        try {
            let filter = {
                and: {
                    deleted: { eq: false },
                    start: {
                        gte: new Date().toISOString(),
                    }
                }
            }

            if (filteredCity) {
                filter = {
                    and: {
                        deleted: { eq: false },
                        start: {
                            gte: new Date().toISOString(),
                        },
                        courseCityId: { eq: filteredCity }
                    }
                }
            }
            const response = await API.graphql({
                query: searchCourses,
                variables: {
                    filter
                }
            })
            setUpComingCourses(response?.data?.searchCourses?.items)

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getCities()
    }, [])

    useEffect(() => {
        getDetails()
        getWeeklyData()
        getDailyData()
        getUpComingCourses()
    }, [filteredCity])

    return (
        <div>
            <Header title='Youth Venture' name='Dashboard' />
            <div className="container-fluid">
                <div className="row m-1 mb-3">
                    <div className="col"></div>
                    <div className='col-auto px-1'>
                        <div className='dropdown '>
                            <span>Filter by City : </span>
                            <select className="filter-dropdown dropdown-toggle border " onChange={(e) => setFilteredCity(e.target.value)}>
                                <option className="dropdown-item" value={''}>{'All'}</option>
                                {cityList?.map((role) => (
                                    <option className="dropdown-item" value={role?.value} key={role?.value}>{role?.name}</option>
                                ))}
                            </select>
                        </div>

                    </div>
                </div>
                <Spinner display={spinner}>
                    <div className="row">
                        <div className="col">
                            <InfoCard title='Students'>{data?.users}</InfoCard>
                        </div>
                        <div className="col">
                            <InfoCard title='Professors'>{data?.professors}</InfoCard>
                        </div>
                        <div className="col">
                            <InfoCard title='Courses'>{data?.courses}</InfoCard>
                        </div>
                        <div className="col">
                            <InfoCard title='Sessions Running'>{data?.sessions}</InfoCard>
                        </div>
                        <div className="col">
                            <InfoCard title='Internships'>{data?.internships}</InfoCard>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12 col-xl-8"></div>
                        <div className="col-12 col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Upcoming Courses</h4>
                                </div>
                                <div className="card-body">
                                    <div className="list-group list-group-flush my-n3">
                                        <div className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h5 className="mb-0">
                                                        Mumbai
                                                    </h5>

                                                </div>
                                                <div className="col-auto">
                                                    <time className="small text-muted" >
                                                        {data?.mumbai}
                                                    </time>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h5 className="mb-0">
                                                        Bangalore
                                                    </h5>

                                                </div>
                                                <div className="col-auto">
                                                    <time className="small text-muted" >
                                                        {data?.banglore}
                                                    </time>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h5 className="mb-0">
                                                        Goa
                                                    </h5>

                                                </div>
                                                <div className="col-auto">
                                                    <time className="small text-muted" >
                                                        {data?.goa}
                                                    </time>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="d-lg-flex gap-4 ">
                        <div className="card col ">
                            <div className="card-header">
                                <h3 className="m-0">Today</h3>
                            </div>
                            <div className="card-body">
                                <div className="list-group list-group-flush my-n3">
                                    <div className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="mb-0">
                                                    Courses
                                                </h4>

                                            </div>
                                            <div className="col-auto">
                                                <time className="small text-muted" >
                                                    {dailyResult?.courses}
                                                </time>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="mb-0">
                                                    Sessions
                                                </h4>

                                            </div>
                                            <div className="col-auto">
                                                <time className="small text-muted" >
                                                    {dailyResult?.sessions}
                                                </time>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="mb-0">
                                                    Internships
                                                </h4>

                                            </div>
                                            <div className="col-auto">
                                                <time className="small text-muted" >
                                                    {dailyResult?.internships}
                                                </time>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col ">
                            <div className="card-header">
                                <h3 className="m-0">This Week</h3>
                            </div>
                            <div className="card-body">
                                <div className="list-group list-group-flush my-n3">
                                    <div className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="mb-0">
                                                    Courses
                                                </h4>

                                            </div>
                                            <div className="col-auto">
                                                <time className="small text-muted" >
                                                    {weeklyResult?.weeklyCourses}
                                                </time>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="mb-0">
                                                    Sessions
                                                </h4>

                                            </div>
                                            <div className="col-auto">
                                                <time className="small text-muted" >
                                                    {weeklyResult?.weeklySessions}
                                                </time>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-group-item">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="mb-0">
                                                    Internships
                                                </h4>

                                            </div>
                                            <div className="col-auto">
                                                <time className="small text-muted" >
                                                    {weeklyResult?.weeklyInternships}
                                                </time>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col">
                            <div className="card-header">
                                <h3 className="m-0">Upcoming Courses <span className="small text-muted">({upComingCourses?.length})</span></h3>
                            </div>
                            <div className="card-body dashboard-card">
                                <div className="list-group list-group-flush my-n3">
                                    {upComingCourses?.map((course) => (
                                        <div className="list-group-item" key={course?.id}>
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="small text-muted" >
                                                        Name
                                                    </div>
                                                    <h4 className="mb-0">
                                                        {course?.name}
                                                    </h4>

                                                </div>
                                                <div className="col-auto">
                                                    <div className="small d-flex justify-content-end text-muted" >
                                                        Start Date
                                                    </div>
                                                    <time >
                                                        {moment(course?.start).format('MMMM DD, YYYY')}
                                                    </time>
                                                </div>
                                            </div>
                                        </div>))
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </Spinner>
            </div>

        </div>
    )
}

export default Dashboard
