import { Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getUser } from '../graphql/queries';
import { API, Auth } from "aws-amplify";


const ProtectedRoute = ({ children }) => {

    const [role, setRole] = useState('');

    const getUserDetails = async () => {
        try {
            let userDetails = await Auth.currentAuthenticatedUser()
            const userResponse = await API.graphql({
                query: getUser,
                variables: { id: userDetails?.username }
            })

            setRole(userResponse?.data?.getUser?.role)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUserDetails()
    }, [])

    if (role) {

        if (role === 'OWNER' || role === 'ADMIN') {
            return children
        }
        else {
            return <Navigate to="/" />
        }
    }

}

export default ProtectedRoute