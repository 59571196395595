import { API, Auth } from "aws-amplify";
import { getUser } from "./graphql/queries";

export const getAccess = async () => {
    let user = await Auth.currentAuthenticatedUser().then(res => { return res.username })
    let role = await API.graphql({ query: getUser, variables: { id: user } }).then((res) => res?.data?.getUser?.role)
    return { user, role }
}

export const isAdmin = (role) => role === 'ADMIN'
export const isTeacher = (role) => role === 'TEACHER'
export const isOwner = (role) => role === 'OWNER'
export const tempPassword = '6NwnroFx4*ex4$cK';