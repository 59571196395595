import Header from "../../../components/Header";
import SessionDetails from "./Details";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Link, useParams, useNavigate } from "react-router-dom";
import { customGetSession } from "../../../customGraphql/queries";
import { updateSession } from "../../../graphql/mutations";
import Swal from 'sweetalert';
import { toast } from "react-toastify";
import { isOwner } from '../../../utils';
import { UserContext } from "../../../contexts/userContext";
import { useContext } from "react";

const SessionDashboard = () => {
    const { id, sessionId } = useParams();
    const [session, setSession] = useState()
    const { user } = useContext(UserContext);
    const navigate = useNavigate()

    const getCourseById = async () => {
        try {
            let response = await API.graphql({
                query: customGetSession,
                variables: { id: sessionId }
            })
            setSession(response?.data?.getSession)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteSession = async () => {
        Swal({
            title: `Delete`,
            text: `Are you sure you want to delete it?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            if (status) {
                API.graphql({
                    query: updateSession,
                    variables: {
                        input: {
                            id: sessionId,
                            deleted: true
                        }
                    }
                }).then((res) => {
                    toast.success('Session deleted successfully')
                    navigate(-1)
                }).catch((err) => toast.error(err.message))
            }
        })
    }

    useEffect(() => {
        getCourseById()
    }, [])

    return (
        <div>
            <Header title={session?.course?.name} name={session?.name}>
                <button className="btn btn-primary me-3" onClick={() => { navigate(-1) }}>Back  </button>
                {session && new Date(session?.end) > new Date() &&
                    <Link to={`/youth-venture/course/${id}/session/${sessionId}/editor`} className='btn btn-primary'>Edit Session</Link>
                }
                {isOwner(user?.role) && new Date(session?.end) > new Date() &&
                    <button className="btn btn-danger ms-3" onClick={handleDeleteSession}>Delete  </button>}
            </Header>

            <div className="container card">
                <SessionDetails />
            </div>
        </div>
    )
}

export default SessionDashboard