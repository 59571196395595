import { Amplify, Auth } from 'aws-amplify';
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import awsExport from './aws-exports';
import Outdoor from './pages/Outdoor';

import Auth_ForgotPassword from './pages/auth/ForgotPassword';
import Auth_ResetPassword from './pages/auth/ResetPassword';
import Auth_SignIn from './pages/auth/SignIn';
import Auth_SignUp from './pages/auth/SignUp';
import Program from './pages/program';
import YouthVenture from './pages/youth-venture';
import YV_Dashboard from './pages/youth-venture/Dashboard';
import YV_AssignmentCreate from './pages/youth-venture/assignments/Create';
import YV_AssignmentList from './pages/youth-venture/assignments/List';
import YV_CourseCreate from './pages/youth-venture/courses/Create';
import YV_CourseDashboard from './pages/youth-venture/courses/Dashboard';
import YV_CourseList from './pages/youth-venture/courses/List';
import YV_ProfessorCreate from './pages/youth-venture/professors/Create';
import YV_ProfessorList from './pages/youth-venture/professors/List';
import YV_SessionCreate from './pages/youth-venture/sessions/Create';
import YV_SessionList from './pages/youth-venture/sessions/List';
import YV_StudentList from './pages/youth-venture/students/List';
import YV_StudentDashboard from './pages/youth-venture/students/Dashboard';
import YV_UserList from './pages/youth-venture/users/List';
import YV_SessionDashboard from './pages/youth-venture/sessions/Dashboard';
import YV_Create from './pages/youth-venture/users/Create'
import YV_AssignmentEdit from './pages/youth-venture/assignments/Edit';
import YV_InternshipList from './pages/youth-venture/internships/InternshipList';
import YV_InternshipCreate from './pages/youth-venture/internships/Create';
import YV_InternshipDashboard from './pages/youth-venture/internships/Dashboard';
import YV_InternshipApplicants from './pages/youth-venture/internships/Applicants';
import YV_ApplicantDetails from './pages/youth-venture/internships/ApplicantDetails';
import ProtectedRoute from './pages/ProtectedRoute';

Amplify.configure(awsExport)
Auth.configure(awsExport)

function App() {



  return (
    <>
      <Routes>
        <Route path='/' element={<Auth_SignIn />}></Route>
        <Route path='/register' element={<Auth_SignUp />}></Route>
        <Route path='/forgot-password' element={<Auth_ForgotPassword />}></Route>
        <Route path='/reset-password' element={<Auth_ResetPassword />}></Route>

        <Route path='/program' element={<Program />}></Route>
        <Route path='/outdoor' element={<Outdoor />}></Route>

        <Route path='/youth-venture' element={<YouthVenture />}>
          <Route path='dashboard' element={<YV_Dashboard />} />

          <Route path='course' element={<YV_CourseList />} />
          <Route path='course/create' element={<YV_CourseCreate />} />
          <Route path='course/:id/editor' element={<YV_CourseCreate />} />
          <Route path='course/:id/dashboard' element={<YV_CourseDashboard />}></Route>

          <Route path='course/:id/session' element={<YV_SessionList />}></Route>
          <Route path='course/:id/session/create' element={<YV_SessionCreate />}></Route>
          <Route path='course/:id/session/:sessionId/editor' element={<YV_SessionCreate />}></Route>
          <Route path='course/:id/session/:sessionId/dashboard' element={<YV_SessionDashboard />}></Route>

          <Route path='course/:id/assignment' element={<YV_AssignmentList />}></Route>
          <Route path='course/:id/assignment/create' element={<YV_AssignmentCreate />}></Route>
          <Route path='course/:id/assignment/:assignmentId/editor' element={<YV_AssignmentEdit />}></Route >

          <Route path='course/:id/student' element={<YV_StudentList />}></Route>
          <Route path='course/:id/student/:courseStudentId/dashboard' element={<YV_StudentDashboard />}></Route>

          <Route path='professor' element={<ProtectedRoute ><YV_ProfessorList /></ProtectedRoute>} />
          <Route path='professor/create' element={<ProtectedRoute ><YV_ProfessorCreate /></ProtectedRoute>} />

          <Route path='user' element={<ProtectedRoute ><YV_UserList /></ProtectedRoute>} />
          <Route path='user/create' element={<ProtectedRoute ><YV_Create /></ProtectedRoute>} />

          <Route path='internship' element={<ProtectedRoute ><YV_InternshipList /></ProtectedRoute>} />
          <Route path='internship/create' element={<ProtectedRoute ><YV_InternshipCreate /></ProtectedRoute>} />
          <Route path='internship/:id/editor' element={<ProtectedRoute ><YV_InternshipCreate /></ProtectedRoute>} />
          <Route path='internship/:id/dashboard' element={<ProtectedRoute ><YV_InternshipDashboard /></ProtectedRoute>} />
          <Route path='internship/:id/applicants' element={<ProtectedRoute ><YV_InternshipApplicants /></ProtectedRoute>} />
          <Route path='internship/:id/applicants/:studentId' element={<ProtectedRoute ><YV_ApplicantDetails /></ProtectedRoute>} />

        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
