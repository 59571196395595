import { API, Storage } from 'aws-amplify';
import { Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";

import { getSession } from '../../../graphql/queries';

const SessionDetails = () => {
    const { id, sessionId } = useParams()
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState([])

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        media: '',
        start: '',
        end: '',
        location: '',
    })



    useEffect(() => {
        getSessionById()
    }, [])


    const getSessionById = async () => {
        if (id) {
            try {
                let response = await API.graphql({
                    query: getSession,
                    variables: {
                        id: sessionId
                    }
                })
                response.data.getSession.location = JSON.parse(response?.data?.getSession?.location)
                setFormData(response?.data?.getSession)
            } catch (error) {
                console.log(error)
            }
        }
    }



    return (
        <div>
            <div className="container py-5">
                <Formik enableReinitialize={true} initialValues={formData} >
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor='name' className='form-label'>Session Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' name={'name'} id='name' value={values?.name} disabled={true} />
                            </div>
                            <div className="form-group">
                                <label htmlFor='location' className='form-label'>Location <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' id='name' value={values?.location?.name || ""} disabled={true} />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='hostId' className='form-label'>Professor Name </label>
                                <input type={'text'} className='form-control' id='name' value={`${values?.host?.firstName} ${values?.host?.lastName}`} disabled={true} />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='description' className='form-label'>Description <span className='text-danger'>*</span></label>
                                <textarea className="form-control" id="description" rows={'6'} name={'description'} value={formData?.description} disabled={true} ></textarea>
                            </div>
                            <div className="form-group"> <label className="form-label mb-1">Video <span className='text-danger'>*</span></label>
                                <small className="form-text text-muted"></small>
                                <aside className='d-flex flex-row flex-wrap mt-3'>
                                    <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '350px', height: '250px' }} key={'media'}>
                                        <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                            {formData?.media &&
                                                <video controls >
                                                    <source src={formData?.media?.[0]} className='d-block w-auto h-100' />
                                                </video>
                                            }
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            {(thumbnail && thumbnail.name || values.thumbnail) &&
                                <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} >
                                    <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                        {/* <span className="close" onClick={() => { removeImage(index) }}>&times;</span> */}

                                        {typeof (values?.thumbnail) === 'string' ?
                                            values?.thumbnail && <img alt='thumb' src={values?.thumbnail} className='d-block w-auto h-100'
                                            /> :
                                            <img alt='thumb' src={URL.createObjectURL(new Blob([thumbnail], { type: thumbnail.type }))} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(thumbnail.preview ? thumbnail.preview : thumbnail) }} />
                                        }
                                    </div>
                                </div>
                            }

                            <div className='card p-4'>
                                <h3>
                                    Scheduled
                                </h3>
                                <div className='form-group'>
                                    <label htmlFor='start' className='form-label'>Start Date & Time <span className='text-danger'>*</span></label>
                                    <input type={'text'} className='form-control' value={moment(values?.start).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='end' className='form-label'>End Date & Time <span className='text-danger'>*</span></label>
                                    <input type={'text'} className='form-control' value={moment(values?.end).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                                </div>
                            </div>
                            {values?.actualStart &&
                                <div className='card p-4'>
                                    <h3>
                                        Actual
                                    </h3>
                                    {values?.actualStart &&
                                        <div className='form-group'>
                                            <label htmlFor='start' className='form-label'>Actual Start Date & Time <span className='text-danger'>*</span></label>
                                            <input type={'text'} className='form-control' value={moment(values?.actualStart).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                                        </div>
                                    }
                                    {values?.actualEnd &&
                                        <div className='form-group'>
                                            <label htmlFor='start' className='form-label'>Actual End Date & Time <span className='text-danger'>*</span></label>
                                            <input type={'text'} className='form-control' value={moment(values?.actualEnd).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                                        </div>
                                    }
                                </div>
                            }
                        </form>
                    )}
                </Formik >
            </div >
        </div >
    )
}

export default SessionDetails
