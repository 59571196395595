import { utils, write } from 'xlsx';


const convertToWorkbook = (excelData) => {
    const workbook = utils.book_new();
    const worksheetData = transformKeys(excelData)
    const worksheet = utils.json_to_sheet(worksheetData);

    utils.book_append_sheet(workbook, worksheet, 'Courses');

    return workbook;
};

function transformKeys(array) {
    const capitalizeFirstChar = str => {
        const words = str.replace(/([A-Z])/g, ' $1');
        return words.charAt(0).toUpperCase() + words.slice(1);
    };

    const convertedArray = array.map(obj => {
        const convertedObj = {};
        for (let key in obj) {
            const convertedKey = capitalizeFirstChar(key);
            convertedObj[convertedKey] = obj[key];
        }
        return convertedObj;
    });
    return convertedArray
}

export const downloadExcelFile = (data, filename) => {
    const workbook = convertToWorkbook(data)
    const excelBuffer = write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename + '.xlsx';
    link.click();

    URL.revokeObjectURL(link.href);
};