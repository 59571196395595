import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from "../../../components/Header";
import LocationSearch from "../../../components/locationSearch";
import { createInternship, updateInternship } from "../../../graphql/mutations";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { getInternship, listYouthVentureCities } from "../../../graphql/queries";
import { useDropzone } from "react-dropzone";
import { Storage } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Spinner from '../../../components/Spinner';

const Create = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [cityOptions, setCityOptions] = useState([]);
    const [mediaInput, setMediaInput] = useState()
    const [files, setFiles] = useState([]);
    const [thumbnail, setThumbnail] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [spinner, setSpinner] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        media: '',
        contact_name: '',
        contact_phone: '',
        slots: '',
        start: '',
        end: '',
        location: '',
        company: '',
        internshipCityId: ''
    })

    const formSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        contact_name: Yup.string().required("Required"),
        contact_phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required'),
        slots: Yup.string().test(
            'Is positive?',
            'The number must be greater than 0!',
            (value) => value > 0
        ).required('Required'),
        start: Yup.string().required('Required'),
        end: Yup.string().required('Required'),
        location: Yup.object().required('Required'),
        company: Yup.string().required('Required'),
        skills: Yup.array().min(1, 'Required').required(),
        internshipCityId: Yup.object().required('Required'),
    })

    const handleformData = async (values) => {
        setSpinner(true)
        let contactPerson = {}
        contactPerson.name = values.contact_name;
        contactPerson.phone = values.contact_phone;
        values.contactPerson = JSON.stringify(contactPerson)

        let duration = moment.duration(moment(values.end).diff(moment(values.start)));
        values.duration = Math.ceil(duration.asDays())
        let media = values?.media || null;
        let thum = values?.thumbnail

        if (!files.length && !media) {
            toast.error('Please add Internship Cover')
            setSpinner(false);
            return
        }

        if (files.length > 0) {
            media = await handleUploadImage(files?.[0], values.title)
        }


        if (!thum) {
            if (files[0]?.type === "video/mp4" || (media && media.endsWith('.mp4'))) {
                if (thumbnail?.name) {
                    thum = await handleUploadImage(thumbnail, values.title)
                } else {
                    toast.error('Please add Internship Thumbnail')
                    setSpinner(false);
                    return
                }
            } else {
                thum = media
            }
        }
        values.skills = values.skills.map((skill) => (skill.value))

        if (!media) {
            media = null;
        }

        if (id) {
            handleUpdateInternship(values, media, thum)
        } else {
            handleCreateInternship(values, media, thum)
        }
    }

    const handleUploadImage = async (file, path) => {

        try {
            path = path.replaceAll(' ', '');
            path = path.replaceAll('/', '-');
            const stored = await Storage.put(`${path}/${Math.random().toString(36).substring(2, 15)}.${file?.name?.split('.')?.pop()}`, file, { contentType: file?.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            return url.split('?')[0]
        } catch (error) {
            console.log(error)
        }
    }


    const getInternshipById = async () => {
        if (id) {
            try {
                let response = await API.graphql({
                    query: getInternship,
                    variables: {
                        id: id
                    }
                })

                response.data.getInternship.location = JSON.parse(response?.data?.getInternship?.location)

                let contactPerson = JSON.parse(response?.data?.getInternship?.contactPerson)
                response.data.getInternship.contact_name = contactPerson?.name
                response.data.getInternship.contact_phone = contactPerson?.phone

                const cities = await getCities();
                response.data.getInternship.internshipCityId = cities.find((item) => (item.value === response?.data?.getInternship?.internshipCityId))

                response.data.getInternship.skills = response?.data?.getInternship?.skills?.map((skill) => ({ value: skill, label: skill }))

                setFormData(response?.data?.getInternship)
                setMediaInput(response?.data?.getInternship?.media)

            } catch (error) {
                console.log(error)
            }
        }
    }


    const handleCreateInternship = async (data, media, thum) => {
        try {
            let res = await API.graphql({
                query: createInternship,
                variables: {
                    input: {
                        title: data.title,
                        description: data?.description,
                        start: data?.start?.toISOString(),
                        end: data?.end?.toISOString(),
                        slots: data?.slots,
                        media: media,
                        contactPerson: data.contactPerson,
                        skills: data?.skills,
                        location: JSON.stringify(data?.location),
                        company: data?.company,
                        internshipCityId: data?.internshipCityId?.value,
                        thumbnail: thum,
                        deleted: false
                    }
                }
            })

            setTimeout(() => {
                toast.success('Internship has been added');
                navigate(-1);
                setSpinner(false)
            }, 2000)


        } catch (error) {
            console.log(error)
            setSpinner(false)
        }
    }

    const handleUpdateInternship = async (data, media, thum) => {
        try {
            await API.graphql({
                query: updateInternship,
                variables: {
                    input: {
                        id,
                        title: data.title,
                        description: data?.description,
                        start: data?.start,
                        end: data?.end,
                        slots: data?.slots,
                        media: media,
                        contactPerson: data?.contactPerson,
                        skills: data?.skills,
                        location: JSON.stringify(data?.location),
                        internshipCityId: data?.internshipCityId?.value,
                        company: data?.company,
                        thumbnail: thum,
                        deleted: false
                    }
                }
            })
            setTimeout(() => {
                toast.success('Internship has been updated');
                navigate(-1);
                setSpinner(false)
            }, 2000)

        } catch (error) {
            console.log(error)
            setSpinner(false)
        }
    }

    useEffect(() => {
        setMediaInput()
    }, [files])

    useEffect(() => {
        getInternshipById()
        getCities()
    }, [])

    // Image Upload Code Start ---- 
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1, accept: { 'image/*': ['.png', '.jpg', '.jpeg'], 'video/*': ['.mp4'] }, onDrop: acceptedFiles => {
            if (files.length + acceptedFiles.length <= 1) {
                setFiles(files.concat(acceptedFiles.map(file => Object.assign(file,
                    {
                        preview: URL.createObjectURL(file)
                    })))
                );
                setMediaInput()
            }
            else { toast.error("you can drop only one file here") }
        }
    });
    useEffect(() => { return () => files.forEach(file => URL.revokeObjectURL(file.preview)); }, [files]);

    const removeImage = (index) => { setFiles(file => file.filter((_s, i) => (i != index))) }
    // Image Upload Code End ----

    const filterStartTime = (time) => {
        const startDate = new Date();
        const selectedDate = new Date(time);

        return startDate.getTime() < selectedDate.getTime();

    };

    const filterEndTime = (time) => {
        const startDate = new Date(startTime);
        const selectedDate = new Date(time);
        const todayDate = new Date();
        if (startDate < todayDate) {
            return todayDate.getTime() < selectedDate.getTime();
        }
        return startDate.getTime() < selectedDate.getTime();

    };

    const getCities = async () => {
        try {
            let response = await API.graphql({
                query: listYouthVentureCities,
            })
            let cities = response?.data?.listYouthVentureCities?.items?.map((city) => ({ value: city.id, label: city.name }))
            setCityOptions(cities);
            return cities
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <Header title='Youth Venture' name={id ? 'Edit Internship' : 'New Internship'} container='container'>
                <button className="btn btn-primary me-3" onClick={() => { navigate(-1) }}>Back  </button>
            </Header>

            <div className="container pb-5">
                <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleformData}>
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor='title' className='form-label'>Title <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' id='title' defaultValue={values?.title} onChange={handleChange} />
                                <span className='text-danger'>{errors.title}</span>
                            </div>

                            <div className="form-group">
                                <label htmlFor='location' className='form-label'>Location / Venue <span className='text-danger'>*</span></label>
                                <LocationSearch setFieldValue={setFieldValue} value={values?.location?.name || ''} city={values?.courseCityId?.label} />
                                <span className='text-danger'>{errors.location}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='internshipCityId' className='form-label'>City <span className='text-danger'>*</span></label>
                                <Select options={cityOptions} id='internshipCityId'
                                    value={values?.internshipCityId}
                                    onChange={(e) => { setFieldValue('internshipCityId', e) }} />
                                <span className='text-danger'>{errors.internshipCityId}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='description' className='form-label'>Description <span className='text-danger'>*</span></label>
                                <textarea className="form-control" id="description" defaultValue={values?.description} onChange={handleChange} ></textarea>
                                <span className='text-danger'>{errors.description}</span>
                            </div>

                            <div className='row form-group'>
                                <div className="col">
                                    <label htmlFor='contact_name' className='form-label'>Contact Person Name <span className='text-danger'>*</span></label>
                                    <input className="form-control" id="contact_name" defaultValue={values?.contact_name} onChange={handleChange} ></input>
                                    <span className='text-danger'>{errors.contact_name}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor='contact_phone' className='form-label'>Contact Person Phone <span className='text-danger'>*</span></label>
                                    <input type="number" className="form-control" id="contact_phone" defaultValue={values?.contact_phone} onChange={handleChange} ></input>
                                    <span className='text-danger'>{errors.contact_phone}</span>
                                </div>
                            </div>

                            <div className='row form-group'>
                                <div className='col'>
                                    <label htmlFor='start' className='form-label'>Start Date <span className='text-danger'>*</span></label>
                                    <DatePicker minDate={new Date()} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className='form-control' name='start' selected={values?.start ? new Date(values?.start) : ''} filterTime={filterStartTime} onChange={(e) => { setFieldValue('start', e); setStartTime(e) }} />
                                    <span className='text-danger'>{errors.start}</span>
                                </div>
                                <div className='col'>
                                    <label htmlFor='end' className='form-label'>End Date <span className='text-danger'>*</span></label>
                                    <DatePicker minDate={new Date(values?.start)} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className='form-control' name='start' selected={values?.end ? new Date(values?.end) : ''} filterTime={filterEndTime} onChange={(e) => { setFieldValue('end', e) }} disabled={!(values?.start)} />
                                    <span className='text-danger'>{errors.end}</span>
                                </div>
                            </div>

                            <div className="form-group"> <label className="form-label mb-1">Internship Cover <span className='text-danger'>*</span></label>
                                <small className="form-text text-muted">Supported file formats png, jpg, jpeg, mp4</small>
                                <div {...getRootProps({ className: 'dropzone dz-message py-6' })}> <input {...getInputProps()} />
                                    <p className='mb-0'>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside className='d-flex flex-row flex-wrap mt-3'>
                                    {mediaInput &&
                                        <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '150px', height: '150px' }} key={'media'}>
                                            <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                <div>
                                                    {mediaInput.endsWith(".mp4") ?
                                                        (<video controls className='d-block w-auto h-100' >
                                                            <source src={mediaInput} />
                                                        </video>)
                                                        :
                                                        <img alt='product_image'
                                                            src={mediaInput} className='d-block w-auto h-100'
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {files?.map((file, index) => {
                                        if (file.type === 'video/mp4') {
                                            return <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} key={index}>
                                                <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden', maxHeight: '100px' }}>
                                                    <span className="close" onClick={() => { removeImage(index) }}>&times;</span>
                                                    <video controls className='d-block w-auto h-100'>
                                                        <source src={file.preview ? URL.createObjectURL(new Blob([file], { type: file.type })) : file || values?.video} onLoad={() => { URL.revokeObjectURL(file.preview ? file.preview : file) }} type="video/mp4" className='d-block w-auto h-100' />
                                                    </video>
                                                </div>
                                            </div>
                                        } else {
                                            return <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} key={index}>
                                                <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                    <span className="close" onClick={() => { removeImage(index) }}>&times;</span>
                                                    <img alt='product_image' src={file.preview ? URL.createObjectURL(new Blob([file], { type: file.type })) : file} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(file.preview ? file.preview : file) }} />
                                                </div>
                                            </div>
                                        }

                                    })}
                                </aside>
                            </div>
                            {((files[0]?.type === 'video/mp4' || thumbnail.name) || (mediaInput?.endsWith(".mp4"))) &&
                                <div className="form-group"> <label className="form-label">Thumbnail</label>
                                    <small className="form-text text-muted">Please use an image.Supported file formats png, jpg, jpeg</small>
                                    <label className='btn btn-primary btn-sm' htmlFor='media' >
                                        <label className='btn btn-primary btn-sm' htmlFor='thumbnail' >
                                            <input type='file' id='thumbnail' accept='image/*' name="thumbnail" onChange={(e) => { setThumbnail(e.target?.files[0]); setFieldValue("thumbnail", null) }} /> Upload
                                        </label>
                                    </label>
                                </div>
                            }

                            {(((thumbnail && thumbnail.name) || values.thumbnail) && (files[0]?.type === 'video/mp4' || mediaInput?.endsWith(".mp4"))) &&
                                <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} >
                                    <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                        {typeof (values?.thumbnail) === 'string'
                                            ? <img alt='thumb' src={values?.thumbnail} className='d-block w-auto h-100' />
                                            : <img alt='thumb' src={URL.createObjectURL(new Blob([thumbnail], { type: thumbnail.type }))} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(thumbnail.preview ? thumbnail.preview : thumbnail) }} />
                                        }
                                    </div>
                                </div>
                            }

                            <div className='form-group'>
                                <label htmlFor='company' className='form-label'>Company Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' id='company' defaultValue={values?.company} onChange={handleChange} />
                                <span className='text-danger'>{errors.company}</span>
                            </div>

                            <div className='row form-group'>
                                <div className='col'>
                                    <label htmlFor='slots' className='form-label'>Maximum Participants <span className='text-danger'>*</span></label>
                                    <input type={'number'} className='form-control' id='slots' value={values?.slots} onChange={handleChange} />
                                    <span className='text-danger'>{errors.slots}</span>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor='skills' className='form-label'>Skills students will learn <span className='text-danger'>*</span></label>
                                <CreatableSelect value={values.skills} isClearable isMulti onChange={(event) => { setFieldValue('skills', event) }} />
                                <span className='text-danger'>{errors.skills}</span>
                            </div>

                            <div className='mt-5'>
                                <Spinner display={spinner}>
                                    <button type='submit' className='btn btn-primary w-100'>{id ? 'Update Internship' : 'Create Internship'}</button>
                                </Spinner>
                            </div>
                        </form>
                    )
                    }
                </Formik >
            </div >
        </div>
    )
}

export default Create
