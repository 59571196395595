import { API, Auth } from 'aws-amplify';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Spinner from '../../components/Spinner';
import { UserContext } from '../../contexts/userContext';
import { getUser } from '../../graphql/queries';
import { tempPassword } from '../../utils';

const formData = {
    phone: '',
    password: ''
}

const findUserSchema = Yup.object().shape({
    phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required')
})

const registerSchema = Yup.object().shape({
    password: Yup.string().required('No password provided.')
        .min(6, 'Password is too short - should be 6 chars minimum.'),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
    otp: Yup.string().matches(/^[0-9]*$/, "Code must be number").min(6, "code is not valid").max(6, "code is not valid").required('Required'),
})

const SignUp = () => {
    const navigate = useNavigate();
    const { updateUser } = useContext(UserContext);
    const [passwordType, setPasswordType] = useState('password');
    const [confirmType, setconfirmType] = useState('password');
    const [spinner, showSpinner] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const [username, setUsername] = useState()

    // useEffect(() => {
    //     const signIn = async () => {
    //         try {
    //             const password = '6NwnroFx4*ex4$cK';
    //             console.log(phone, password)
    //             const response = await Auth.signIn({ username: `+91${phone}`, password });
    //             // const response = await Auth.signUp({
    //             //     Username: `+91${phone}`,
    //             //     TemporaryPassword: password,
    //             //     attributes: {
    //             //         'given_name': 'Puneet',
    //             //         'family_name': 'Jain'
    //             //     }
    //             // });
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }

    //     signIn()
    // }, [])

    const handlePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const registerUser = async (values) => {
        try {
            showSpinner(true);
            await Auth.confirmSignUp(username, values.otp, { forceAliasCreation: false })
            const cognitoUser = await Auth.signIn({ username, password: tempPassword });
            await Auth.changePassword(cognitoUser, tempPassword, values.password);

            const userResponse = await API.graphql({
                query: getUser,
                variables: { id: cognitoUser.username }
            })

            const user = userResponse.data.getUser
            updateUser(user)

            if (user.role === 'ADMIN' || user.role === 'OWNER') {
                navigate('/program')
            } else if (user.role === 'TEACHER') {
                navigate('/youth-venture/course')
            }
        } catch (error) {
            if (error.name === 'CodeMismatchException') setError(error.message)
            console.log('registerUser', error)
        } finally {
            showSpinner(false);
        }
    }


    const findUser = async (values) => {
        setError('');
        const username = `+91${values.phone}`;

        try {
            showSpinner(true);
            await Auth.signIn({ username, password: tempPassword });
            setError('Looks like you are already registered, please Sign In to continue.')
        } catch (error) {
            console.log('findUser', error)
            if (error.name === 'UserNotConfirmedException') {
                setUsername(username);
                setStep(2);
            } else if (error.name === 'NotAuthorizedException') {
                setError('Looks like you are not authorized to register at this time. Please contact admin at admin@goingtoschool.com')
            }
        } finally {
            showSpinner(false)
        }
    }

    const resendCode = async (values) => {
        setError('');

        try {
            showSpinner(true);
            await Auth.resendSignUp(username);
            setError(`Confirmation code has been resent`)
        } catch (error) {
            console.log('resendCode', error)
            setError('Oops, we are unable to complete your registration at this time. Please contact admin at admin@goingtoschool.com')
        } finally {
            showSpinner(false);
        }
    }

    return (
        <div className="d-flex bg-auth " style={{ display: 'block' }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 my-5">
                        <h1 className="display-3 text-center">
                            Going To School
                        </h1>
                        <h3 className="display-4 text-center mb-5">
                            Registration
                        </h3>
                        <p className='text-danger text-center'>{error}</p>

                        {
                            step === 1 && <Formik initialValues={formData} validationSchema={findUserSchema} onSubmit={(values) => findUser(values)}>
                                {({ handleChange, handleSubmit, values, errors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='phone' className='form-label'>Mobile Number</label>
                                            <input type='number' className='form-control' id='phone' name='phone' value={values.phone} onChange={handleChange} placeholder="e.g. 9988776655" />
                                            <span className='text-danger'>{errors.phone}</span>
                                        </div>
                                        <Spinner display={spinner}>
                                            <div className='form-group'>
                                                <button type='submit' className='btn btn-primary btn-lg w-100'>Continue</button>
                                                <Link to='/' className='btn btn-lg w-100'>Sign In</Link>
                                            </div>
                                        </Spinner>
                                    </form>
                                )}
                            </Formik>
                        }

                        {
                            step === 2 && <Formik initialValues={formData} validationSchema={registerSchema} onSubmit={(values) => registerUser(values)}>
                                {({ handleChange, handleSubmit, values, errors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className='form-group'>
                                                    <label htmlFor='password' className='form-label'>Password</label>
                                                    <div className="input-group input-group-merge">
                                                        <input type={`${passwordType}`} className='form-control' id='password' name='password' value={values.password} onChange={handleChange} placeholder="" />
                                                        <span className="input-group-text">
                                                            <i className={(passwordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={handlePasswordType}></i>
                                                        </span>
                                                    </div>
                                                    <span className='text-danger'>{errors.password}</span>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='form-group'>
                                                    <label htmlFor='confirmPassword' className='form-label'>Confirm Password</label>
                                                    <div className="input-group input-group-merge">
                                                        <input type={`${confirmType}`} className='form-control' id='confirmPassword' name='confirmPassword' value={values.confirmPassword} onChange={handleChange} placeholder="" />
                                                        <span className="input-group-text">
                                                            <i className={(confirmType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={() => { setconfirmType((prev) => (prev === 'password' ? 'text' : 'password')) }}></i>
                                                        </span>
                                                    </div>
                                                    <span className='text-danger'>{errors.confirmPassword}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='otp' className='form-label'>Confirmation Code (Sent via SMS)</label>
                                            <input type='text' className='form-control' id='otp' name='otp' value={values.otp} onChange={handleChange} placeholder="e.g. 123456" />
                                            <span className='text-danger'>{errors.otp}</span>
                                        </div>

                                        <Spinner display={spinner}>
                                            <div className=' form-group'>
                                                <button type='submit' className='btn btn-primary btn-lg w-100'>Continue</button>
                                                <button type='submit' className='btn btn-lg w-100 mt-2' onClick={() => resendCode()}>Resend Code</button>
                                            </div>
                                        </Spinner>
                                    </form>
                                )}
                            </Formik>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;