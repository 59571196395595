import { API } from 'aws-amplify';
import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { getInternship } from '../../../graphql/queries';
import Header from "../../../components/Header";
import { isOwner } from '../../../utils';
import { UserContext } from "../../../contexts/userContext";
import InternshipMenu from './Menu';
import moment from "moment";
import { updateInternship } from '../../../graphql/mutations';
import Swal from 'sweetalert';
import { toast } from "react-toastify";


const InternshipDashboard = () => {
    const { user } = useContext(UserContext);
    const { id } = useParams();
    const navigate = useNavigate()
    const [internship, setInternship] = useState();


    const getInternshipById = async () => {
        try {
            let response = await API.graphql({
                query: getInternship,
                variables: { id }
            })
            response.data.getInternship.location = JSON.parse(response?.data?.getInternship.location)
            response.data.getInternship.contactPerson = JSON.parse(response?.data?.getInternship.contactPerson)
            setInternship(response?.data?.getInternship)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteInternship = async () => {
        Swal({
            title: `Delete`,
            text: `Are you sure you want to delete it?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            if (status) {
                API.graphql({
                    query: updateInternship,
                    variables: {
                        input: {
                            id: id,
                            deleted: true
                        }
                    }
                }).then((res) => {
                    toast.success('Internship deleted successfully')
                    navigate(-1)
                }).catch((err) => toast.error(err.message))
            }
        })
    }

    useEffect(() => {
        getInternshipById()
    }, [])

    return (
        <div>
            <Header title={internship?.title} name='Dashboard'>
                <button className="btn btn-primary me-3" onClick={() => { navigate('/youth-venture/internship') }}>Back  </button>
                {internship && new Date(internship?.end) > new Date() &&
                    <Link to={`/youth-venture/internship/${id}/editor`} className='btn btn-primary'>Edit Internship</Link>
                }
                {isOwner(user.role) && (internship && new Date(internship?.end) > new Date()) &&
                    <button className="btn btn-danger ms-3" onClick={handleDeleteInternship}>Delete  </button>}
            </Header>
            <div className="container-fluid">
                <InternshipMenu id={id} />
                <hr />
                <div className="container card py-5">
                    <form >
                        <div className="form-group">
                            <label htmlFor='title' className='form-label'>Title </label>
                            <input type={'text'} className='form-control' id='title' defaultValue={internship?.title} disabled={true} />

                        </div>

                        <div className="form-group">
                            <label htmlFor='location' className='form-label'>Location / Venue </label>
                            <input type={'text'} className='form-control' id='name' value={internship?.location?.name || ""} disabled={true} />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='internshipCityId' className='form-label'>City </label>
                            <input type={'text'} className='form-control' id='name' value={internship?.city?.name || ""} disabled={true} />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='description' className='form-label'>Description</label>
                            <textarea className="form-control" id="description" defaultValue={internship?.description} disabled={true}></textarea>

                        </div>

                        <div className="form-group">
                            <label htmlFor='title' className='form-label'>Company Name</label>
                            <input type={'text'} className='form-control' id='company' defaultValue={internship?.company} disabled={true} />

                        </div>

                        <div className='row form-group'>
                            <div className="col">
                                <label htmlFor='contact_name' className='form-label'>Contact Person Name </label>
                                <input className="form-control" id="contact_name" defaultValue={internship?.contactPerson?.name} disabled={true} ></input>

                            </div>
                            <div className="col">
                                <label htmlFor='contact_phone' className='form-label'>Contact Person Phone </label>
                                <input className="form-control" id="contact_phone" defaultValue={internship?.contactPerson?.phone} disabled={true}></input>

                            </div>
                        </div>

                        <div className='row form-group'>
                            <div className='col'>
                                <label htmlFor='start' className='form-label'>Start Date </label>
                                <input type={'text'} className='form-control' id='name' value={moment(internship?.start).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                            </div>
                            <div className='col'>
                                <label htmlFor='end' className='form-label'>End Date</label>
                                <input type={'text'} className='form-control' id='name' value={moment(internship?.end).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                            </div>
                        </div>
                        {internship?.media &&
                            <div className="form-group">
                                <label className="form-label mb-1">Internship Cover</label>
                                <div className='d-flex flex-row flex-wrap mt-3'>
                                    <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '350px', height: '250px' }} >
                                        <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                            <div>
                                                {internship?.media?.endsWith(".mp4") ?
                                                    (<video controls className='d-block w-auto h-100' >
                                                        <source src={internship?.media} />
                                                    </video>)
                                                    :
                                                    <img alt='product_image'
                                                        src={internship?.media} className='d-block w-auto h-100'
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            internship?.media?.endsWith(".mp4") &&
                            <div className='form-group'>
                                <label htmlFor='slots' className='form-label'>Thumbnail </label>
                                <div className='rounded-2 border border-1 mb-1 ms-1 p-2 border-box ' style={{ width: '300px', height: '200px' }}>
                                    <img alt='internship_image'
                                        src={internship?.thumbnail} height={'100%'} width={'100%'}
                                    />
                                </div>
                            </div>
                        }

                        <div className='row form-group'>
                            <div className='col'>
                                <label htmlFor='slots' className='form-label'>Maximum Participants </label>
                                <input type={'number'} className='form-control' id='slots' defaultValue={internship?.slots} disabled={true} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor='skills' className='form-label'>Skills students will learn </label>
                            <ul>
                                {internship?.skills?.map((skill, index) => (
                                    <li key={index}>{skill}</li>
                                ))}

                            </ul>
                        </div>
                    </form>
                </div >
            </div>
        </div>
    )
}

export default InternshipDashboard
