import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, NumberFormat } from 'formik';
import { API, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { searchUsers } from '../../graphql/queries';
import Spinner from '../../components/Spinner';



const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [resetIntialValues, setResetIntialValues] = useState({ phone: '', new_password: '', confirm_password: '', code: '' })
    const [passwordType, setPasswordType] = useState('password');
    const [confirmType, setconfirmType] = useState('password');
    const [spinner, showSpinner] = useState(false);

    const phoneSchema = Yup.object().shape({
        phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required')
    })

    const handlePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        code: Yup.string().matches(/^[0-9]*$/, "Code must be number").min(6, "code is not valid").max(6, "code is not valid").required('Required'),
        new_password: Yup.string().required('No password provided.')
            .min(6, 'Password is too short - should be 6 chars minimum.'),
        confirm_password: Yup.string().required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });

    const findUserInUserTable = async (username) => {
        try {
            let response = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: { phone: { eq: username } }
                }
            })
            if (response.data.searchUsers.items[0].role === 'TEACHER' || response.data.searchUsers.items[0].role === 'ADMIN' || response.data.searchUsers.items[0].role === 'OWNER') {
                return true
            } else {
                return false
            }


        } catch (error) {
            console.log(error)
        }
    }

    const findUserInCognito = async (username) => {
        try {
            const { user } = await Auth.signUp({
                username,
                password: '1234'
            });
            return false
        } catch (error) {
            if (error.code === 'UsernameExistsException') {
                return true
            }
        }
    }

    const resendCode = async (username) => {
        showSpinner(true)
        try {
            Auth.forgotPassword(username)
                .then((data) => {
                    toast.success('Confirmation code has been sent to Phone Number');
                })
                .catch((err) => console.log(err));
            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    const sendCode = async (values) => {
        showSpinner(true)
        const username = `+91${values.phone}`;
        if (await findUserInUserTable(username) && await findUserInCognito(username)) {
            setResetIntialValues((prev) => ({ ...prev, phone: username }))
            Auth.forgotPassword(username)
                .then((data) => {
                    toast.success('Confirmation code has been sent to Phone Number');
                    setStep(2)
                })
                .catch((err) => console.log(err));
            showSpinner(false)
        }
        else {
            toast.error("Please enter valid Phone Number!")
            showSpinner(false)
        }

    }

    const submitPassword = (values) => {
        showSpinner(true)
        Auth.forgotPasswordSubmit(values.phone, values.code, values.new_password)
            .then((data) => {
                toast.success('Password Updated', {
                    position: toast.POSITION.TOP_RIGHT
                }
                );
                showSpinner(false)
                navigate('/')

            })
            .catch((err) => {
                showSpinner(false)
                toast.error(err.message)
                console.log(err)
            });

    }



    return (
        <div className="d-flex bg-auth " style={{ display: 'block' }}>
            <div className="container">
                <div className="row vh-100 align-items-center">
                    <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-lg-block">
                        <div className="text-center">
                            <img src="/img/login.svg" alt="..." className="img-fluid" />
                        </div>
                    </div>
                    {step == 1 ? (<div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                        <h2 className="display-4 text-center mb-5">
                            Forgot Password
                        </h2>
                        <Formik
                            initialValues={resetIntialValues}
                            validationSchema={phoneSchema}
                            onSubmit=
                            {(values) => {
                                sendCode(values)

                            }}
                        >
                            {({ handleChange, handleSubmit, values, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='form-group'>
                                        <label htmlFor='phone' className='form-label'>Mobile Number</label>
                                        <input type='number' className='form-control' id='phone' name='phone' value={values.phone} onChange={handleChange} placeholder="e.g. 9988776655" />
                                        <span className='text-danger'>{errors.phone}</span>
                                    </div>
                                    <Spinner display={spinner}>
                                        <div className=' form-group'>
                                            <button type='submit' className='btn btn-primary btn-lg w-100' >Send Code</button>
                                        </div>
                                    </Spinner>
                                </form>
                            )}
                        </Formik>
                    </div>) : (
                        <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                            <h2 className="display-4 text-center mb-5">
                                Forgot Password
                            </h2>
                            <Formik
                                initialValues={resetIntialValues}
                                validationSchema={formSchema}
                                enableReinitialize={true}
                                onSubmit=
                                {(values) => {
                                    submitPassword(values)
                                }}
                            >
                                {({ handleChange, handleSubmit, values, errors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='code' className='form-label'>Code</label>
                                            <input type={'text'} id='code' className='form-control mfa-code' format='######' mask='_' placeholder='0 0 0 0 0 0' value={values.code} maxLength="6" onChange={handleChange} disabled={values.code.length > 6} />
                                            {/* <input type={'number'} className='form-control' id='code' value={values.code} onChange={handleChange} placeholder="" /> */}
                                            <span className='text-danger'>{errors.code}</span>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='new_password' className='form-label'>New Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${passwordType}`} id='new_password' value={values.new_password} placeholder="Enter new password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(passwordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={handlePasswordType}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.new_password}</span>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='confirm_password' className='form-label'>Confirm  Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${confirmType}`} id='confirm_password' value={values.confirm_password} placeholder="Confirm password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(confirmType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={() => { setconfirmType((prev) => (prev === 'password' ? 'text' : 'password')) }}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.confirm_password}</span>
                                        </div>
                                        <Spinner display={spinner}>
                                            <div className=' form-group'>
                                                <button type='submit' className='btn btn-primary btn-lg w-100' >Change Password
                                                </button>
                                            </div>
                                        </Spinner>
                                    </form>
                                )}
                            </Formik>
                            <div className=' form-group'>
                                <Spinner display={spinner}>
                                    <button type='submit' className='btn btn-primary btn-lg w-100' onClick={() => { resendCode(resetIntialValues?.phone) }}>Resend Code
                                    </button>
                                </Spinner>
                            </div>
                        </div>
                    )}
                </div>

            </div>

        </div >





    )
}

export default ForgotPassword
