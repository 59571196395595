import { API, Storage } from 'aws-amplify';
import { Formik } from "formik";
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from "moment";
import * as Yup from 'yup';
import Header from "../../../components/Header";
import LocationSearch from '../../../components/locationSearch';
import { createSession, updateSession } from '../../../graphql/mutations';
import { getSession } from '../../../graphql/queries';
import Spinner from '../../../components/Spinner';
import Select from 'react-select';

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        role
        firstName
        lastName
      }
    }
  }
`;

const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      hostId
      host {
        id
        role
        firstName
        lastName
      }
      name
      description
      duration
      city {
        id
        name
      }
      start
      end
      hosts
    }
  }
`;

const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
    }
  }
`;

const SessionCreate = () => {
    const { id, sessionId } = useParams()
    const navigate = useNavigate();
    const [mediaInput, setMediaInput] = useState([])
    const [files, setFiles] = useState([]);
    const [thumbnail, setThumbnail] = useState([])
    const [course, setCourse] = useState();
    const [startTime, setStartTime] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [teachers, setTeachers] = useState([]);


    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        media: '',
        start: '',
        end: '',
        location: '',
        hostId: '',
    })

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        // duration: Yup.string().required('Required'),
        // video: Yup.string().required('Required'),
        start: Yup.string().required('Required'),
        end: Yup.string().required('Required'),
        location: Yup.object().required('Required'),
        hostId: Yup.object().required('Required'),
    })

    const handleformData = async (values) => {
        setSpinner(true);
        let duration = moment.duration(moment(values.end).diff(moment(values.start)));
        values.duration = Math.ceil(duration.asHours());
        let media = values?.media[0] || "";
        let thum = values?.thumbnail || ''

        if (!files.length && !media) {
            toast.error('Please add video')
            setSpinner(false);
            return
        }

        if (thumbnail.length === 0 && !values?.thumbnail) {
            toast.error('Please add Thumbnail')
            setSpinner(false);
            return
        }

        if (files.length) {
            media = await handleUploadVideo(files?.[0], values?.name)
        }
        if (thumbnail?.name) {
            thum = await handleUploadVideo(thumbnail, values?.name)
        }

        if (sessionId) {
            handleUpdateSession(values, media, thum)
        } else {
            handleCreateSession(values, media, thum)
        }
    }


    const handleUploadVideo = async (file, sessionName) => {
        try {
            let courseName = course.name.replaceAll(' ', '').replaceAll('/', '-')
            sessionName = sessionName.replaceAll(' ', '').replaceAll('/', '-')
            const stored = await Storage.put(`${courseName}/${sessionName}/${Math.random().toString(36).substring(2, 15)}.${file.name.split('.').pop()}`, file, { contentType: file.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            return url.split('?')[0]
        } catch (error) {
            console.log(error)
        }
    }

    const getCourseById = async () => {
        try {
            let response = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            setCourse(response?.data?.getCourse)
        } catch (error) {
            console.log(error)
        }
    }

    const handleListTeachers = async () => {
        let options = []
        try {
            let res = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: {
                        role: { eq: 'TEACHER' },
                        deleted: { ne: true }
                    }
                }
            })
            options = res?.data?.searchUsers?.items?.map((user) => ({ label: `${user?.firstName} ${user?.lastName}`, value: user?.id }))
            // if (isTeacher(access?.role)) {
            //     options = options.filter((item) => { return access.user && item.value === access.user })
            // }
            setTeachers(options)
            return options
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCourseById();
        handleListTeachers()
        getSessionById()
    }, [])



    useEffect(() => {
        setMediaInput([])
    }, [files])


    const getSessionById = async () => {
        if (sessionId) {
            try {
                let response = await API.graphql({
                    query: getSession,
                    variables: {
                        id: sessionId
                    }
                })
                response.data.getSession.location = JSON.parse(response?.data?.getSession?.location)
                const host = response?.data?.getSession?.host
                response.data.getSession.hostId = { label: `${host?.firstName} ${host?.lastName}`, value: response?.data?.getSession?.hostId }
                setFormData(response?.data?.getSession)
                setMediaInput(response.data.getSession.media[0])
            } catch (error) {
                console.log(error)
            }
        }
    }


    const handleUpdateSession = async (data, media, thum) => {
        try {
            setSpinner(true);
            await API.graphql({
                query: updateSession,
                variables: {
                    input: {
                        id: sessionId,
                        name: data.name,
                        description: data.description,
                        start: data.start,
                        end: data.end,
                        location: JSON.stringify(data.location),
                        duration: data.duration + ' Hours',
                        hostId: data?.hostId?.value,
                        media: [media],
                        thumbnail: thum,
                        sessionCityId: course?.courseCityId
                    }
                }

            })

            // toast.success('Session has been updated');
            // setTimeout(() => {
            //     navigate(-1)
            //     setSpinner(false);
            // }, 2000)
            updateCourseHosts(data?.hostId?.value)

        } catch (error) {
            console.log(error)
        }
        setSpinner(false);
    }


    const handleCreateSession = async (data, media, thum) => {
        try {
            await API.graphql({
                query: createSession,
                variables: {
                    input: {
                        name: data.name,
                        description: data.description,
                        start: data.start,
                        end: data.end,
                        courseId: id,
                        location: JSON.stringify(data.location),
                        duration: data.duration + ' Hours',
                        media: [media],
                        thumbnail: thum,
                        hostId: data?.hostId?.value,
                        deleted: false,
                        sessionCityId: course?.courseCityId
                    }
                }
            })
            updateCourseHosts(data?.hostId?.value)

        } catch (error) {
            console.log(error)
        }
        setSpinner(false);
    }
    const updateCourseHosts = async (sessionHostId) => {
        let courseHosts = course?.hosts || []
        if (!courseHosts?.includes(sessionHostId)) {
            courseHosts = [...courseHosts, sessionHostId]
            try {
                await API.graphql({
                    query: updateCourse,
                    variables: {
                        input: {
                            id,
                            hosts: courseHosts
                        }
                    }
                })
                toast.success('Session has been added');
                setTimeout(() => {
                    navigate(-1)
                    setSpinner(false);
                }, 2000)

            } catch (error) {
                console.log(error)
            }
        }
        else {
            toast.success('Session has been added');
            setTimeout(() => {
                navigate(-1)
                setSpinner(false);
            }, 2000)
        }

    }
    const filterStartTime = (time) => {
        const startDate = new Date();
        const endDate = new Date(course?.end)
        const selectedDate = new Date(time);
        if (selectedDate > startDate && selectedDate < endDate) {
            return startDate.getTime() < selectedDate.getTime();
        } else if (selectedDate === endDate) {
            return selectedDate.getTime() < endDate.getTime();
        }
    };

    const filterEndTime = (time) => {
        const startDate = new Date(startTime);
        const endDate = new Date(course?.end)
        const selectedDate = new Date(time);
        if (selectedDate > startDate && selectedDate < endDate) {
            return startDate.getTime() < selectedDate.getTime();
        } else if (selectedDate === endDate) {
            return selectedDate.getTime() < endDate.getTime();
        }
    };

    // Image Upload Code Start ---- 
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1, accept: { 'video/*': ['.mp4', '.webm'] }, onDrop: acceptedFiles => {
            // setFormData((prev) => ({ ...prev, media: [] }))
            if (files.length + acceptedFiles.length <= 1) {
                setFiles(files.concat(acceptedFiles.map(file => Object.assign(file,
                    {
                        preview: URL.createObjectURL(file)
                    })))
                );
                setMediaInput([])
            }
            else { toast.error("you can drop only one file here") }
        }
    });
    useEffect(() => { return () => files.forEach(file => URL.revokeObjectURL(file.preview)); }, [files]);

    const removeImage = (index) => { setFiles(file => file.filter((_s, i) => (i !== index))) }
    // Image Upload Code End ----


    return (
        <div>
            <Header title={course?.name} name={sessionId ? 'Edit Session' : 'New Session'} container='container'>
                <button className="btn btn-primary me-3" onClick={() => { navigate(-1) }}>Back  </button>
            </Header>

            <div className="container pb-5">
                <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleformData}>
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor='name' className='form-label'>Session Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' name={'name'} id='name' value={values?.name} onChange={handleChange} />
                                <span className='text-danger'>{errors.name}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor='location' className='form-label'>Location <span className='text-danger'>*</span></label>
                                <LocationSearch setFieldValue={setFieldValue} value={values?.location?.name} city={course?.city?.name} />
                                <span className='text-danger'>{errors.location}</span>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='hostId' className='form-label'>Professor Name <span className='text-danger'>*</span></label>
                                <Select options={teachers} id='hostId'
                                    // value={teachers?.find((teacher) => (teacher?.value === values?.hostId))}
                                    value={values?.hostId}
                                    onChange={(e) => { setFieldValue('hostId', e) }} />
                                <span className='text-danger'>{errors.hostId}</span>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='description' className='form-label'>Description <span className='text-danger'>*</span></label>
                                <textarea className="form-control" id="description" name={'description'} value={values?.description} onChange={handleChange} ></textarea>
                                <span className='text-danger'>{errors.description}</span>
                            </div>

                            <div className="form-group"> <label className="form-label mb-1">Video <span className='text-danger'>*</span></label>
                                <small className="form-text text-muted"></small>
                                <div {...getRootProps({ className: 'dropzone dz-message py-6' })}> <input {...getInputProps()} />
                                    <p className='mb-0'>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside className='d-flex flex-row flex-wrap mt-3'>
                                    {mediaInput?.length >= 1 &&
                                        <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '250px', height: '150px' }} key={'media'}>
                                            <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                <video controls >
                                                    <source src={mediaInput} className='d-block w-auto h-100' />
                                                </video>
                                            </div>
                                        </div>
                                    }
                                    {files?.map((file, index) =>
                                        <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '250px', height: '150px' }} key={index}>
                                            <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                <span className="close" onClick={() => { removeImage(index) }}>&times;</span>
                                                <video controls>
                                                    <source src={file.preview ? URL.createObjectURL(new Blob([file], { type: file.type })) : file || values?.video} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(file.preview ? file.preview : file) }} type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>)}
                                </aside>

                            </div>
                            {(files[0] || values.thumbnail) &&
                                <div className="form-group"> <label className="form-label">Video Thumbnail</label>
                                    <small className="form-text text-muted">Please use an image.Supported file formats png, jpg, jpeg</small>
                                    <label className='btn btn-primary btn-sm' htmlFor='media' >
                                        <label className='btn btn-primary btn-sm' htmlFor='thumbnail' >
                                            <input type='file' id='thumbnail' accept='image/*' name="thumbnail" onChange={(e) => {
                                                // console.log(e.target.files[0])
                                                setThumbnail(e.target?.files[0]);
                                                setFieldValue("thumbnail", null);
                                            }} /> Upload
                                        </label>
                                    </label>
                                </div>
                            }

                            {((thumbnail && thumbnail.name) || values.thumbnail) &&
                                <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} >
                                    <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                        {/* <span className="close" onClick={() => { removeImage(index) }}>&times;</span> */}

                                        {typeof (values?.thumbnail) === 'string' ?
                                            values?.thumbnail && <img alt='thumb' src={values?.thumbnail} className='d-block w-auto h-100'
                                            /> :
                                            <img alt='thumb' src={URL.createObjectURL(new Blob([thumbnail], { type: thumbnail.type }))} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(thumbnail.preview ? thumbnail.preview : thumbnail) }} />
                                        }
                                    </div>
                                </div>
                            }

                            {/* <div className="row form-group">
                                <label htmlFor='duration' className='form-label'>Duration <span className='text-danger'>*</span></label>
                                <div className='col'>
                                    <input type={'text'} className='form-control' id='duration' value={values?.duration} onChange={handleChange} placeholder="e.g. 3 hours" />
                                    <span className='text-danger'>{errors.duration}</span>
                                </div>
                            </div> */}

                            <div className='form-group'>
                                <label htmlFor='start' className='form-label'>Start Date & Time <span className='text-danger'>*</span></label>
                                <DatePicker timeIntervals={60} minDate={new Date()} maxDate={course?.end ? new Date(course?.end) : ''} filterTime={filterStartTime} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className='form-control' name='start' selected={values?.start ? new Date(values?.start) : ''} onChange={(e) => { setFieldValue('start', e); setStartTime(e) }} onKeyDown={(e) => { e.preventDefault() }} />
                                <span className='text-danger'>{errors?.start}</span>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='end' className='form-label'>End Date & Time <span className='text-danger'>*</span></label>
                                <DatePicker timeIntervals={60} minDate={values?.start ? new Date(values?.start) : new Date()} maxDate={course?.end ? new Date(course?.end) : ''} filterTime={filterEndTime} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className='form-control' name='end' selected={values?.end ? new Date(values?.end) : ''} onChange={(e) => { setFieldValue('end', e) }} onKeyDown={(e) => { e.preventDefault() }} disabled={!(values?.start)} />
                                <span className='text-danger'>{errors?.end}</span>
                            </div>
                            <div className='mt-5'>
                                <Spinner display={spinner}>
                                    <button type='submit' className='btn btn-primary w-100'>{sessionId ? 'Update Session' : 'Create Session'}</button>
                                </Spinner>
                            </div>
                        </form>
                    )}
                </Formik >
            </div >
        </div >
    )
}

export default SessionCreate
