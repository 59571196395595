import { Auth, API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isAdmin, isOwner } from '../../../utils';
import { UserContext } from '../../../contexts/userContext';
import { getUser } from '../../../graphql/queries';

const LeftMenu = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { user, updateUser } = useContext(UserContext);

    useEffect(() => {
        if (!user.id) {
            getUserDetails()
        }
    }, [])

    const getUserDetails = async () => {
        try {
            let userDetails = await Auth.currentAuthenticatedUser()
            const userResponse = await API.graphql({
                query: getUser,
                variables: { id: userDetails?.username }
            })
            updateUser(userResponse?.data?.getUser)
        } catch (error) {
            console.log(error)
            navigate('/')
        }
    }

    async function signOut() {
        try {
            await Auth.signOut();
            navigate('/')
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <nav className='navbar navbar-vertical fixed-start navbar-expand-md navbar-light' id='sidebar' >
            <div className='container-fluid'>
                <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>

                <img src='/img/venture.png' className='img-fluid' />

                <div className='navbar-user d-md-none'>
                    <div className='dropdown'>
                        <a href="#" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <Avatar image={myUser.image} online={true} /> */}
                            <div className={'avatar avatar-md mx-auto'}>
                                <img className='avatar-img rounded-circle' alt={'avatar'} src={'https://www.cedovip.org/wp-content/uploads/2020/08/placeholder-profile-pic.png'} />
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            <div className='dropdown-item'>
                                {`${user?.firstName} ${user?.lastName}`}
                            </div>
                            <div className='dropdown-item'>
                                {user?.role}
                            </div>
                            <hr className="dropdown-divider"></hr>
                            <div className='dropdown-item cursor-pointer' onClick={signOut}>Logout</div>
                        </div>
                    </div>
                </div>

                <hr />
                <div className='collapse navbar-collapse' id='sidebarCollapse'>
                    <ul className='navbar-nav'>
                        {
                            (isAdmin(user.role) || isOwner(user.role)) &&
                            <li className='nav-item'>
                                <Link className={`nav-link ${location.pathname.includes('/youth-venture/dashboard') ? 'active' : ''}`} to='dashboard'>
                                    <i className='fe fe-home' /> Dashboard
                                </Link>
                            </li>
                        }

                        {
                            (isAdmin(user.role) || isOwner(user.role)) &&
                            <li className='nav-item'>
                                <Link className={`nav-link ${location.pathname.includes('internship') ? 'active' : ''}`} to='internship'>
                                    <i className='fe fe-award' /> Internships
                                </Link>
                            </li>
                        }


                        <li className='nav-item'>
                            <Link className={`nav-link ${location.pathname.includes('course') ? 'active' : ''}`} to='course'>
                                <i className='fe fe-book' /> Courses
                            </Link>
                        </li>
                        {
                            (isAdmin(user.role) || isOwner(user.role)) &&
                            <li className='nav-item'>
                                <Link className={`nav-link ${location.pathname.includes('professor') ? 'active' : ''}`} to='professor'>
                                    <i className='fe fe-user' /> Professors
                                </Link>
                            </li>
                        }
                        {
                            (isAdmin(user.role) || isOwner(user.role)) &&
                            <li className='nav-item'>
                                <Link className={`nav-link ${location.pathname.includes('user') ? 'active' : ''}`} to='user'>
                                    <i className='fe fe-users' /> Users
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default LeftMenu