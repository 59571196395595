import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import { getCourse } from "../../../graphql/queries";
import CourseMenu from "../courses/Menu";
import { updateCourse } from "../../../graphql/mutations";
import Swal from 'sweetalert';
import { toast } from "react-toastify";

const CourseStudent = () => {
    const { id } = useParams();

    const [spinner, showSpinner] = useState(false);
    const [course, setCourse] = useState()

    const getCourseById = async () => {
        try {
            showSpinner(true)
            let response = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            response.data.getCourse.location = JSON.parse(response.data?.getCourse?.location)
            response.data.getCourse.assignment = JSON.parse(response.data?.getCourse?.assignment)
            setCourse(response.data?.getCourse)
            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteAssignment = (assignmentId) => {
        Swal({
            title: `Delete`,
            text: `Are you sure you want to delete it?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            if (status) {
                const assignments = [...course.assignment].filter((assignment) => (assignment.id !== assignmentId));
                API.graphql({
                    query: updateCourse,
                    variables: {
                        input: {
                            id: id,
                            assignment: JSON.stringify(assignments)
                        }
                    }
                }).then((res) => {
                    getCourseById()
                    toast.success('Assignment deleted successfully')
                }).catch((err) => toast.error(err.message))
            }
        })

    }

    useEffect(() => {
        getCourseById()
    }, [])

    return (
        <div>
            <Header title={course?.name} name='Assignments'>
                {new Date(course?.end) > new Date() &&
                    <Link to={`/youth-venture/course/${id}/assignment/create`} className='btn btn-primary'>New Assignment</Link>
                }
            </Header>

            <div className="container-fluid">
                <CourseMenu id={id} />
                <hr />
            </div>

            <Spinner display={spinner}>
                <div className='container-fluid'>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table table-sm table-hover card-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2}>Name</th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base ">
                                    {
                                        (course?.assignment) ? course?.assignment?.map((item) => {
                                            return (
                                                <tr key={item?.name}>
                                                    <td>
                                                        {item?.name}
                                                        <div className="text-muted">{item?.description}</div>
                                                    </td>
                                                    {new Date(course?.end) > new Date() &&
                                                        <td className='text-end text-muted' onClick={(event) => { event.stopPropagation() }}>
                                                            <div className='dropdown'>
                                                                <div className='dropdown-ellipses dropdown-toggle text-mute' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                                    <i className='fe fe-more-vertical'></i>
                                                                </div>
                                                                <div className='dropdown-menu dropdown-menu-end'>
                                                                    <Link to={`/youth-venture/course/${id}/assignment/${item?.id}/editor`} className='dropdown-item' >
                                                                        Edit
                                                                    </Link>
                                                                    <div className='dropdown-item cursor-pointer' onClick={() => { handleDeleteAssignment(item.id) }}>
                                                                        Delete
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>}
                                                </tr>
                                            )
                                        }) :
                                            <tr className=' text-center text-muted'>
                                                <td colSpan={'4'}>
                                                    <h1 className='m-0'>
                                                        No data found
                                                    </h1>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Spinner>
        </div>
    )
}

export default CourseStudent;