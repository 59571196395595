import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { UserContext } from '../contexts/userContext';
import { useContext } from "react";


const TopMenu = () => {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();

	async function signOut() {
		try {
			await Auth.signOut();
			navigate('/')
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}


	return (
		<div className='navbar navbar-expand-md navbar-light d-none d-md-flex'>
			<div className='container-fluid'>
				<form className='form-inline me-4 d-none d-md-flex'>

				</form>
				<div className='navbar-user me-4'>
					<div className='dropdown'>
						<a href="#" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							{/* <Avatar image={myUser.image} online={true} /> */}
							<div className={'avatar avatar-md mx-auto'}>
								<img className='avatar-img rounded-circle' alt={'avatar'} src='/img/default-user.png' />

							</div>
						</a>
						<div className="dropdown-menu dropdown-menu-end">
							<div className='dropdown-item'>
								{`${user?.firstName} ${user?.lastName}`}
							</div>
							<div className='dropdown-item'>
								{user?.role}
							</div>
							<Link className='dropdown-item' to='/reset-password'>
								Reset Password
							</Link>
							<hr className="dropdown-divider"></hr>
							<div className='dropdown-item cursor-pointer' onClick={signOut}>Logout</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)

}

export default TopMenu;
