export const customListCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostId
        name
        description
        duration
        start
        end
        media
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        host {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const customListCourseStudents = /* GraphQL */ `
  query ListCourseStudents(
    $filter: ModelCourseStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        studentId
        assignment
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        approved
        student {
          user {
            gender
            firstName
            lastName
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const customGetSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      courseId
      course {
        id
        hostId
        name
      }
      hostId
      name
      end
      description
      duration
      createdAt
      updatedAt
    }
  }
`;

export const customInternshipApplicantsByInternshipId = /* GraphQL */ `
  query InternshipApplicantsByInternshipId(
    $internshipId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInternshipApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internshipApplicantsByInternshipId(
      internshipId: $internshipId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        approved
        internshipId
        studentId
        createdAt
        updatedAt
         student {
          user {
            firstName
            lastName
            id
            phone
          }
        }
      }
      nextToken
    }
  }
`;

export const customInternshipAttendeesByStudentId = /* GraphQL */ `
  query InternshipAttendeesByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInternshipAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internshipAttendeesByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
        internship {
          id
          title
          start
          end
        }
        student {
          user {
            id
            firstName
            lastName
          }
        }
      }
      nextToken
    }
  }
`;
