/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      grade
      baseline
      posts {
        nextToken
      }
      events {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      applications {
        nextToken
      }
      internshipSessions {
        nextToken
      }
      createdAt
      updatedAt
      studentUserId
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      grade
      baseline
      posts {
        nextToken
      }
      events {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      applications {
        nextToken
      }
      internshipSessions {
        nextToken
      }
      createdAt
      updatedAt
      studentUserId
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      grade
      baseline
      posts {
        nextToken
      }
      events {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      applications {
        nextToken
      }
      internshipSessions {
        nextToken
      }
      createdAt
      updatedAt
      studentUserId
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      media
      content
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      media
      content
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      media
      content
      createdAt
      updatedAt
    }
  }
`;
export const createCurriculum = /* GraphQL */ `
  mutation CreateCurriculum(
    $input: CreateCurriculumInput!
    $condition: ModelCurriculumConditionInput
  ) {
    createCurriculum(input: $input, condition: $condition) {
      id
      name
      title
      description
      media
      exercises
      createdAt
      updatedAt
    }
  }
`;
export const updateCurriculum = /* GraphQL */ `
  mutation UpdateCurriculum(
    $input: UpdateCurriculumInput!
    $condition: ModelCurriculumConditionInput
  ) {
    updateCurriculum(input: $input, condition: $condition) {
      id
      name
      title
      description
      media
      exercises
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurriculum = /* GraphQL */ `
  mutation DeleteCurriculum(
    $input: DeleteCurriculumInput!
    $condition: ModelCurriculumConditionInput
  ) {
    deleteCurriculum(input: $input, condition: $condition) {
      id
      name
      title
      description
      media
      exercises
      createdAt
      updatedAt
    }
  }
`;
export const createGround = /* GraphQL */ `
  mutation CreateGround(
    $input: CreateGroundInput!
    $condition: ModelGroundConditionInput
  ) {
    createGround(input: $input, condition: $condition) {
      id
      name
      sport
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      media
      location
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGround = /* GraphQL */ `
  mutation UpdateGround(
    $input: UpdateGroundInput!
    $condition: ModelGroundConditionInput
  ) {
    updateGround(input: $input, condition: $condition) {
      id
      name
      sport
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      media
      location
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGround = /* GraphQL */ `
  mutation DeleteGround(
    $input: DeleteGroundInput!
    $condition: ModelGroundConditionInput
  ) {
    deleteGround(input: $input, condition: $condition) {
      id
      name
      sport
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      media
      location
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      media
      location
      grounds {
        nextToken
      }
      students {
        nextToken
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      media
      location
      grounds {
        nextToken
      }
      students {
        nextToken
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      media
      location
      grounds {
        nextToken
      }
      students {
        nextToken
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      type
      name
      description
      media
      active
      skills
      groundId
      ground {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      grade
      start
      end
      attendence
      location
      slots
      participants {
        nextToken
      }
      feedbacks {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      type
      name
      description
      media
      active
      skills
      groundId
      ground {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      grade
      start
      end
      attendence
      location
      slots
      participants {
        nextToken
      }
      feedbacks {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      type
      name
      description
      media
      active
      skills
      groundId
      ground {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      grade
      start
      end
      attendence
      location
      slots
      participants {
        nextToken
      }
      feedbacks {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEventParticipant = /* GraphQL */ `
  mutation CreateEventParticipant(
    $input: CreateEventParticipantInput!
    $condition: ModelEventParticipantConditionInput
  ) {
    createEventParticipant(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      approved
      present
      presentAt
      presentLocation
      createdAt
      updatedAt
    }
  }
`;
export const updateEventParticipant = /* GraphQL */ `
  mutation UpdateEventParticipant(
    $input: UpdateEventParticipantInput!
    $condition: ModelEventParticipantConditionInput
  ) {
    updateEventParticipant(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      approved
      present
      presentAt
      presentLocation
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventParticipant = /* GraphQL */ `
  mutation DeleteEventParticipant(
    $input: DeleteEventParticipantInput!
    $condition: ModelEventParticipantConditionInput
  ) {
    deleteEventParticipant(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      approved
      present
      presentAt
      presentLocation
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      comment
      rating
      feedbackQuestion
      media
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      submittedBy
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      comment
      rating
      feedbackQuestion
      media
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      submittedBy
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      comment
      rating
      feedbackQuestion
      media
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      submittedBy
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      role
      firstName
      lastName
      birthday
      gender
      address
      city {
        id
        name
        createdAt
        updatedAt
      }
      deleted
      phone
      email
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      events {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      createdAt
      updatedAt
      userCityId
      userStudentId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      role
      firstName
      lastName
      birthday
      gender
      address
      city {
        id
        name
        createdAt
        updatedAt
      }
      deleted
      phone
      email
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      events {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      createdAt
      updatedAt
      userCityId
      userStudentId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      role
      firstName
      lastName
      birthday
      gender
      city {
        id
        name
        createdAt
        updatedAt
      }
      deleted
      phone
      email
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      events {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      createdAt
      updatedAt
      userCityId
      userStudentId
    }
  }
`;
export const createYouthVentureCity = /* GraphQL */ `
  mutation CreateYouthVentureCity(
    $input: CreateYouthVentureCityInput!
    $condition: ModelYouthVentureCityConditionInput
  ) {
    createYouthVentureCity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateYouthVentureCity = /* GraphQL */ `
  mutation UpdateYouthVentureCity(
    $input: UpdateYouthVentureCityInput!
    $condition: ModelYouthVentureCityConditionInput
  ) {
    updateYouthVentureCity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteYouthVentureCity = /* GraphQL */ `
  mutation DeleteYouthVentureCity(
    $input: DeleteYouthVentureCityInput!
    $condition: ModelYouthVentureCityConditionInput
  ) {
    deleteYouthVentureCity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createUserDevice = /* GraphQL */ `
  mutation CreateUserDevice(
    $input: CreateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    createUserDevice(input: $input, condition: $condition) {
      id
      userId
      type
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDevice = /* GraphQL */ `
  mutation UpdateUserDevice(
    $input: UpdateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    updateUserDevice(input: $input, condition: $condition) {
      id
      userId
      type
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice(
    $input: DeleteUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    deleteUserDevice(input: $input, condition: $condition) {
      id
      userId
      type
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      type
      typeId
      senderId
      sender {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      type
      typeId
      senderId
      sender {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      type
      typeId
      senderId
      sender {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      name
      description
      duration
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      media
      thumbnail
      attachments
      skills
      slots
      location
      active
      rating
      assignment
      sessions {
        nextToken
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
      courseCityId
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      name
      description
      duration
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      media
      thumbnail
      attachments
      skills
      slots
      location
      active
      rating
      assignment
      sessions {
        nextToken
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
      courseCityId
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      name
      description
      duration
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      media
      thumbnail
      attachments
      skills
      slots
      location
      active
      rating
      assignment
      sessions {
        nextToken
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
      courseCityId
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      hostLocation
      name
      description
      duration
      deleted
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      actualStart
      actualEnd
      media
      thumbnail
      attachments
      location
      active
      rating
      students {
        nextToken
      }
      createdAt
      updatedAt
      sessionCityId
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      hostLocation
      name
      description
      duration
      deleted
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      actualStart
      actualEnd
      media
      thumbnail
      attachments
      location
      active
      rating
      students {
        nextToken
      }
      createdAt
      updatedAt
      sessionCityId
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      hostLocation
      name
      description
      duration
      deleted
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      actualStart
      actualEnd
      media
      thumbnail
      attachments
      location
      active
      rating
      students {
        nextToken
      }
      createdAt
      updatedAt
      sessionCityId
    }
  }
`;
export const createCourseStudent = /* GraphQL */ `
  mutation CreateCourseStudent(
    $input: CreateCourseStudentInput!
    $condition: ModelCourseStudentConditionInput
  ) {
    createCourseStudent(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      assignment
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      approved
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseStudent = /* GraphQL */ `
  mutation UpdateCourseStudent(
    $input: UpdateCourseStudentInput!
    $condition: ModelCourseStudentConditionInput
  ) {
    updateCourseStudent(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      assignment
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      approved
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseStudent = /* GraphQL */ `
  mutation DeleteCourseStudent(
    $input: DeleteCourseStudentInput!
    $condition: ModelCourseStudentConditionInput
  ) {
    deleteCourseStudent(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      assignment
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      approved
      createdAt
      updatedAt
    }
  }
`;
export const createSessionStudent = /* GraphQL */ `
  mutation CreateSessionStudent(
    $input: CreateSessionStudentInput!
    $condition: ModelSessionStudentConditionInput
  ) {
    createSessionStudent(input: $input, condition: $condition) {
      id
      sessionId
      session {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      courseId
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      present
      presentAt
      presentLocation
      presentByAdmin
      presentAtByAdmin
      createdAt
      updatedAt
    }
  }
`;
export const updateSessionStudent = /* GraphQL */ `
  mutation UpdateSessionStudent(
    $input: UpdateSessionStudentInput!
    $condition: ModelSessionStudentConditionInput
  ) {
    updateSessionStudent(input: $input, condition: $condition) {
      id
      sessionId
      session {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      courseId
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      present
      presentAt
      presentLocation
      presentByAdmin
      presentAtByAdmin
      createdAt
      updatedAt
    }
  }
`;
export const deleteSessionStudent = /* GraphQL */ `
  mutation DeleteSessionStudent(
    $input: DeleteSessionStudentInput!
    $condition: ModelSessionStudentConditionInput
  ) {
    deleteSessionStudent(input: $input, condition: $condition) {
      id
      sessionId
      session {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      courseId
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      present
      presentAt
      presentLocation
      presentByAdmin
      presentAtByAdmin
      createdAt
      updatedAt
    }
  }
`;
export const createInternship = /* GraphQL */ `
  mutation CreateInternship(
    $input: CreateInternshipInput!
    $condition: ModelInternshipConditionInput
  ) {
    createInternship(input: $input, condition: $condition) {
      id
      title
      description
      location
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      slots
      skills
      media
      thumbnail
      contactPerson
      company
      applicants {
        nextToken
      }
      attendees {
        nextToken
      }
      createdAt
      updatedAt
      internshipCityId
    }
  }
`;
export const updateInternship = /* GraphQL */ `
  mutation UpdateInternship(
    $input: UpdateInternshipInput!
    $condition: ModelInternshipConditionInput
  ) {
    updateInternship(input: $input, condition: $condition) {
      id
      title
      description
      location
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      slots
      skills
      media
      thumbnail
      contactPerson
      company
      applicants {
        nextToken
      }
      attendees {
        nextToken
      }
      createdAt
      updatedAt
      internshipCityId
    }
  }
`;
export const deleteInternship = /* GraphQL */ `
  mutation DeleteInternship(
    $input: DeleteInternshipInput!
    $condition: ModelInternshipConditionInput
  ) {
    deleteInternship(input: $input, condition: $condition) {
      id
      title
      description
      location
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      slots
      skills
      media
      thumbnail
      contactPerson
      company
      applicants {
        nextToken
      }
      attendees {
        nextToken
      }
      createdAt
      updatedAt
      internshipCityId
    }
  }
`;
export const createInternshipApplicant = /* GraphQL */ `
  mutation CreateInternshipApplicant(
    $input: CreateInternshipApplicantInput!
    $condition: ModelInternshipApplicantConditionInput
  ) {
    createInternshipApplicant(input: $input, condition: $condition) {
      id
      approved
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInternshipApplicant = /* GraphQL */ `
  mutation UpdateInternshipApplicant(
    $input: UpdateInternshipApplicantInput!
    $condition: ModelInternshipApplicantConditionInput
  ) {
    updateInternshipApplicant(input: $input, condition: $condition) {
      id
      approved
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInternshipApplicant = /* GraphQL */ `
  mutation DeleteInternshipApplicant(
    $input: DeleteInternshipApplicantInput!
    $condition: ModelInternshipApplicantConditionInput
  ) {
    deleteInternshipApplicant(input: $input, condition: $condition) {
      id
      approved
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInternshipAttendee = /* GraphQL */ `
  mutation CreateInternshipAttendee(
    $input: CreateInternshipAttendeeInput!
    $condition: ModelInternshipAttendeeConditionInput
  ) {
    createInternshipAttendee(input: $input, condition: $condition) {
      id
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      presentAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInternshipAttendee = /* GraphQL */ `
  mutation UpdateInternshipAttendee(
    $input: UpdateInternshipAttendeeInput!
    $condition: ModelInternshipAttendeeConditionInput
  ) {
    updateInternshipAttendee(input: $input, condition: $condition) {
      id
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      presentAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInternshipAttendee = /* GraphQL */ `
  mutation DeleteInternshipAttendee(
    $input: DeleteInternshipAttendeeInput!
    $condition: ModelInternshipAttendeeConditionInput
  ) {
    deleteInternshipAttendee(input: $input, condition: $condition) {
      id
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      presentAt
      createdAt
      updatedAt
    }
  }
`;
