import { API } from "aws-amplify";
import { downloadExcelFile } from "./uitils";
import moment from "moment";


const searchSessions = /* GraphQL */ `
  query SearchSessions(
    $filter: SearchableSessionFilterInput
    $sort: [SearchableSessionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionAggregationInput]
  ) {
    searchSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        name
        course {
            name
        }
        city {
            name
        }
        location
        host {
            firstName
            lastName
        }
        duration
        start
        end
        actualStart
        actualEnd
        students {
            items {
               id
            }
        }
      }
      nextToken
      total
    }
  }
`;


export const getAllSessionsByCourse = async (id, startDate, endDate) => {
    let data = [];
    let nextToken = null;

    do {
        const result = await sessionAPI(id, startDate, endDate, nextToken);
        data = [...data, ...result?.data];
        nextToken = result?.nextToken;
    } while (nextToken);
    downloadExcelFile(data, `Course-Sessions-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`);
};


const sessionAPI = async (id, startDate, endDate, token) => {
    const response = await API.graphql({
        query: searchSessions,
        variables: {
            filter: {
                and: {
                    deleted: { eq: false }, start: {
                        gte: startDate.toISOString(),
                        lte: endDate.toISOString(),
                    },
                    courseId: { eq: id }
                }
            },
            limit: 100,
            sort: { field: 'createdAt', direction: 'desc' },
            nextToken: token,
        },
    });
    const { items, nextToken } = response?.data?.searchSessions;


    for (let item of items) {
        item.city = item?.city?.name
        item.professor = `${item?.host?.firstName || ''} ${item?.host?.lastName || ""}`
        delete item?.host
        item.course = item?.course?.name
        item.location = (item.location) ? JSON.parse(item.location).name : "No data"
        item.startDate = moment(item?.start).format('DD/MM/YYYY')
        delete item?.start
        item.endDate = moment(item?.end).format('DD/MM/YYYY')
        item.studentsAttended = item?.students?.items?.length
        delete item?.end
        delete item?.id
        delete item?.students

    }
    return { data: items, nextToken };
}