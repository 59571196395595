import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../contexts/userContext';
import { Auth } from 'aws-amplify';

const Portals = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        checkUser()
    }, [])

    const checkUser = async () => {
        if (user?.id) {

        } else {
            navigate('/')
        }
    }

    return (
        <div className='row justify-content-center align-items-center text-center vh-100'>
            <Link to={'/outdoor/dashboard'} className='col-auto'>
                <img src='/img/outdoor.png' />
                <h3 className='pt-3'>Outdoor For Girls</h3>
            </Link>
            <Link to={'/youth-venture/dashboard'} className='col-auto'>
                <img src='/img/venture.png' />
                <h3 className='pt-3'>Youth Venture</h3>
            </Link>
        </div>
    )
}

export default Portals
