/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchGrounds = /* GraphQL */ `
  query SearchGrounds(
    $filter: SearchableGroundFilterInput
    $sort: [SearchableGroundSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGroundAggregationInput]
  ) {
    searchGrounds(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchSchools = /* GraphQL */ `
  query SearchSchools(
    $filter: SearchableSchoolFilterInput
    $sort: [SearchableSchoolSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSchoolAggregationInput]
  ) {
    searchSchools(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: [SearchableEventSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventAggregationInput]
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
         city{
          id
          name
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchCourses = /* GraphQL */ `
  query SearchCourses(
    $filter: SearchableCourseFilterInput
    $sort: [SearchableCourseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCourseAggregationInput]
  ) {
    searchCourses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchSessions = /* GraphQL */ `
  query SearchSessions(
    $filter: SearchableSessionFilterInput
    $sort: [SearchableSessionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionAggregationInput]
  ) {
    searchSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchCourseStudents = /* GraphQL */ `
  query SearchCourseStudents(
    $filter: SearchableCourseStudentFilterInput
    $sort: [SearchableCourseStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCourseStudentAggregationInput]
  ) {
    searchCourseStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        courseId
        studentId
        assignment
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        approved
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchSessionStudents = /* GraphQL */ `
  query SearchSessionStudents(
    $filter: SearchableSessionStudentFilterInput
    $sort: [SearchableSessionStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionStudentAggregationInput]
  ) {
    searchSessionStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        sessionId
        courseId
        studentId
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        present
        presentAt
        presentLocation
        presentByAdmin
        presentAtByAdmin
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchInternships = /* GraphQL */ `
  query SearchInternships(
    $filter: SearchableInternshipFilterInput
    $sort: [SearchableInternshipSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipAggregationInput]
  ) {
    searchInternships(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchInternshipApplicants = /* GraphQL */ `
  query SearchInternshipApplicants(
    $filter: SearchableInternshipApplicantFilterInput
    $sort: [SearchableInternshipApplicantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipApplicantAggregationInput]
  ) {
    searchInternshipApplicants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        approved
        internshipId
        studentId
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchInternshipAttendees = /* GraphQL */ `
  query SearchInternshipAttendees(
    $filter: SearchableInternshipAttendeeFilterInput
    $sort: [SearchableInternshipAttendeeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipAttendeeAggregationInput]
  ) {
    searchInternshipAttendees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      grade
      baseline
      posts {
        nextToken
      }
      events {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      applications {
        nextToken
      }
      internshipSessions {
        nextToken
      }
      createdAt
      updatedAt
      studentUserId
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      media
      content
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentId
        media
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCurriculum = /* GraphQL */ `
  query GetCurriculum($id: ID!) {
    getCurriculum(id: $id) {
      id
      name
      title
      description
      media
      exercises
      createdAt
      updatedAt
    }
  }
`;
export const listCurricula = /* GraphQL */ `
  query ListCurricula(
    $filter: ModelCurriculumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurricula(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        description
        media
        exercises
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGround = /* GraphQL */ `
  query GetGround($id: ID!) {
    getGround(id: $id) {
      id
      name
      sport
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      media
      location
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGrounds = /* GraphQL */ `
  query ListGrounds(
    $filter: ModelGroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      name
      media
      location
      grounds {
        nextToken
      }
      students {
        nextToken
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      name
      description
      media
      active
      skills
      groundId
      ground {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      schoolId
      school {
        id
        name
        media
        location
        createdAt
        updatedAt
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      grade
      start
      end
      attendence
      location
      slots
      participants {
        nextToken
      }
      feedbacks {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventParticipant = /* GraphQL */ `
  query GetEventParticipant($id: ID!) {
    getEventParticipant(id: $id) {
      id
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      approved
      present
      presentAt
      presentLocation
      createdAt
      updatedAt
    }
  }
`;
export const listEventParticipants = /* GraphQL */ `
  query ListEventParticipants(
    $filter: ModelEventParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        studentId
        approved
        present
        presentAt
        presentLocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      comment
      rating
      feedbackQuestion
      media
      eventId
      event {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      submittedBy
      user {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comment
        rating
        feedbackQuestion
        media
        eventId
        studentId
        submittedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      role
      firstName
      lastName
      birthday
      gender
      city {
        id
        name
        createdAt
        updatedAt
      }
      deleted
      phone
      email
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      events {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      feedbacks {
        nextToken
      }
      courses {
        nextToken
      }
      sessions {
        nextToken
      }
      createdAt
      updatedAt
      userCityId
      userStudentId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      nextToken
    }
  }
`;
export const getYouthVentureCity = /* GraphQL */ `
  query GetYouthVentureCity($id: ID!) {
    getYouthVentureCity(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listYouthVentureCities = /* GraphQL */ `
  query ListYouthVentureCities(
    $filter: ModelYouthVentureCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYouthVentureCities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserDevice = /* GraphQL */ `
  query GetUserDevice($id: ID!) {
    getUserDevice(id: $id) {
      id
      userId
      type
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const listUserDevices = /* GraphQL */ `
  query ListUserDevices(
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        name
        meta
        token
        arn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      type
      typeId
      senderId
      sender {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        typeId
        senderId
        title
        message
        sent
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      name
      description
      duration
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      media
      thumbnail
      attachments
      skills
      slots
      location
      active
      rating
      hosts
      assignment
      sessions {
        nextToken
      }
      students {
        nextToken
      }
      createdAt
      updatedAt
      courseCityId
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      hostId
      host {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      hostLocation
      name
      description
      duration
      deleted
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      actualStart
      actualEnd
      media
      thumbnail
      attachments
      location
      active
      rating
      students {
        nextToken
      }
      createdAt
      updatedAt
      sessionCityId
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      nextToken
    }
  }
`;
export const getCourseStudent = /* GraphQL */ `
  query GetCourseStudent($id: ID!) {
    getCourseStudent(id: $id) {
      id
      courseId
      course {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      assignment
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      approved
      createdAt
      updatedAt
    }
  }
`;
export const listCourseStudents = /* GraphQL */ `
  query ListCourseStudents(
    $filter: ModelCourseStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        studentId
        assignment
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSessionStudent = /* GraphQL */ `
  query GetSessionStudent($id: ID!) {
    getSessionStudent(id: $id) {
      id
      sessionId
      session {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      courseId
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      present
      presentAt
      presentLocation
      presentByAdmin
      presentAtByAdmin
      createdAt
      updatedAt
    }
  }
`;
export const listSessionStudents = /* GraphQL */ `
  query ListSessionStudents(
    $filter: ModelSessionStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sessionId
        courseId
        studentId
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        present
        presentAt
        presentLocation
        presentByAdmin
        presentAtByAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInternship = /* GraphQL */ `
  query GetInternship($id: ID!) {
    getInternship(id: $id) {
      id
      title
      description
      location
      city {
        id
        name
        createdAt
        updatedAt
      }
      start
      end
      deleted
      slots
      skills
      media
      thumbnail
      contactPerson
      company
      applicants {
        nextToken
      }
      attendees {
        nextToken
      }
      createdAt
      updatedAt
      internshipCityId
    }
  }
`;
export const listInternships = /* GraphQL */ `
  query ListInternships(
    $filter: ModelInternshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInternships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      nextToken
    }
  }
`;
export const getInternshipApplicant = /* GraphQL */ `
  query GetInternshipApplicant($id: ID!) {
    getInternshipApplicant(id: $id) {
      id
      approved
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInternshipApplicants = /* GraphQL */ `
  query ListInternshipApplicants(
    $filter: ModelInternshipApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInternshipApplicants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        approved
        internshipId
        studentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInternshipAttendee = /* GraphQL */ `
  query GetInternshipAttendee($id: ID!) {
    getInternshipAttendee(id: $id) {
      id
      internshipId
      internship {
        id
        title
        description
        location
        start
        end
        deleted
        slots
        skills
        media
        thumbnail
        contactPerson
        company
        createdAt
        updatedAt
        internshipCityId
      }
      studentId
      student {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      presentAt
      createdAt
      updatedAt
    }
  }
`;
export const listInternshipAttendees = /* GraphQL */ `
  query ListInternshipAttendees(
    $filter: ModelInternshipAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInternshipAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studentsBySchoolId = /* GraphQL */ `
  query StudentsBySchoolId(
    $schoolId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentsBySchoolId(
      schoolId: $schoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolId
        grade
        baseline
        createdAt
        updatedAt
        studentUserId
      }
      nextToken
    }
  }
`;
export const postsByStudentId = /* GraphQL */ `
  query PostsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        media
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groundsBySchoolId = /* GraphQL */ `
  query GroundsBySchoolId(
    $schoolId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groundsBySchoolId(
      schoolId: $schoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sport
        schoolId
        media
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByGroundId = /* GraphQL */ `
  query EventsByGroundId(
    $groundId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByGroundId(
      groundId: $groundId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsBySchoolId = /* GraphQL */ `
  query EventsBySchoolId(
    $schoolId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsBySchoolId(
      schoolId: $schoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByHostId = /* GraphQL */ `
  query EventsByHostId(
    $hostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByHostId(
      hostId: $hostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        description
        media
        active
        skills
        groundId
        schoolId
        hostId
        grade
        start
        end
        attendence
        location
        slots
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventParticipantsByEventId = /* GraphQL */ `
  query EventParticipantsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventParticipantsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        studentId
        approved
        present
        presentAt
        presentLocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventParticipantsByStudentId = /* GraphQL */ `
  query EventParticipantsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventParticipantsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        studentId
        approved
        present
        presentAt
        presentLocation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const feedbacksByEventId = /* GraphQL */ `
  query FeedbacksByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbacksByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        comment
        rating
        feedbackQuestion
        media
        eventId
        studentId
        submittedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const feedbacksByStudentId = /* GraphQL */ `
  query FeedbacksByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbacksByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        comment
        rating
        feedbackQuestion
        media
        eventId
        studentId
        submittedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const feedbacksBySubmittedBy = /* GraphQL */ `
  query FeedbacksBySubmittedBy(
    $submittedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbacksBySubmittedBy(
      submittedBy: $submittedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        comment
        rating
        feedbackQuestion
        media
        eventId
        studentId
        submittedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByRole = /* GraphQL */ `
  query UsersByRole(
    $role: UserRole!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRole(
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      nextToken
    }
  }
`;
export const usersByPhone = /* GraphQL */ `
  query UsersByPhone(
    $phone: AWSPhone!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        firstName
        lastName
        birthday
        gender
        deleted
        phone
        email
        createdAt
        updatedAt
        userCityId
        userStudentId
      }
      nextToken
    }
  }
`;
export const userDevicesByUserId = /* GraphQL */ `
  query UserDevicesByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDevicesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        name
        meta
        token
        arn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByDate = /* GraphQL */ `
  query NotificationsByDate(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByDate(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        typeId
        senderId
        title
        message
        sent
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coursesByHostId = /* GraphQL */ `
  query CoursesByHostId(
    $hostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByHostId(
      hostId: $hostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostId
        name
        description
        duration
        start
        end
        deleted
        media
        thumbnail
        attachments
        skills
        slots
        location
        active
        rating
        assignment
        createdAt
        updatedAt
        courseCityId
      }
      nextToken
    }
  }
`;
export const sessionsByCourseId = /* GraphQL */ `
  query SessionsByCourseId(
    $courseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      nextToken
    }
  }
`;
export const sessionsByHostId = /* GraphQL */ `
  query SessionsByHostId(
    $hostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByHostId(
      hostId: $hostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        media
        thumbnail
        attachments
        location
        active
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      nextToken
    }
  }
`;
export const courseStudentsByCourseId = /* GraphQL */ `
  query CourseStudentsByCourseId(
    $courseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseStudentsByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        studentId
        assignment
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseStudentsByStudentId = /* GraphQL */ `
  query CourseStudentsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseStudentsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        studentId
        assignment
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionStudentsBySessionId = /* GraphQL */ `
  query SessionStudentsBySessionId(
    $sessionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionStudentsBySessionId(
      sessionId: $sessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        courseId
        studentId
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        present
        presentAt
        presentLocation
        presentByAdmin
        presentAtByAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionStudentsByCourseId = /* GraphQL */ `
  query SessionStudentsByCourseId(
    $courseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionStudentsByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        courseId
        studentId
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        present
        presentAt
        presentLocation
        presentByAdmin
        presentAtByAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionStudentsByStudentId = /* GraphQL */ `
  query SessionStudentsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionStudentsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        courseId
        studentId
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        present
        presentAt
        presentLocation
        presentByAdmin
        presentAtByAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const internshipApplicantsByInternshipId = /* GraphQL */ `
  query InternshipApplicantsByInternshipId(
    $internshipId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInternshipApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internshipApplicantsByInternshipId(
      internshipId: $internshipId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        approved
        internshipId
        studentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const internshipApplicantsByStudentId = /* GraphQL */ `
  query InternshipApplicantsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInternshipApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internshipApplicantsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        approved
        internshipId
        studentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const internshipAttendeesByInternshipId = /* GraphQL */ `
  query InternshipAttendeesByInternshipId(
    $internshipId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInternshipAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internshipAttendeesByInternshipId(
      internshipId: $internshipId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const internshipAttendeesByStudentId = /* GraphQL */ `
  query InternshipAttendeesByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInternshipAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internshipAttendeesByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
