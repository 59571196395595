import { API } from "aws-amplify";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from "../../../components/Header";
import LocationSearch from "../../../components/locationSearch";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useEffect, useState } from "react";
import { getCourse, listYouthVentureCities, searchUsers } from "../../../graphql/queries";
import { useDropzone } from "react-dropzone";
import { Storage } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import { getAccess } from "../../../utils";
import { isTeacher } from "../../../utils";
import moment from "moment";
import Spinner from '../../../components/Spinner';


const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
    }
  }
`;

const CreateCourses = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [teachers, setTeachers] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [mediaInput, setMediaInput] = useState([])
    const [files, setFiles] = useState([]);
    const [thumbnail, setThumbnail] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [hosts, setHosts] = useState([]);

    // ROLE MANAGEMENT
    const [access, setAccess] = useState({})
    useEffect(() => { getAccessFun(); getCities() }, [])
    const getAccessFun = async () => { setAccess(await getAccess()) }

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        media: '',
        slots: '',
        start: '',
        end: '',
        location: '',
        hostId: '',
        courseCityId: ''
    })

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        slots: Yup.string().test(
            'Is positive?',
            'The number must be greater than 0!',
            (value) => value > 0
        ).required('Required'),
        start: Yup.string().required('Required'),
        end: Yup.string().required('Required'),
        location: Yup.object().required('Required'),
        hostId: Yup.object().required('Required'),
        skills: Yup.array().min(1, 'Required').required(),
        courseCityId: Yup.object().required('Required'),
    })

    const handleformData = async (values) => {
        setSpinner(true)
        let duration = moment.duration(moment(values.end).diff(moment(values.start)));
        values.duration = Math.ceil(duration.asDays())
        let media = values?.media?.[0] || null;
        let thum = values?.thumbnail

        if (files.length > 0) {
            media = await handleUploadImage(files?.[0], values.name)
        }

        if (!thum) {
            if (files[0]?.type === "video/mp4" || (media && media.endsWith('.mp4'))) {
                if (thumbnail?.name) {
                    thum = await handleUploadImage(thumbnail, values.name)
                } else {
                    toast.error('Please add Thumbnail')
                    setSpinner(false);
                    return
                }
            } else {
                thum = media
            }
        }
        values.skills = values?.skills?.map((skill) => (skill?.value))

        if (!media) {
            media = null;
        } else {
            media = [media]
        }

        if (id) {
            handleUpdateCourse(values, media, thum)
        } else {
            handleCreateCourse(values, media, thum)
        }
    }

    const handleUploadImage = async (file, path) => {

        try {
            path = path.replaceAll(' ', '');
            path = path.replaceAll('/', '-');
            const stored = await Storage.put(`${path}/${Math.random().toString(36).substring(2, 15)}.${file?.name?.split('.')?.pop()}`, file, { contentType: file?.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            return url.split('?')[0]
        } catch (error) {
            console.log(error)
        }
    }

    const handleListTeachers = async () => {
        let options = []
        try {
            let res = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: {
                        role: { eq: 'TEACHER' },
                        deleted: { ne: true }
                    }
                }
            })
            options = res?.data?.searchUsers?.items?.map((user) => ({ label: `${user?.firstName} ${user?.lastName}`, value: user?.id }))
            if (isTeacher(access?.role)) {
                options = options.filter((item) => { return access.user && item.value === access.user })
            }
            setTeachers(options)
            return options
        } catch (error) {
            console.log(error)
        }
    }

    const getCourseById = async () => {
        if (id) {
            try {
                let response = await API.graphql({
                    query: getCourse,
                    variables: {
                        id: id
                    }
                })
                response.data.getCourse.location = JSON.parse(response?.data?.getCourse?.location)
                response.data.getCourse.skills = response?.data?.getCourse?.skills?.map((skill) => ({ value: skill, label: skill }))
                const host = response?.data?.getCourse?.host
                response.data.getCourse.hostId = { label: `${host?.firstName} ${host?.lastName}`, value: response?.data?.getCourse?.hostId }
                const cities = await getCities()
                response.data.getCourse.courseCityId = cities.find((city) => (city.value === response?.data?.getCourse?.courseCityId))
                setStartTime(response?.data?.getCourse?.start)
                setFormData(response?.data?.getCourse)
                setMediaInput(response?.data?.getCourse?.media?.[0])
                const responseHosts = response?.data?.getCourse?.hosts || []
                setHosts(responseHosts)
            } catch (error) {
                console.log(error)
            }
        }
    }


    const handleCreateCourse = async (data, media, thum) => {
        try {
            let res = await API.graphql({
                query: createCourse,
                variables: {
                    input: {
                        name: data.name,
                        duration: data.duration + ' Days',
                        description: data?.description,
                        start: data?.start?.toISOString(),
                        end: data?.end?.toISOString(),
                        slots: data?.slots,
                        media: media,
                        skills: data?.skills,
                        location: JSON.stringify(data?.location),
                        hostId: data?.hostId?.value,
                        hosts: [data?.hostId?.value],
                        thumbnail: thum,
                        courseCityId: data?.courseCityId?.value,
                        deleted: false
                    }
                }
            })
            setTimeout(() => {
                toast.success('Course has been added');
                navigate(-1);
                setSpinner(false)
            }, 2000)


        } catch (error) {
            console.log(error)
            setSpinner(false)
        }
    }

    const handleUpdateCourse = async (data, media, thum) => {
        let courseHosts = hosts
        if (!courseHosts.includes(data?.hostId?.value)) {
            courseHosts = [...courseHosts, data?.hostId?.value]
        }
        try {
            await API.graphql({
                query: updateCourse,
                variables: {
                    input: {
                        id,
                        name: data.name,
                        duration: data.duration + ' Days',
                        description: data?.description,
                        start: data?.start,
                        end: data?.end,
                        slots: data?.slots,
                        media: media,
                        skills: data?.skills,
                        location: JSON.stringify(data?.location),
                        hostId: data?.hostId?.value,
                        courseCityId: data?.courseCityId?.value,
                        thumbnail: thum,
                        hosts: courseHosts
                    }
                }
            })
            setTimeout(() => {
                toast.success('Course has been updated');
                navigate(-1);
                setSpinner(false)
            }, 2000)

        } catch (error) {
            console.log(error)
            setSpinner(false)
        }

    }

    useEffect(() => {
        setMediaInput([])
    }, [files])

    useEffect(() => {
        handleListTeachers()
        getCourseById()
    }, [access.role])

    // Image Upload Code Start ---- 
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1, accept: { 'image/*': ['.png', '.jpg', '.jpeg'], 'video/*': ['.mp4'] }, onDrop: acceptedFiles => {
            if (files.length + acceptedFiles.length <= 1) {
                setFiles(files.concat(acceptedFiles.map(file => Object.assign(file,
                    {
                        preview: URL.createObjectURL(file)
                    })))
                );
                setMediaInput([])
            }
            else { toast.error("you can drop only one file here") }
        }
    });
    useEffect(() => { return () => files.forEach(file => URL.revokeObjectURL(file.preview)); }, [files]);

    const removeImage = (index) => { setFiles(file => file.filter((_s, i) => (i != index))) }
    // Image Upload Code End ----

    const filterStartTime = (time) => {
        const startDate = new Date();
        const selectedDate = new Date(time);

        return startDate.getTime() < selectedDate.getTime();

    };

    const filterEndTime = (time) => {
        const startDate = new Date(startTime);
        const selectedDate = new Date(time);
        const todayDate = new Date();
        if (startDate < todayDate) {
            return todayDate.getTime() < selectedDate.getTime();
        }
        return startDate.getTime() < selectedDate.getTime();

    };

    const getCities = async () => {
        try {
            let response = await API.graphql({
                query: listYouthVentureCities,
            })
            let cities = response.data.listYouthVentureCities.items.map((city) => ({ value: city.id, label: city.name }))
            setCityOptions(cities);
            return cities
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <Header title='Youth Venture' name={id ? 'Edit Course' : 'New Bootcamp'} container='container'>
                <button className="btn btn-primary me-3" onClick={() => { navigate(-1) }}>Back  </button>
            </Header>

            <div className="container pb-5">
                <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleformData}>
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor='name' className='form-label'>Course Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' id='name' defaultValue={values.name} onChange={handleChange} />
                                <span className='text-danger'>{errors.name}</span>
                            </div>

                            <div className="form-group">
                                <label htmlFor='location' className='form-label'>Location / Venue <span className='text-danger'>*</span></label>
                                <LocationSearch setFieldValue={setFieldValue} value={values?.location?.name || ''} city={values?.courseCityId?.label} />
                                <span className='text-danger'>{errors.location}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='courseCityId' className='form-label'>City <span className='text-danger'>*</span></label>
                                <Select options={cityOptions} id='courseCityId'
                                    value={values?.courseCityId}
                                    onChange={(e) => { setFieldValue('courseCityId', e) }} />
                                <span className='text-danger'>{errors.courseCityId}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='description' className='form-label'>Description <span className='text-danger'>*</span></label>
                                <textarea className="form-control" id="description" defaultValue={values.description} onChange={handleChange} ></textarea>
                                <span className='text-danger'>{errors.description}</span>
                            </div>

                            {/* <div className="row form-group">
                                <label htmlFor='duration' className='form-label'>Duration <span className='text-danger'>*</span></label>
                                <div className='col'>
                                    <input type={'text'} className='form-control' id='duration' defaultValue={values.duration} onChange={handleChange} placeholder="e.g. 3 days" />
                                    <span className='text-danger'>{errors.duration}</span>
                                </div>
                            </div> */}
                            <div className='row form-group'>
                                <div className='col'>
                                    <label htmlFor='start' className='form-label'>Start Date <span className='text-danger'>*</span></label>
                                    <DatePicker minDate={new Date()} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className='form-control' name='start' selected={values?.start ? new Date(values?.start) : ''} filterTime={filterStartTime} onChange={(e) => { setFieldValue('start', e); setStartTime(e) }} />
                                    <span className='text-danger'>{errors.start}</span>
                                </div>
                                <div className='col'>
                                    <label htmlFor='end' className='form-label'>End Date <span className='text-danger'>*</span></label>
                                    <DatePicker minDate={new Date(values?.start)} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className='form-control' name='start' selected={values?.end ? new Date(values?.end) : ''} filterTime={filterEndTime} onChange={(e) => { setFieldValue('end', e) }} disabled={!(values?.start)} />
                                    <span className='text-danger'>{errors.end}</span>
                                </div>
                            </div>

                            <div className="form-group"> <label className="form-label mb-1">Cover Photo (Add photo/video)</label>
                                <small className="form-text text-muted">Supported file formats png, jpg, jpeg, mp4</small>
                                <div {...getRootProps({ className: 'dropzone dz-message py-6' })}> <input {...getInputProps()} />
                                    <p className='mb-0'>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside className='d-flex flex-row flex-wrap mt-3'>
                                    {mediaInput?.length >= 1 &&
                                        <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '150px', height: '150px' }} key={'media'}>
                                            <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                <div>
                                                    {mediaInput.endsWith(".mp4") ?
                                                        (<video controls className='d-block w-auto h-100' >
                                                            <source src={mediaInput} />
                                                        </video>)
                                                        :
                                                        <img alt='product_image'
                                                            src={mediaInput} className='d-block w-auto h-100'
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {files?.map((file, index) => {
                                        if (file.type === 'video/mp4') {
                                            return <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} key={index}>
                                                <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden', maxHeight: '100px' }}>
                                                    <span className="close" onClick={() => { removeImage(index) }}>&times;</span>
                                                    <video controls className='d-block w-auto h-100'>
                                                        <source src={file.preview ? URL.createObjectURL(new Blob([file], { type: file.type })) : file || values?.video} onLoad={() => { URL.revokeObjectURL(file.preview ? file.preview : file) }} type="video/mp4" className='d-block w-auto h-100' />
                                                    </video>
                                                </div>
                                            </div>
                                        } else {
                                            return <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} key={index}>
                                                <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                    <span className="close" onClick={() => { removeImage(index) }}>&times;</span>
                                                    <img alt='product_image' src={file.preview ? URL.createObjectURL(new Blob([file], { type: file.type })) : file} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(file.preview ? file.preview : file) }} />
                                                </div>
                                            </div>
                                        }

                                    })}
                                </aside>
                            </div>
                            {(files[0]?.type === 'video/mp4' || values.thumbnail || thumbnail.name) &&
                                <div className="form-group"> <label className="form-label">Thumbnail</label>
                                    <small className="form-text text-muted">Please use an image.Supported file formats png, jpg, jpeg</small>
                                    <label className='btn btn-primary btn-sm' htmlFor='media' >
                                        <label className='btn btn-primary btn-sm' htmlFor='thumbnail' >
                                            <input type='file' id='thumbnail' accept='image/*' name="thumbnail" onChange={(e) => { setThumbnail(e.target?.files[0]); setFieldValue("thumbnail", null) }} /> Upload
                                        </label>
                                    </label>
                                </div>
                            }

                            {((thumbnail && thumbnail.name) || values.thumbnail) &&
                                <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '100px', height: '100px' }} >
                                    <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                        {typeof (values?.thumbnail) === 'string'
                                            ? <img alt='thumb' src={values?.thumbnail} className='d-block w-auto h-100' />
                                            : <img alt='thumb' src={URL.createObjectURL(new Blob([thumbnail], { type: thumbnail.type }))} className='d-block w-auto h-100' onLoad={() => { URL.revokeObjectURL(thumbnail.preview ? thumbnail.preview : thumbnail) }} />
                                        }
                                    </div>
                                </div>
                            }

                            {/* <div className="form-group">
                                <label htmlFor='media' className='form-label'>Course Cover</label>
                                <div className='d-flex justify-content-between p-1 py-2 form-control' >
                                    <div>{values?.media?.name || 'Upload Image/Video'}</div>
                                    <label className='btn btn-primary btn-sm' htmlFor='media' >
                                        <input type='file' id='media' accept='image/*,video/*' onChange={(e) => { setFieldValue("media", e.currentTarget.files[0]); }} /> Upload
                                    </label>
                                </div>
                                <span className='text-danger'>{errors.media}</span>
                                <div className='mt-3'>
                                    {values?.media.type === "image/jpeg" && <img src={URL.createObjectURL(values?.media)} width={'50px'} height={'50px'} />}
                                    {values?.media.type === "video/mp4" && <video width="320" height="240" controls>
                                        <source src={URL.createObjectURL(values?.media)} type="video/mp4" />
                                    </video>
                                    }
                                </div>
                            </div> */}
                            <div className='form-group'>
                                <label htmlFor='hostId' className='form-label'>Professor Name <span className='text-danger'>*</span></label>
                                <Select options={teachers} id='hostId'
                                    // value={teachers?.find((teacher) => (teacher?.value === values?.hostId))}
                                    value={values?.hostId}
                                    onChange={(e) => { setFieldValue('hostId', e) }} />
                                <span className='text-danger'>{errors.hostId}</span>
                            </div>

                            <div className='row form-group'>
                                <div className='col'>
                                    <label htmlFor='slots' className='form-label'>Maximum Participants <span className='text-danger'>*</span></label>
                                    <input type={'number'} className='form-control' id='slots' defaultValue={values.slots} onChange={handleChange} />
                                    <span className='text-danger'>{errors.slots}</span>
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor='skills' className='form-label'>Skills students will learn <span className='text-danger'>*</span></label>
                                <CreatableSelect value={values.skills} isClearable isMulti onChange={(event) => { setFieldValue('skills', event) }} />
                                <span className='text-danger'>{errors.skills}</span>
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor='media' className='form-label'>Attachments</label>
                                <div className='d-flex justify-content-between p-1 py-2 form-control' >
                                    <div>Upload Attachment</div>
                                    <label className='btn btn-primary btn-sm' htmlFor='media' >
                                        <input type='file' multiple /> Upload
                                    </label>
                                </div>
                                <span className='text-danger'>{errors.media}</span>
                                <div className='mt-3'>
                                    {values?.media.type === "image/jpeg" && <img src={URL.createObjectURL(values?.media)} width={'50px'} height={'50px'} />}
                                    {values?.media.type === "video/mp4" && <video width="320" height="240" controls>
                                        <source src={URL.createObjectURL(values?.media)} type="video/mp4" />
                                    </video>
                                    }
                                </div>
                            </div> */}

                            <div className='mt-5'>
                                <Spinner display={spinner}>
                                    <button type='submit' className='btn btn-primary w-100'>{id ? 'Update Course' : 'Create Course'}</button>
                                </Spinner>
                            </div>
                        </form>
                    )
                    }
                </Formik >
            </div >
        </div >
    )
}

export default CreateCourses