import React from 'react';
import { Outlet } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import LeftMenu from './components/LeftMenu';

const YouthVenture = () => {

  

  return (
    <div>
      <TopMenu />
      <LeftMenu />
      <div className='main-content' >
        <Outlet />
      </div>
    </div>
  )
}

export default YouthVenture