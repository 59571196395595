import React, { useEffect } from 'react'
import Header from "../../../components/Header";
import * as Yup from 'yup';
import { Formik } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { getCourse } from "../../../graphql/queries";
import { updateCourse } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner';

const EditAssignment = () => {
    const { id, assignmentId } = useParams()
    const navigate = useNavigate();
    const [course, setCourse] = useState();
    const [assignments, setAssignments] = useState([])
    const [currentAssignment, setCurrentAssignment] = useState({
        name: '',
        description: '',
        media: []
    });
    const [mediaInput, setMediaInput] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const handleMediaInput = (e) => {
        var filenames = Array.from(e.target.files).map(function (file) {
            return file;
        });

        setMediaInput(mediaInput.concat(filenames))
    }


    const getCourseById = async () => {
        try {
            let response = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            response.data.getCourse.assignment = JSON.parse(response.data?.getCourse?.assignment)
            let assignment = response.data.getCourse.assignment.find((item) => (item.id === assignmentId))
            setCurrentAssignment(assignment)
            setCourse(response.data?.getCourse)
            setAssignments(response?.data?.getCourse?.assignment || [])
        } catch (error) {
            console.log(error)
        }
    }


    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
    })

    const handleformData = async (values) => {
        setSpinner(true)
        let courseName = course?.name.replaceAll(' ', '').replaceAll('/', '-')
        let data = [...assignments]
        const media = []
        if (mediaInput.length > 0) {
            for (let i = 0; i < mediaInput.length; i++) {
                const stored = await Storage.put(`${courseName}/assignments/${values?.name}-${mediaInput[i].name.split('.').pop()}.${mediaInput[i].name.split('.')[1]}`, mediaInput[i], { contentType: mediaInput[i].type, });
                const url = await Storage.get(stored.key, { level: 'public' })
                media.push(url.split('?')[0])
            }
        }
        const totalMedia = [...currentAssignment.media, ...media]

        let assignment = { id: assignmentId, name: values?.name, description: values?.description, media: totalMedia }
        data = data.map((item) => {
            if (item.id === assignmentId) {
                return assignment
            } else {
                return item
            }
        })
        handleUpdateAssignment(JSON.stringify(data))
    }

    const removeMediaInput = (e, key) => {
        e.stopPropagation()
        let mediaCopy = [...mediaInput];
        mediaCopy.splice(key, 1)
        setMediaInput(mediaCopy);
    }

    const removeOldMedia = (e) => {
        let media = [...currentAssignment.media]
        media = media.filter((item) => (item !== e))
        setCurrentAssignment((prev) => ({ ...prev, media: media }))
    }

    const handleUpdateAssignment = async (data) => {
        try {
            await API.graphql({
                query: updateCourse,
                variables: {
                    input: {
                        id,
                        assignment: data
                    }
                }
            })
            toast.success('Assignment has been Updated');
            navigate(-1)
            setSpinner(false)
        } catch (error) {
            console.log(error)
        }
        setSpinner(false)
    }

    useEffect(() => {
        getCourseById()
    }, [])

    return (
        <div>
            <Header title={course?.name} name={currentAssignment?.name} container='container'>
                <button className="btn btn-primary me-3" onClick={() => { navigate(-1) }}>Back  </button>
            </Header>

            <div className="container pb-5">
                <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={currentAssignment} onSubmit={handleformData}>
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor='name' className='form-label'>Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' id='name' defaultValue={values.name} onChange={handleChange} />
                                <span className='text-danger'>{errors.name}</span>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='description' className='form-label'>Description <span className='text-danger'>*</span></label>
                                <textarea className="form-control" id="description" defaultValue={values.description} onChange={handleChange} ></textarea>
                                <span className='text-danger'>{errors.description}</span>
                            </div>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4 className='card-header-title'>Files / Attachments</h4>
                                    <label className='btn btn-primary btn-sm' htmlFor='attachment' >
                                        <input type='file' multiple={true} id='attachment' name="thumbnail" onChange={handleMediaInput} /> Upload
                                    </label>
                                </div>
                                {values?.media.length > 0 &&
                                    <div className='card-body'>
                                        <div className='list-group list-group-lg list-group-flush list my-n4'>
                                            {values?.media?.map((e, i) => (
                                                <div className='list-group-item'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-auto'>
                                                            {e.endsWith(".mp4") ?
                                                                <i className='fe fe-video '></i> :
                                                                (e.endsWith(".png") || e.endsWith(".jpg") || e.endsWith(".jpeg")) ?
                                                                    <i className='fe fe-image '></i>
                                                                    :
                                                                    <i className='fe fe-file '></i>
                                                            }
                                                        </div>
                                                        <div className='col ms-n2'>
                                                            {e.split('/public/')[1]}
                                                        </div>
                                                        <div className='col-auto'>
                                                            <a href="#!" className="btn btn-sm btn-white d-none d-md-inline-block" onClick={() => { removeOldMedia(e) }}>Remove</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {mediaInput.length > 0 &&
                                    <div className='card-body'>
                                        <div className='list-group list-group-lg list-group-flush list my-n4'>
                                            {mediaInput.map((e, i) => (
                                                <div className='list-group-item'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-auto'>
                                                            {e?.type === "video/mp4" ?
                                                                <i className='fe fe-video '></i> :
                                                                (e?.type === "image/png" || e?.type === "image/jpg" || e?.type === "image/jpeg") ?
                                                                    <i className='fe fe-image '></i>
                                                                    :
                                                                    <i className='fe fe-file '></i>
                                                            }
                                                        </div>
                                                        <div className='col ms-n2'>
                                                            {e?.name}
                                                        </div>
                                                        <div className='col-auto'>
                                                            <a href="#!" className="btn btn-sm btn-white d-none d-md-inline-block" onClick={(e) => { removeMediaInput(e, i) }}>Remove</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='mt-5'>
                                <Spinner display={spinner}>
                                    <button type='submit' className='btn btn-primary w-100'>Update Assignment</button>
                                </Spinner>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default EditAssignment
