import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import { getCourse } from "../../../graphql/queries";
import CourseMenu from "../courses/Menu";
import moment from "moment";
import DatePicker from "react-datepicker";
import { downloadCourseStudents } from "../../../excelHealper/courseStudents";



const studentsByCourseId = /* GraphQL */ `
  query CourseStudentsByCourseId($courseId: ID!) {
    courseStudentsByCourseId(courseId: $courseId) {
      items {
        id
        studentId
        student {
            id
            user {
                id
                firstName
                lastName
                phone
            }
        }
        assignment
        studentRating
        hostRating
        approved
        createdAt
      }
      nextToken
    }
  }
`;

export const getCourseStudent = /* GraphQL */ `
  query GetCourseStudent($id: ID!) {
    getCourseStudent(id: $id) {
      id
      courseId
      studentId
      course {
        sessions {
            items {
                name
                id
                start
            }
        }
        }
        student {
            sessions {
                items {
                presentAt
                present
                id
                sessionId
                }
            }
        }
      approved
    }
  }
`;

const CourseStudent = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [spinner, showSpinner] = useState(false);
    const [course, setCourse] = useState();
    const [students, setStudents] = useState([]);
    const [startDate, setStartDate] = useState(new Date("2023/04/01"));
    const [endDate, setEndDate] = useState(new Date("2023/04/01"));
    const [downloadStatus, setDownloadStatus] = useState(false)


    const getStudentByCourseId = async () => {
        try {
            showSpinner(true)

            // Get Course
            let response = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            response.data.getCourse.assignment = JSON.parse(response?.data?.getCourse?.assignment)
            setCourse(response?.data?.getCourse)

            response = await API.graphql({
                query: studentsByCourseId,
                variables: { courseId: id }
            })

            // checking assignments
            response?.data?.courseStudentsByCourseId?.items?.forEach((item) => {
                item.assignment = JSON.parse(item?.assignment)
                let assignmentStatus = false
                item?.assignment?.forEach((asg) => {
                    if (asg?.response && !assignmentStatus) {
                        assignmentStatus = true
                    }
                })
                item.assignmentStatus = assignmentStatus

            })

            for (const courseStudent of response?.data?.courseStudentsByCourseId?.items) {
                let res = await API.graphql({
                    query: getCourseStudent,
                    variables: { id: courseStudent.id }
                })

                let courseSessions = res?.data?.getCourseStudent?.course?.sessions?.items
                let studentSessions = res?.data?.getCourseStudent?.student?.sessions?.items;

                let studentLastCheckIn = 0
                let count = 0;

                courseSessions?.forEach((session) => {
                    studentSessions?.forEach((studentSession) => {
                        if (session?.id === studentSession?.sessionId) {
                            count = count + 1;
                            if (new Date(studentLastCheckIn) < new Date(studentSession?.presentAt)) {
                                studentLastCheckIn = studentSession?.presentAt
                            }
                        }
                    })

                })
                courseStudent.count = count
                courseStudent.studentLastCheckIn = studentLastCheckIn
            }

            // checking session attendence
            // response?.data?.courseStudentsByCourseId?.items?.forEach((item) => {
            //     item.lastSessionCheckIn = item?.student?.sessions?.items?.sort((a, b) => (new Date(b?.presentAt) - new Date(a?.presentAt)))[0];
            //     item.sessionAttendence = item?.student?.sessions?.items?.map((a) => {
            //         if (a?.present) {
            //             return true
            //         } else {
            //             return false
            //         }
            //     })
            // })

            setStudents(response?.data?.courseStudentsByCourseId?.items)
            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDownload = async (event) => {
        event.preventDefault()
        setDownloadStatus(true)
        await downloadCourseStudents(id, startDate, endDate)
        setDownloadStatus(false)
    }


    useEffect(() => { getStudentByCourseId() }, [])

    return (
        <div>
            <Header title={course?.name} name='Students'></Header>

            <div className="container-fluid">
                <CourseMenu id={id} />
                <hr />
            </div>

            <Spinner display={spinner}>
                <div className='container-fluid'>
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                </div>
                                <div className="col-auto">
                                    <div className="dropdown">
                                        <button className="btn btn-sm btn-white " type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" >
                                            Download <i className="fe fe-download m-2"></i>
                                        </button>
                                        <form className="dropdown-menu dropdown-menu-end dropdown-menu-card " data-popper-placement="bottom-end" style={{ position: "absolute", inset: " 0px 0px auto auto", margin: "0px", transform: " translate(0px, 32px)" }}>
                                            <div className="card-header">
                                                <h4 className="card-header-title">
                                                    Download Excel file
                                                </h4>
                                            </div>
                                            <div className="card-body" style={{ overflowY: 'initial' }}>
                                                <div className="list-group list-group-flush mt-n4 mb-4">
                                                    <div className="list-group-item">
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>Start Date</small>

                                                            </div>
                                                            <div className="col-auto">
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    selectsStart
                                                                    startDate={startDate}
                                                                    dateFormat="MMMM d, yyyy"
                                                                    endDate={endDate}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="list-group-item">
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>End Date</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date) => { setEndDate(new Date(date?.setHours(23, 59, 0, 0))) }}
                                                                    selectsEnd
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    dateFormat="MMMM d, yyyy"
                                                                    minDate={startDate}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn w-100 btn-primary" disabled={downloadStatus} onClick={handleDownload}>
                                                    {downloadStatus ? "Downloading..." : "Download"}
                                                </button>

                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-sm table-hover card-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th className='text-center'>Enrolled On</th>
                                        <th className='text-center'>Assignment</th>
                                        <th className='text-center'>Last Session Checked In</th>
                                        <th className='text-center'>Session Attendence</th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base ">
                                    {
                                        students?.length > 0 ? students?.map((item) => {
                                            return (
                                                <tr key={item.id} onClick={() => { navigate(`/youth-venture/course/${course.id}/student/${item.id}/dashboard`) }} className='cursor-pointer'>
                                                    <td>
                                                        {item?.student?.user?.firstName} {item?.student?.user?.lastName}
                                                    </td>
                                                    <td>
                                                        {item?.student?.user?.phone}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            item?.createdAt ? <>
                                                                {moment(item?.createdAt).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(item?.createdAt).format('h:mm a')}</div>
                                                            </> : "No data"
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {item?.studentRating ? <span className="badge bg-success-soft text-black">Reviewed</span> : (item?.assignmentStatus) ? <span className="badge bg-warning-soft text-black">In Review</span> : <span className="badge bg-secondary-soft">Assignment Pending</span>}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            item?.studentLastCheckIn ? <>
                                                                {moment(item?.studentLastCheckIn).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(item?.studentLastCheckIn).format('h:mm a')}</div>
                                                            </> : "No data"
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {item?.count}
                                                        {/* {item?.sessionAttendence.map((session) =>
                                                            (<i className="fe fe-check  fw-bold m-1 p-1"></i>)
                                                        )} */}

                                                    </td>
                                                    {/* <td className='text-center'>
                                                        {item?.studentRating ? <div>
                                                            {[...new Array(item?.studentRating)]?.map((start) => (<span className={`fa fa-star checked`}></span>))}
                                                            {[...new Array(5 - item?.studentRating)]?.map((start) => (<span className={`fa fa-star stroke`}></span>))}
                                                        </div> : 'NA'}
                                                    </td>
                                                    <td className='text-center'>
                                                        {item?.hostRating ? <div>
                                                            {[...new Array(item?.hostRating)]?.map((start) => (<span className={`fa fa-star checked`}></span>))}
                                                            {[...new Array(5 - item?.hostRating)]?.map((start) => (<span className={`fa fa-star stroke`}></span>))}
                                                        </div> : 'NA'}
                                                    </td> */}
                                                </tr>
                                            )
                                        }) :
                                            <tr className=' text-center text-muted'>
                                                <td colSpan={'6'}>
                                                    <h1 className='m-0'>
                                                        No data found
                                                    </h1>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Spinner>
        </div>
    )
}

export default CourseStudent;