import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import InfoCard from "../../../components/InfoCard";
import { getCourse } from "../../../graphql/queries";
import CourseMenu from "./Menu";
import CourseDetails from "./Details";
import { updateCourse } from "../../../graphql/mutations";
import { useContext } from "react";
import { isAdmin, isOwner } from '../../../utils';
import { UserContext } from "../../../contexts/userContext";
import Swal from 'sweetalert';
import { toast } from "react-toastify";

const countSessionsAndStudnets = /* GraphQL */ `
        query CountSessionsAndStudnets(
            $sessionFilter:SearchableSessionFilterInput
            $courseStudentFilter:SearchableCourseStudentFilterInput
            )
            {
            searchSessions(filter: $sessionFilter) {
            total
            }
            searchCourseStudents(filter: $courseStudentFilter) {
            total
            }
        }
        `;

const CourseDashboard = () => {
    const { id } = useParams();
    const { user } = useContext(UserContext);
    const navigate = useNavigate()

    const [course, setCourse] = useState()
    const [count, setCount] = useState({ students: 0, sessions: 0 })

    const getCourseById = async () => {
        try {
            let response = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            response.data.getCourse.location = JSON.parse(response.data?.getCourse?.location)
            response.data.getCourse.assignment = JSON.parse(response.data?.getCourse?.assignment)
            setCourse(response.data.getCourse)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteCourse = async () => {
        Swal({
            title: `Delete`,
            text: `Are you sure you want to delete it?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            if (status) {
                API.graphql({
                    query: updateCourse,
                    variables: {
                        input: {
                            id: id,
                            deleted: true
                        }
                    }
                }).then((res) => {
                    toast.success('Course deleted successfully')
                    navigate(-1)
                }).catch((err) => toast.error(err.message))
            }
        })
    }

    const getCount = async () => {
        try {
            let response = await API.graphql({
                query: countSessionsAndStudnets,
                variables: {
                    sessionFilter: { deleted: { eq: false }, courseId: { eq: id } },
                    courseStudentFilter: { courseId: { eq: id } }
                }
            })
            let result = response?.data
            setCount((prev) => ({
                ...prev, students: result?.searchCourseStudents?.total, sessions: result?.searchSessions?.total
            }))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCourseById()
        getCount()
    }, [])

    return (
        <div>
            <Header title={course?.name} name='Dashboard'>
                <button className="btn btn-primary me-3" onClick={() => { navigate('/youth-venture/course') }}>Back  </button>
                {course && new Date(course?.end) > new Date() && ((user?.id === course?.hostId) || isOwner(user?.role) || isAdmin(user?.role)) &&
                    <Link to={`/youth-venture/course/${id}/editor`} className='btn btn-primary'>Edit Course</Link>
                }
                {isOwner(user.role) && (course && (new Date(course?.end) > new Date())) &&
                    <button className="btn btn-danger ms-3" onClick={handleDeleteCourse}>Delete  </button>}
            </Header>

            <div className="container-fluid">
                <CourseMenu id={id} />
                <hr />
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <CourseDetails />
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="col">
                            <InfoCard title='Sessions'>{count?.sessions}</InfoCard>
                        </div>
                        <div className="col">
                            <InfoCard title='Students'>{count?.students}</InfoCard>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default CourseDashboard;