import { API } from "aws-amplify";
import { downloadExcelFile } from "./uitils";
import moment from "moment";


const searchCourses = /* GraphQL */ `
  query SearchCourses(
    $filter: SearchableCourseFilterInput
    $sort: [SearchableCourseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCourseAggregationInput]
  ) {
    searchCourses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        host {
          firstName
          lastName
        }
          city{
          name
        }
        duration
        start
        end
        slots
      }
      nextToken
      total
    }
  }
`;

const searchSessions = /* GraphQL */ `
  query SearchSessions(
    $filter: SearchableSessionFilterInput
    $sort: [SearchableSessionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionAggregationInput]
  ) {
    searchSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        courseId
        hostId
        hostLocation
        name
        description
        duration
        deleted
        start
        end
        actualStart
        actualEnd
        location
        rating
        createdAt
        updatedAt
        sessionCityId
      }
      nextToken
      total
    }
  }
`;

export const searchCourseStudents = /* GraphQL */ `
  query SearchCourseStudents(
    $filter: SearchableCourseStudentFilterInput
  ) {
    searchCourseStudents(
      filter: $filter
    ) {
      total
    }
  }
`;

export const searchSessionStudents = /* GraphQL */ `
  query SearchSessionStudents(
    $filter: SearchableSessionStudentFilterInput
    $sort: [SearchableSessionStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionStudentAggregationInput]
  ) {
    searchSessionStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        sessionId
        courseId
        studentId
        studentRating
        studentFeedback
        studentFeedbackMedia
        hostRating
        hostFeedback
        hostFeedbackMedia
        present
        presentAt
        presentLocation
        presentByAdmin
        presentAtByAdmin
        createdAt
        updatedAt
        student {
          user {
            firstName
            lastName
            phone
          }
        }
      }
      nextToken
      total
    }
  }
`;


export const getAllCourses = async (startDate, endDate) => {
  let data = [];
  let nextToken = null;

  do {
    const result = await coursesAPI(startDate, endDate, nextToken);
    data = [...data, ...result?.data];
    nextToken = result?.nextToken;
  } while (nextToken);
  downloadExcelFile(data, `Courses-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`);
};

const getCourseSessions = async (id) => {
  try {
    let response = await API.graphql({
      query: searchSessions,
      variables: {
        filter: { and: { courseId: { eq: id }, deleted: { eq: false } } }
      }
    })
    return response?.data?.searchSessions?.items
  } catch (error) {
    console.log(error)
  }
}


const getCourseStudentCount = async (id) => {
  try {
    let response = await API.graphql({
      query: searchCourseStudents,
      variables: {
        filter: { courseId: { eq: id } }
      }
    })
    return response?.data?.searchCourseStudents?.total
  } catch (error) {
    console.log(error)
  }
}

const getSessionStudnets = async (id) => {
  try {
    let response = await API.graphql({
      query: searchSessionStudents,
      variables: {
        filter: { sessionId: { eq: id } }
      }
    })
    return response?.data?.searchSessionStudents?.items
  } catch (error) {
    console.log(error)
  }
}

const coursesAPI = async (startDate, endDate, token) => {
  const response = await API.graphql({
    query: searchCourses,
    variables: {
      filter: {
        deleted: { eq: false }, start: {
          gte: startDate.toISOString(),
          lte: endDate.toISOString(),
        }
      },
      limit: 100,
      sort: { field: 'createdAt', direction: 'desc' },
      nextToken: token,
    },
  });
  const { items, nextToken } = response?.data?.searchCourses;
  let newArray = []
  for (let item of items) {
    item.professor = `${item?.host?.firstName || ''} ${item?.host?.lastName || ""}`
    item.studentsEnrolled = await getCourseStudentCount(item?.id)
    item.city = item?.city?.name
    delete item?.host
    item.startDate = moment(item?.start).format('DD/MM/YYYY')
    delete item?.start
    item.endDate = moment(item?.end).format('DD/MM/YYYY')
    let sessions = await getCourseSessions(item.id)
    delete item?.end
    delete item?.id

    if (sessions.length === 0) {
      newArray = [...newArray, item]
    } else {
      for (const session of sessions) {
        let sessionStudents = await getSessionStudnets(session?.id)

        if (sessionStudents.length > 0) {
          for (let sessionStudent of sessionStudents) {
            newArray.push({
              ...item,
              sessionName: session?.name || "",
              professorCheckIn: session?.actualStart ? moment(session?.actualStart).format('DD/MM/YYYY hh:mm a') : "No Data",
              professorLocation: (session?.hostLocation) ? JSON.parse(session?.hostLocation).name : "No data",
              studentName: `${sessionStudent?.student?.user?.firstName || ''} ${sessionStudent?.student?.user?.lastName || ""}`,
              studentCheckIn: sessionStudent?.presentAt ? moment(sessionStudent?.presentAt).format('DD/MM/YYYY hh:mm a') : "No Data"
            })
          }
        } else {
          newArray.push({
            ...item,
            sessionName: session?.name || "",
            professorCheckIn: session?.actualStart ? moment(session?.actualStart).format('DD/MM/YYYY hh:mm a') : "No Data",
            professorLocation: (session?.hostLocation) ? JSON.parse(session?.hostLocation).name : "No data",
          })
        }
      }
    }


  }
  return { data: newArray, nextToken };
}