import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng, } from 'react-places-autocomplete';


const LocationSearch = ({ setFieldValue, value, city }) => {


    const [input, setInput] = useState(value);

    useEffect(() => {
        setInput(value)
    }, [value])

    const bounds = {
        BANGALORE: {
            south: 12.7342888,
            west: 77.3791981,
            north: 13.173706,
            east: 77.8826809,
        },
        MUMBAI: {
            south: 18.8928676,
            west: 72.7758729,
            north: 19.2716339,
            east: 72.9864994,
        },
        GOA: {
            south: 14.8984403,
            west: 73.6769517,
            north: 15.8010166,
            east: 74.3364371,
        },
    };

    const cityName = city;
    const cityBounds = bounds[cityName];

    const handleSelect = (address, placeId) => {
        setInput(address)
        geocodeByPlaceId(placeId)
            .then((selectedAddress) => {
                let addressResult = {}
                addressResult.lat_lng = `${selectedAddress[0].geometry?.location.lat()},${selectedAddress[0].geometry?.location.lng()}`
                addressResult.name = address.split(',')[0]
                addressResult.place_id = selectedAddress[0].place_id;
                setFieldValue('location', addressResult);
            })
    };

    const restrictions = {
        country: 'in',
    }

    return (
        <div>
            <PlacesAutocomplete name='address1' value={input} onSelect={handleSelect}
                onChange={value => setInput(value)}
                searchOptions={{
                    componentRestrictions: restrictions,
                    bounds: cityBounds || '',
                    strictBounds: true,
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) =>
                (<div className='search-input-container col-12 p-0'>
                    <input {...getInputProps({ placeholder: 'Search Location', className: 'form-control business-form', })} />
                    <div className='autocomplete-container bg-light'>
                        {suggestions.map((suggestion) => {
                            const className = 'suggestion-item';
                            return (
                                <div key={suggestion?.placeId} {...getSuggestionItemProps(suggestion, { className, })} className='border-bottom border-dark-subtle p-2'>
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>)}
            </PlacesAutocomplete>
        </div>)
};

export default LocationSearch;