import React, { useState } from 'react'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';


const ResetPassword = () => {
    const navigate = useNavigate();
    const [resetIntialValues, setResetIntialValues] = useState({ new_password: '', confirm_password: '', old_password: '' });
    const [oldPasswordType, setOldPasswordType] = useState('password');
    const [passwordType, setPasswordType] = useState('password');
    const [confirmType, setconfirmType] = useState('password');
    const [spinner, showSpinner] = useState(false);

    const formSchema = Yup.object().shape({
        old_password: Yup.string().required('Required'),
        new_password: Yup.string().required('No password provided.')
            .min(6, 'Password is too short - should be 6 chars minimum.'),
        confirm_password: Yup.string().required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });

    const handlePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const ResetPassword = (values) => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, values.old_password, values.new_password);
            })
            .then((data) => { toast.success("Password Updated"); navigate('/') })
            .catch((err) => { toast.error('Incorrect old password ') });
    }

    return (
        <div>
            <div className="d-flex bg-auth " style={{ display: 'block' }}>
                <div className="container">
                    <div className="row vh-100 align-items-center">
                        <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-lg-block">
                            <div className="text-center">
                                <img src="/img/login.svg" alt="..." className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                            <h2 className="display-4 text-center mb-5">
                                Reset Password
                            </h2>
                            <Formik
                                initialValues={resetIntialValues}
                                validationSchema={formSchema}
                                enableReinitialize={true}
                                onSubmit=
                                {(values) => {
                                    ResetPassword(values)
                                }}
                            >
                                {({ handleChange, handleSubmit, values, errors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='old_password' className='form-label'>Old Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${oldPasswordType}`} id='old_password' value={values.old_password} placeholder="Enter old password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(oldPasswordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={() => { setOldPasswordType((prev) => (prev === 'password' ? 'text' : 'password')) }}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.old_password}</span>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='new_password' className='form-label'>New Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${passwordType}`} id='new_password' value={values.new_password} placeholder="Enter new password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(passwordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={handlePasswordType}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.new_password}</span>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='confirm_password' className='form-label'>Confirm  Password</label>
                                            <div className="input-group input-group-merge">
                                                <Field className="form-control" type={`${confirmType}`} id='confirm_password' value={values.confirm_password} placeholder="Confirm password" onChange={handleChange} />
                                                <span className="input-group-text">
                                                    <i className={(confirmType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={() => { setconfirmType((prev) => (prev === 'password' ? 'text' : 'password')) }}></i>
                                                </span>
                                            </div>
                                            <span className='text-danger'>{errors.confirm_password}</span>
                                        </div>
                                        <Spinner display={spinner}>
                                            <div className=' form-group'>
                                                <button type='submit' className='btn btn-primary btn-lg w-100' >Change Password
                                                </button>
                                            </div>
                                        </Spinner>
                                    </form>
                                )}
                            </Formik>
                        </div>

                    </div>

                </div>

            </div >
        </div>
    )
}

export default ResetPassword
