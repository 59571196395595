import { API } from "aws-amplify";
import { downloadExcelFile } from "./uitils";
import moment from "moment";

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        firstName
        lastName
        role
        gender
        phone
        email
        city{
          name
        }
        createdAt
      }
      nextToken
      total
      
    }
  }
`;



export const getAllUser = async (startDate, endDate, nextToken = null) => {
  let data = [];
  do {
    const result = await getUsersAPI(startDate, endDate, nextToken);
    data = [...data, ...result?.data]
    nextToken = result?.nextToken;
  } while (nextToken);
  downloadExcelFile(data, `Users-${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`)
}

const getUsersAPI = async (startDate, endDate, token) => {
  const response = await API.graphql({
    query: searchUsers,
    variables: {
      filter: {
        and: {
          deleted: { eq: false }, createdAt: {
            gte: startDate.toISOString(),
            lte: endDate.toISOString(),
          }
        }
      },
      limit: 100,
      nextToken: token,
    },
  });
  const { items, nextToken } = response?.data?.searchUsers;
  items.forEach(item => {
    item.city = item?.city?.name
    item.registeredOn = moment(item?.createdAt).format('DD/MM/YYYY')
    delete item.createdAt
  });
  return { data: items, nextToken };
}