import { API, Auth } from 'aws-amplify';
import { Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from "../../../components/Header";
import { createUser, updateUser } from '../../../graphql/mutations';
import { listYouthVentureCities, searchUsers } from '../../../graphql/queries';
import { tempPassword } from '../../../utils';
import Select from 'react-select';
import Spinner from '../../../components/Spinner';


const Create = () => {
    const navigate = useNavigate();
    const [cityOptions, setCityOptions] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const formData = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: '',
        city: '',
        address: ''
    }

    const formSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Provide valid mail')
            .required('Email Required')
            .test(
                "Validate Email",
                (value) => {
                    const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return re.test(String(value).toLowerCase())
                },
            ),
        phone: Yup.string().min(10, "Phone number is not valid").max(10, "Phone number is not valid").required('Required'),
        city: Yup.object().required('Required'),
        gender: Yup.string(),
        address: Yup.string().required("Required")
    })

    const handleRegister = async (data) => {
        setSpinner(true);
        const username = `+91${data.phone}`;
        let response = await API.graphql({
            query: searchUsers,
            variables: {
                filter: { and: { phone: { eq: username }, deleted: { eq: false } } }
            }
        })

        if (response.data.searchUsers.items.length > 0) {
            if (response?.data?.searchUsers?.items[0]?.role === "OWNER") {
                toast.error('You Don\'t have access to change this role')
                setSpinner(false);
                return;
            }
            if (response.data.searchUsers.items[0].role === "TEACHER") {
                toast.error(`${data?.firstName} ${data?.lastName} is already present as a Professor`)
                setSpinner(false);
                return;
            }
            console.log('Update user role to Professor')
            response = await API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: response.data.searchUsers.items[0].id,
                        role: 'TEACHER',
                        email: data?.email,
                        gender: data.gender,
                        address: JSON.stringify({ address: data?.address })
                    }
                }
            })

            toast.success(`${data.firstName} ${data.lastName} has been invited as a Professor`)
        } else {
            const cognitoUser = await Auth.signUp({
                username: username,
                password: tempPassword,
                attributes: {
                    phone_number: username,
                    email: data.email,
                    given_name: data.firstName,
                    family_name: data.lastName,
                }
            });
            // console.log('cognitoUser', cognitoUser)

            response = await API.graphql({
                query: createUser,
                variables: {
                    input: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phone: username,
                        id: cognitoUser.userSub,
                        role: 'TEACHER',
                        userCityId: data.city.value,
                        gender: data.gender,
                        deleted: false,
                        address: JSON.stringify({ address: data?.address })
                    }
                }
            })
            toast.success(`${data.firstName} ${data.lastName} has been invited as a Professor`)
        }
        setTimeout(() => {
            setSpinner(false);
            navigate('/youth-venture/professor')
        }, 2000)

        // handleSignUp(data)
    }

    const getCities = async () => {
        try {
            let response = await API.graphql({
                query: listYouthVentureCities,
            })
            let cities = response.data.listYouthVentureCities.items.map((city) => ({ value: city.id, label: city.name }))
            setCityOptions(cities);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCities()
    }, [])


    return (
        <div>
            <Header title='Youth Venture' name='Invite Professor' container='container'></Header>
            <div className="container pb-5">
                <Formik enableReinitialize={true} validationSchema={formSchema} initialValues={formData} onSubmit={handleRegister}>
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor='firstName' className='form-label'>First Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' name='firstName' id='firstName' value={values.firstName} onChange={handleChange} />
                                <span className='text-danger'>{errors.firstName}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='lastName' className='form-label'>Last Name <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' name='lastName' id='lastName' value={values.lastName} onChange={handleChange} />
                                <span className='text-danger'>{errors.lastName}</span>
                            </div>

                            <div className="form-group">
                                <label htmlFor='location' className='form-label'>Address <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' name='address'  value={values.address} onChange={handleChange} />
                                <span className='text-danger'>{errors.address}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='city' className='form-label'>City <span className='text-danger'>*</span></label>
                                <Select options={cityOptions} id='city'
                                    value={values?.city}
                                    onChange={(e) => { setFieldValue('city', e) }} />
                                <span className='text-danger'>{errors.city}</span>
                            </div>

                            <div className='mb-3 form-group'>
                                <label htmlFor='email' className='form-label'>Email address <span className='text-danger'>*</span></label>
                                <input type={'text'} className='form-control' id='email' name={'email'} value={values.email} onChange={handleChange} />
                                <span className='text-danger'>{errors.email}</span>
                            </div>

                            <div className='mb-3 form-group'>
                                <label htmlFor='phone' className='form-label'>Phone Number <span className='text-danger'>*</span></label>
                                <input type={'number'} className='form-control' id='phone' name={'phone'} value={values.phone} onChange={handleChange} />
                                <span className='text-danger'>{errors.phone}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='gender' className='form-label'>Gender </label>
                                <select className='form-control' as='select' name='gender' value={values.gender} onChange={handleChange}>
                                    <option>Select Gender</option>
                                    <option value='MALE'>Male</option>
                                    <option value='FEMALE'>Female</option>
                                    <option value='OTHER'>Other</option>
                                </select>
                                <span className='text-danger'>{errors.gender}</span>
                            </div>

                            <div className='mt-5'>
                                <Spinner display={spinner}>
                                    <button type='submit' className='btn btn-primary w-100'>Invite Professor</button>
                                </Spinner>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Create
