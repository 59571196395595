import { API } from "aws-amplify";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { getCourse } from "../../../graphql/queries";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const CourseDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        media: '',
        slots: '',
        start: '',
        end: '',
        location: '',
        hostId: '',
    })


    const getCourseById = async () => {
        if (id) {
            try {
                let response = await API.graphql({
                    query: getCourse,
                    variables: {
                        id: id
                    }
                })
                response.data.getCourse.location = JSON.parse(response?.data?.getCourse?.location)
                setFormData(response?.data?.getCourse)

            } catch (error) {
                console.log(error)
            }
        }
    }


    useEffect(() => {
        getCourseById()
    }, [])


    return (
        <div>
            <div className="container pb-5">
                <Formik enableReinitialize={true} initialValues={formData} >
                    {({ values }) => (

                        <form >
                            <div className="form-group">
                                <label htmlFor='name' className='form-label'>Course Name </label>
                                <input type={'text'} className='form-control' id='name' defaultValue={values?.name} disabled={true} />
                            </div>

                            <div className="form-group">
                                <label htmlFor='location' className='form-label'>Location / Venue </label>
                                <input type={'text'} className='form-control' id='name' value={values?.location?.name || ""} disabled={true} />
                            </div>

                            <div className="form-group">
                                <label htmlFor='name' className='form-label'>City </label>
                                <input type={'text'} className='form-control' id='courseCityId' defaultValue={values?.city?.name} disabled={true} />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='description' className='form-label'>Description </label>
                                <textarea className="form-control pe-2" id="description" rows="6" defaultValue={values?.description || ""} disabled={true} ></textarea>

                            </div>

                            <div className='row form-group'>
                                <div className='col'>
                                    <label htmlFor='start' className='form-label'>Start Date </label>
                                    <input type={'text'} className='form-control' id='name' value={moment(values?.start).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                                </div>
                                <div className='col'>
                                    <label htmlFor='end' className='form-label'>End Date</label>
                                    <input type={'text'} className='form-control' id='name' value={moment(values?.end).format('MMMM DD, YYYY h:mm a')} disabled={true} />
                                </div>
                            </div>
                            {values?.media?.length > 0 &&
                                <div className="form-group"> <label className="form-label mb-1">Cover Photo</label>
                                    <aside className='d-flex flex-row flex-wrap mt-3'>
                                        <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box img-wrap' style={{ width: '350px', height: '250px' }} key={'media'}>
                                            <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                                                <div>
                                                    {values?.media?.[0]?.endsWith(".mp4") ?
                                                        (<video controls className='d-block w-auto h-100' >
                                                            <source src={values?.media?.[0]} />
                                                        </video>)
                                                        :
                                                        <img alt='product_image'
                                                            src={values?.media?.[0]} className='d-block w-auto h-100'
                                                        />
                                                    }
                                                </div>
                                                <div>
                                                    {values?.media?.[0]?.endsWith(".mp4") && <img alt='product_image'
                                                        src={values?.thumbnail} className='d-block w-auto h-100'
                                                    />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            }
                            <div className='form-group'>
                                <label htmlFor='hostId' className='form-label'>Professor Name </label>
                                <input type={'text'} className='form-control' id='name' value={`${values?.host?.firstName} ${values?.host?.lastName}`} disabled={true} />
                            </div>

                            <div className='row form-group'>
                                <div className='col'>
                                    <label htmlFor='slots' className='form-label'>Maximum Participants </label>
                                    <input type={'number'} className='form-control' id='slots' defaultValue={values?.slots} disabled={true} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor='skills' className='form-label'>Skills students will learn </label>
                                <ul>
                                    {values?.skills?.map((skill, index) => (
                                        <li key={index}>{skill}</li>
                                    ))}

                                </ul>
                            </div>
                        </form>)}
                </Formik >
            </div >
        </div >
    )
}

export default CourseDetails