import { Link, NavLink } from "react-router-dom";

const CourseMenu = ({ id }) => {
    return (
        <ul className="nav nav-tabs nav-overflow header-tabs">
            <li className="nav-item">
                <NavLink to={`/youth-venture/course/${id}/dashboard`} className="nav-link">
                    Dashboard
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to={`/youth-venture/course/${id}/session`} className="nav-link">
                    Sessions
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to={`/youth-venture/course/${id}/assignment`} className="nav-link">
                    Assignment
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to={`/youth-venture/course/${id}/student`} className="nav-link">
                    Students
                </NavLink>
            </li>
        </ul>
    )
}

export default CourseMenu;