import React from 'react'

const Spinner = ({ display, children }) => {
	return (
		<>
			{display ? (
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center p-3'>
							<div className="spinner-border text-secondary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export default Spinner;