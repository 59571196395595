import { API } from "aws-amplify";
import { downloadExcelFile } from "./uitils";
import moment from "moment";

const searchCourseStudents = /* GraphQL */ `
  query SearchCourseStudents(
    $filter: SearchableCourseStudentFilterInput
    $sort: [SearchableCourseStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCourseStudentAggregationInput]
  ) {
    searchCourseStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        student {
          user {
            firstName
            lastName
          }
        }
         course {
          name
          host {
            firstName
            lastName
          }
        }
        studentRating
        studentFeedback
        hostRating
        hostFeedback
        createdAt
        studentId
        courseId
      }
      nextToken
      total
    }
  }
`;

export const searchSessionStudents = /* GraphQL */ `
  query SearchSessionStudents(
    $filter: SearchableSessionStudentFilterInput
    $sort: [SearchableSessionStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionStudentAggregationInput]
  ) {
    searchSessionStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
    }
  }
`;

const getStudentAttendence = async (courseId, sessionId) => {
  try {
    const response = await API.graphql({
      query: searchSessionStudents,
      variables: {
        filter: { and: { courseId: { eq: courseId }, sessionId: { eq: sessionId } } }
      }
    });

    return response?.data?.searchSessionStudents?.total

  } catch (error) {
    console.log(error)
  }
}


export const downloadCourseStudents = async (id, startDate, endDate) => {
  let data = [];
  let nextToken = null;

  do {
    const result = await courseStudentsAPI(id, startDate, endDate, nextToken);
    data = [...data, ...result?.data];
    nextToken = result?.nextToken;
  } while (nextToken);
  downloadExcelFile(data, `CourseStudent-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`);
};




const courseStudentsAPI = async (id, startDate, endDate, token) => {
  const response = await API.graphql({
    query: searchCourseStudents,
    variables: {
      filter: {
        and: {
          createdAt: {
            gte: startDate.toISOString(),
            lte: endDate.toISOString(),
          },
          courseId: { eq: id }
        }
      },
      limit: 100,
      sort: { field: 'createdAt', direction: 'desc' },
      nextToken: token,
    },
  });
  const { items, nextToken } = response?.data?.searchCourseStudents;

  for (let item of items) {
    item.studentName = `${item?.student?.user?.firstName || ""} ${item?.student?.user?.lastName || ""}`
    item.professor = `${item?.course?.host?.firstName || ""} ${item?.course?.host?.lastName || ""}`
    delete item?.host
    delete item?.student
    item.course = item?.course?.name
    item.enrolledOn = moment(item?.createdAt).format('DD/MM/YYYY')
    item.sessionsAttended = await getStudentAttendence(item.courseId, item.studentId);
    delete item?.id
    delete item?.students
    delete item.createdAt

  }
  return { data: items, nextToken };
}