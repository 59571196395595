import { API } from "aws-amplify";
import { downloadExcelFile } from "./uitils";
import moment from "moment";

const searchInternshipAttendees = /* GraphQL */ `
  query SearchInternshipAttendees(
    $filter: SearchableInternshipAttendeeFilterInput
    $sort: [SearchableInternshipAttendeeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipAttendeeAggregationInput]
  ) {
    searchInternshipAttendees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

const searchInternships = /* GraphQL */ `
  query SearchInternships(
    $filter: SearchableInternshipFilterInput
    $sort: [SearchableInternshipSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipAggregationInput]
  ) {
    searchInternships(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        company
        city{
            name
        }
        start
        end
        slots
        contactPerson
        createdAt
      }
      nextToken
      total
      
    }
  }
`;

const searchInternshipApplicants = /* GraphQL */ `
  query SearchInternshipApplicants(
    $filter: SearchableInternshipApplicantFilterInput
    $sort: [SearchableInternshipApplicantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipApplicantAggregationInput]
  ) {
    searchInternshipApplicants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        approved
        internshipId
        studentId
        createdAt
        updatedAt
        student {
          user {
            firstName
            lastName
            phone
          }
        }
      }
      nextToken
      total
    }
  }
`;


export const downloadInternships = async (startDate, endDate, nextToken = null) => {
  let data = [];
  do {
    const result = await internshipAPI(startDate, endDate, nextToken);
    data = [...data, ...result?.data]

    nextToken = result?.nextToken;
  } while (nextToken);
  downloadExcelFile(data, `Internships-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`)
}

const getInternshipApplicants = async (id) => {
  try {
    let response = await API.graphql({
      query: searchInternshipApplicants,
      variables: {
        filter: { internshipId: { eq: id } }
      }
    })
    return response?.data?.searchInternshipApplicants?.items
  } catch (error) {
    console.log(error)
  }
}

const getInternshipAttendee = async (id, studentId) => {
  try {
    let response = await API.graphql({
      query: searchInternshipAttendees,
      variables: {
        filter: { and: { internshipId: { eq: id }, studentId: { eq: studentId } } }
      }
    })
    return response?.data?.searchInternshipAttendees?.items
  } catch (error) {
    console.log(error)
  }
}

const internshipAPI = async (startDate, endDate, token) => {
  const response = await API.graphql({
    query: searchInternships,
    variables: {
      filter: {
        deleted: { eq: false }, start: {
          gte: startDate.toISOString(),
          lte: endDate.toISOString(),
        }
      },
      limit: 100,
      sort: { field: 'createdAt', direction: 'desc' },
      nextToken: token,
    },
  });
  const { items, nextToken } = response?.data?.searchInternships;

  let newArray = []
  for (const item of items) {
    item.city = item?.city?.name
    item.contactPerson = JSON.parse(item?.contactPerson)
    item.contactName = item?.contactPerson?.name
    item.contactPhone = item?.contactPerson?.phone
    delete item?.contactPerson
    item.startDate = moment(item?.start).format('DD/MM/YYYY')
    delete item?.start
    item.endDate = moment(item?.end).format('DD/MM/YYYY')
    delete item?.end
    item.createdAt = moment(item?.createdAt).format('DD/MM/YYYY')
    let applicants = await getInternshipApplicants(item?.id);
    delete item.id

    if (applicants.length === 0) {
      newArray = [...newArray, item]
    }
    else {

      for (const element of applicants) {

        let attendence = await getInternshipAttendee(element.internshipId, element.studentId)

        if (attendence.length > 0) {

          for (const attendeee of attendence) {
            newArray.push({
              ...item,
              studentName: (element?.student?.user?.firstName || "") + " " + (element?.student?.user?.lastName || ""),
              studentPhone: (element?.student?.user?.phone || ""),
              enrolledOn: moment(element?.createdAt).format('DD/MM/YYYY'),
              sessionDate: moment(attendeee?.createdAt).format('DD/MM/YYYY'),
              presentAt: moment(attendeee?.presentAt).format('DD/MM/YYYY hh:mm a'),
            })
          }

        } else {
          newArray.push(({
            ...item,
            studentName: (element?.student?.user?.firstName || "") + " " + (element?.student?.user?.lastName || ""),
            studentPhone: (element?.student?.user?.phone || ''),
            enrolledOn: moment(element?.createdAt).format('DD/MM/YYYY')
          }))
        }



      }
    }


  }
  return { data: newArray, nextToken };
}