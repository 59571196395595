import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import Spinner from '../../../components/Spinner'
import moment from 'moment';
import InternshipMenu from './Menu'

const searchInternshipAttendees = /* GraphQL */ `
  query SearchInternshipAttendees(
    $filter: SearchableInternshipAttendeeFilterInput
    $sort: [SearchableInternshipAttendeeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipAttendeeAggregationInput]
  ) {
    searchInternshipAttendees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        internshipId
        studentId
        presentAt
        createdAt
        updatedAt
        internship {
          id
          title
          start
          end
        }
        student {
          user {
            id
            firstName
            lastName
          }
        }
      }
      nextToken
      total
    }
  }
`;
const ApplicantDetails = () => {

    const { id, studentId } = useParams()
    const [internshipData, setInternshipData] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };



    const getApplicantDetails = async () => {
        try {
            let response = await API.graphql({
                query: searchInternshipAttendees,
                variables: {
                    studentId: studentId,
                    filter: { and: { internshipId: { eq: `${id}` }, studentId: { eq: studentId } } }
                }
            })

            let result = createDateList(response?.data?.searchInternshipAttendees?.items[0]?.internship?.start)
            result.forEach((item) => {
                response?.data?.searchInternshipAttendees?.items?.forEach((res) => {
                    if (item.today === new Date(res?.presentAt)?.toLocaleDateString('en-GB')) {
                        item.present = true;
                        item.presentAt = res.presentAt
                    }
                })
            })
            setResultData(result)
            setInternshipData(response?.data?.searchInternshipAttendees?.items)
        } catch (error) {
            console.log(error)
        }
    }

    const createDateList = (start) => {
        var startDate = new Date(start);
        var currentDate = new Date();
        let dateArray = [];
        while (startDate <= currentDate) {
            var formattedDate = startDate.toLocaleDateString('en-GB');
            let dateObj = {
                today: formattedDate,
                present: false,
                presentAt: null
            }
            dateArray.push(dateObj);
            startDate.setDate(startDate.getDate() + 1);  // Move to the next day
        }
        return dateArray
    }

    useEffect(() => {
        getApplicantDetails()
    }, [])

    return (
        <div>
            <Header title={internshipData[0]?.internship?.title} name={`${internshipData[0]?.student?.user?.firstName} ${internshipData[0]?.student?.user?.lastName}`}></Header>
            <div className="container-fluid">
                <InternshipMenu id={id} />
                <hr />
            </div>

            <Spinner display={spinner}>
                <div className='container-fluid pb-3'>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table table-sm table-hover card-table">
                                <thead >
                                    <tr>
                                        <th>Date</th>
                                        <th>Check In Date & Time</th>
                                    </tr>
                                </thead>

                                <tbody className="list fs-base ">
                                    {
                                        resultData && resultData.map((item) => {
                                            return (
                                                <tr key={item?.today} >
                                                    <td>
                                                        {moment((item?.today), "DD/MM/YYYY").format('DD MMMM, YYYY')}
                                                    </td>
                                                    <td className=''>
                                                        {
                                                            item?.presentAt ? <>
                                                                {moment(item?.presentAt).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(item?.presentAt).format('h:mm a')}</div>
                                                            </> : "No Show"
                                                        }
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </Spinner >
        </div>
    )
}

export default ApplicantDetails
