import { API } from "aws-amplify";
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Spinner from '../../../components/Spinner';
import CourseMenu from "../courses/Menu";
import DatePicker from "react-datepicker";
import { getAllSessionsByCourse } from "../../../excelHealper/courseSessions";
import { UserContext } from '../../../contexts/userContext';
import { isAdmin, isOwner } from "../../../utils";

const searchSessions = /* GraphQL */ `
  query SearchSessions(
    $filter: SearchableSessionFilterInput
    $sort: [SearchableSessionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionAggregationInput]
  ) {
    searchSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        courseId
        hostId
        name
        duration
        deleted
        start
        end
        location
        createdAt
        updatedAt
        host{
            firstName
            lastName
        }
      }
      nextToken
      total
    }
  }
`;

const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      hostId
      name
      start
      end
      deleted
      media
      hosts
    }
  }
`;

const CourseSession = () => {
    const { id } = useParams();
    const { user } = useContext(UserContext);
    const [spinner, showSpinner] = useState(false);
    const [course, setCourse] = useState();
    const [sessions, setSessions] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [sortItem, setSortItem] = useState({ field: 'createdAt', direction: 'desc' })
    const [startDate, setStartDate] = useState(new Date("2023/04/01"));
    const [endDate, setEndDate] = useState(new Date("2023/04/01"));
    const [downloadStatus, setDownloadStatus] = useState(false)

    const limit = 100;

    const navigate = useNavigate();

    const getCourseById = async () => {
        try {
            // Get Course
            let response = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            // response.data.getCourse.location = JSON.parse(response.data?.getCourse?.location)
            // response.data.getCourse.assignment = JSON.parse(response.data?.getCourse?.assignment)
            setCourse(response?.data?.getCourse)
        } catch (error) {
            console.log(error)
        }
    }

    const getSessionsByCourseId = async (from) => {
        try {
            showSpinner(true)

            const isAdminOrOwner = isAdmin(user?.role) || isOwner(user?.role);
            const isUserHost = course?.hostId === user?.id;
            // Get Course Sessions
            let filter = { and: { deleted: { eq: false }, courseId: { eq: id } } }

            if (!isAdminOrOwner && !isUserHost) {
                filter.and.hostId = { eq: user?.id };
            }

            if (search && isAdminOrOwner) {
                filter.and.name = { matchPhrasePrefix: search };
            } else if (search && !isUserHost) {
                filter.and.hostId = { eq: user?.id };
                filter.and.name = { matchPhrasePrefix: search };
            }

            let response = await API.graphql({
                query: searchSessions,
                variables: {
                    courseId: id,
                    filter,
                    sort: sortItem,
                    from,
                    limit
                }
            })
            setTotalCount(response?.data?.searchSessions?.total)
            setSessions(response?.data?.searchSessions?.items)

            let pages = [];
            for (let i = 1; i <= Math.ceil(response?.data?.searchSessions?.total / limit); i++) {
                pages.push(i)
            }

            setTotalPages(pages)
            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    const hanldeSort = (fieldName) => {
        setSortItem((prev) => {
            if (prev?.field === fieldName) {
                if (prev.direction === 'asc') {
                    return ({ field: fieldName, direction: 'desc' })
                } else {
                    return ({ field: fieldName, direction: 'asc' })
                }
            } else {
                return ({ field: fieldName, direction: 'desc' })
            }
        })
    }

    const handlePage = (payload) => {
        if (payload === -1 && currentPage >= 2) {
            getSessionsByCourseId((currentPage - 2) * limit)
            setCurrentPage((prev) => prev - 1)
        } else if (payload === 1 && currentPage < totalPages[totalPages.length - 1]) {
            getSessionsByCourseId(currentPage * limit)
            setCurrentPage((prev) => prev + 1)
        }
    }

    const handleDownload = async (event) => {
        event.preventDefault()
        setDownloadStatus(true)
        await getAllSessionsByCourse(course.id, startDate, endDate)
        setDownloadStatus(false)
    }



    useEffect(() => {
        getCourseById();
    }, [])

    useEffect(() => {
        if (user?.id && course?.hostId) {
            getSessionsByCourseId(0)
        }
    }, [search, sortItem, user?.id, course?.hostId])

    return (
        <div>
            <Header title={course?.name} name='Sessions'>
                {(isOwner(user?.role) || isAdmin(user?.role) || (course?.hostId && user?.id === course?.hostId)) &&
                    <Link to={`/youth-venture/course/${id}/session/create`} className='btn btn-primary'>New Session</Link>
                }
            </Header>

            <div className="container-fluid">
                <CourseMenu id={id} />
                <hr />
            </div>


            <div className='container-fluid'>
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <form>
                                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                        <input className="form-control list-search" type="search" placeholder="Search" defaultValue={search} onChange={(e) => { setSearch(e?.target?.value) }} />
                                        <span className="input-group-text">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div className="col-auto">
                                <div className="dropdown">
                                    <button className="btn btn-sm btn-white " type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" >
                                        Download <i className="fe fe-download m-2"></i>
                                    </button>
                                    <form className="dropdown-menu dropdown-menu-end dropdown-menu-card " data-popper-placement="bottom-end" style={{ position: "absolute", inset: " 0px 0px auto auto", margin: "0px", transform: " translate(0px, 32px)" }}>
                                        <div className="card-header">
                                            <h4 className="card-header-title">
                                                Download Excel file
                                            </h4>
                                        </div>
                                        <div className="card-body" style={{ overflowY: 'initial' }}>
                                            <div className="list-group list-group-flush mt-n4 mb-4">
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>Start Date</small>

                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                selectsStart
                                                                startDate={startDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                endDate={endDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>End Date</small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => { setEndDate(new Date(date.setHours(23, 59, 0, 0))) }}
                                                                selectsEnd
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                minDate={startDate}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn w-100 btn-primary" disabled={downloadStatus} onClick={handleDownload}>
                                                {downloadStatus ? "Downloading..." : "Download"}
                                            </button>

                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-hover card-table">
                            <thead >
                                <tr>
                                    <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('name') }} data-sort="item-name" >Session</span></th>
                                    <th>Professor</th>
                                    <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('duration') }} data-sort="item-name" >Duration</span></th>
                                    <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('start') }} data-sort="item-name" >Start</span></th>
                                    <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('end') }} data-sort="item-name" >End</span></th>
                                    <th className='text-center'>Location</th>
                                    <th ></th>
                                </tr>
                            </thead>
                            <Spinner display={spinner}>
                                <tbody className="list fs-base ">
                                    {
                                        (sessions.length > 0) ? sessions.map((session) => {
                                            return (
                                                <tr key={session.id} className="cursor-pointer" onClick={() => { navigate(`/youth-venture/course/${id}/session/${session.id}/dashboard`) }}>
                                                    <td>
                                                        {session?.name}
                                                    </td>
                                                    <td>
                                                        {`${session?.host?.firstName} ${session?.host?.lastName}`}
                                                    </td>
                                                    <td className='text-center'>
                                                        {session?.duration}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            session.start && <>
                                                                {moment(session.start).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(session.start).format('h:mm a')}</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            session.end && <>
                                                                {moment(session.end).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(session.end).format('h:mm a')}</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-center text-wrap'>
                                                        {
                                                            JSON.parse(session?.location)?.name
                                                        }
                                                    </td>
                                                    <td className='text-center text-muted' onClick={(event) => { event.stopPropagation() }}>
                                                        {session && new Date(session?.end) > new Date() && ((user?.id === course?.hostId) || (user?.id === session?.hostId) || isOwner(user?.role) || isAdmin(user?.role)) &&
                                                            <div className='dropdown'>
                                                                <div className='dropdown-ellipses dropdown-toggle text-mute' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                                    <i className='fe fe-more-vertical'></i>
                                                                </div>
                                                                <div className='dropdown-menu dropdown-menu-end'>
                                                                    <Link to={`/youth-venture/course/${id}/session/${session.id}/editor`} className='dropdown-item' >
                                                                        Edit
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className=' text-center text-muted'>
                                                <td colSpan={'5'}>
                                                    <h1 className='m-0'>
                                                        No data found
                                                    </h1>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Spinner >
                        </table>

                    </div>
                    <div className="card-footer d-flex justify-content-between">
                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer">
                                {currentPage > 1 ?
                                    <button className="page-link ps-0 pe-4 border-end" onClick={() => { handlePage(-1) }}>
                                        <i className="fe fe-arrow-left me-1"></i> Prev
                                    </button> : ""}

                            </li>
                        </ul>
                        <ul className="list-pagination pagination pagination-tabs card-pagination ">

                            {totalPages.map((page) => (
                                <li className={`cursor-pointer ${(currentPage === page) ? 'active' : ''}`} onClick={() => { getSessionsByCourseId((page - 1) * limit); setCurrentPage(page) }} key={page}>
                                    <span className='page'>{page}</span>
                                </li>))}
                        </ul>
                        <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                            <li className="page-item cursor-pointer">
                                {currentPage < totalPages[totalPages.length - 1] ?
                                    <button className="page-link ps-4 pe-0 border-start" onClick={() => { handlePage(1) }}>
                                        Next <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                    : ""}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default CourseSession;