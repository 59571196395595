import React, { useEffect } from 'react';

const Header = ({ title, name, children, container, count }) => {
    useEffect(() => {
        document.title = `${name} - Going to School`;
    }, [name])

    return (
        <div className='pt-5 pb-4'>
            <div className={container || 'container-fluid'}>
                <div className='header-body'>
                    <div className='row align-items-end'>
                        <div className='col'>
                            <h6 className='header-pretitle'>{title}</h6>
                            <h1 className='header-title'>{name}
                                {count ? <span className='text-muted fs-2 m-2'>{`(${count})`}</span> : ""}
                            </h1>
                        </div>
                        <div className='col-auto'>
                            {
                                children && <>{children}</>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;