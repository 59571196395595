import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
// import { searchUsers } from "../../../graphql/queries";

const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        role
        firstName
        lastName
        birthday
        phone
        email
        address
      }
      nextToken
      total
    }
  }
`;

const ProfessorList = (pretoken, prev) => {
    const [spinner, showSpinner] = useState(false);
    const [items, setItems] = useState()
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState('');
    const [sortItem, setSortItem] = useState({ field: 'createdAt', direction: 'desc' })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);

    let limit = 100;

    const getList = async (from) => {
        try {
            showSpinner(true)
            let filter = { and: { role: { eq: 'TEACHER' }, deleted: { eq: false } } }
            if (search) {
                filter = { and: { role: { eq: 'TEACHER' }, deleted: { eq: false }, firstName: { matchPhrasePrefix: search }, } }
            }
            const response = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: filter,
                    limit,
                    sort: sortItem,
                    from
                }
            })
            setTotalCount(response?.data?.searchUsers?.total)

            let pages = [];
            for (let i = 1; i <= Math.ceil(response?.data?.searchUsers?.total / limit); i++) {
                pages.push(i)
            }
            setTotalPages(pages)
            setItems(response?.data?.searchUsers?.items)
            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    // const onNext = () => {
    //     if (token[token.length - 1] !== null) {
    //         getList(token[token.length - 1], true)
    //     }
    // }

    // const onPrev = () => {
    //     if (token.length <= 2) {
    //         getList(null, false)
    //         setToken(prev => prev.slice(0, -1))
    //     } else {
    //         getList(token[token.length - 2], false)
    //         setToken(token.slice(0, -1))
    //     }
    // }

    const handlePage = (payload) => {
        if (payload === -1 && currentPage >= 2) {
            getList((currentPage - 2) * limit)
            setCurrentPage((prev) => prev - 1)
        } else if (payload === 1 && currentPage < totalPages[totalPages.length - 1]) {
            getList(currentPage * limit)
            setCurrentPage((prev) => prev + 1)
        }
    }

    const hanldeSort = (fieldName) => {
        setSortItem((prev) => {
            if (prev?.field === fieldName) {
                if (prev.direction === 'asc') {
                    return ({ field: fieldName, direction: 'desc' })
                } else {
                    return ({ field: fieldName, direction: 'asc' })
                }
            } else {
                return ({ field: fieldName, direction: 'desc' })
            }
        })
    }

    useEffect(() => { getList(0) }, [search, sortItem])

    return (
        <div>
            <Header title='Youth Venture' name='Professors' count={totalCount}>
                <Link to='/youth-venture/professor/create' className='btn btn-primary'>Invite Professor</Link>
            </Header>

            <div className='container-fluid pb-3'>
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <form>
                                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                        <input className="form-control list-search" type="search" placeholder="Search" defaultValue={search} onChange={(e) => { setSearch(e?.target?.value) }} />
                                        <span className="input-group-text">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <Spinner display={spinner}>
                        <div className="table-responsive">
                            <table className="table table-sm table-hover card-table">
                                <thead >
                                    <tr>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('firstName') }} data-sort="item-name" >Name</span></th>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('phone') }} data-sort="item-name" >Phone</span></th>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('email') }} data-sort="item-name" >Email</span></th>
                                        <th>Address</th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base ">
                                    {
                                        items && items?.map((item) => {
                                            return (
                                                <tr key={item?.id} >
                                                    <td className="w-25">
                                                        {item?.firstName} {item?.lastName}
                                                    </td>
                                                    <td className="w-25">
                                                        {item?.phone}
                                                    </td>
                                                    <td className="w-25">
                                                        {item?.email}
                                                    </td>
                                                    <td className="text-wrap">
                                                        {item?.address ? JSON.parse(item?.address)?.address : "No Data"}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Spinner>
                    <div className="card-footer d-flex justify-content-between">
                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer">
                                {currentPage > 1 ?
                                    <button className="page-link ps-0 pe-4 border-end" onClick={() => { handlePage(-1) }}>
                                        <i className="fe fe-arrow-left me-1"></i> Prev
                                    </button> : ""}

                            </li>
                        </ul>
                        <ul className="list-pagination pagination pagination-tabs card-pagination ">

                            {totalPages.map((page) => (
                                <li className={`cursor-pointer ${(currentPage === page) ? 'active' : ''}`} onClick={() => { getList((page - 1) * limit); setCurrentPage(page) }} key={page}>
                                    <span className='page'>{page}</span>
                                </li>))}
                        </ul>
                        <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                            <li className="page-item cursor-pointer">
                                {currentPage < totalPages[totalPages.length - 1] ?
                                    <button className="page-link ps-4 pe-0 border-start" onClick={() => { handlePage(1) }}>
                                        Next <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                    : ""}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default ProfessorList;