import { NavLink } from "react-router-dom";

const InternshipMenu = ({ id }) => {
    return (
        <ul className="nav nav-tabs nav-overflow header-tabs">
            <li className="nav-item">
                <NavLink to={`/youth-venture/internship/${id}/dashboard`} className="nav-link">
                    Dashboard
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to={`/youth-venture/internship/${id}/applicants`} className="nav-link">
                    Applicants
                </NavLink>
            </li>
        </ul>
    )
}

export default InternshipMenu;