import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Spinner from "../../../components/Spinner";
import { getCourse } from "../../../graphql/queries";
import CourseMenu from "../courses/Menu";
import { saveAs } from 'file-saver';
import { updateCourseStudent } from "../../../graphql/mutations";
import { toast } from 'react-toastify';
import moment from "moment";

export const getCourseStudent = /* GraphQL */ `
  query GetCourseStudent($id: ID!) {
    getCourseStudent(id: $id) {
      id
      courseId
      course {
        id
        name
      }
      studentId
      student {
        id
        schoolId
        user {
            id
            firstName
            lastName
        }
        grade
      }
      course {
        sessions {
            items {
                name
                id
                start
                deleted
            }
        }
        }
        student {
            sessions {
                items {
                presentAt
                present
                id
                sessionId
                }
            }
        }
      assignment
      studentRating
      studentFeedback
      studentFeedbackMedia
      hostRating
      hostFeedback
      hostFeedbackMedia
      approved
    }
  }
`;

const StudentDashboard = () => {
    const { id, courseStudentId } = useParams();
    const navigate = useNavigate();

    const [spinner, showSpinner] = useState(false);
    const [course, setCourse] = useState();
    const [courseStudent, setCourseStudent] = useState([]);
    const [studentSubmittedAssign, setStudentSubmittedAssign] = useState([])

    const [starsHighlights, setStarsHighlights] = useState([])
    const takeStars = 5;

    const getData = async () => {
        try {
            showSpinner(true)
            // Get Course
            let responseCourse = await API.graphql({
                query: getCourse,
                variables: { id: id }
            })
            responseCourse.data.getCourse.location = JSON.parse(responseCourse.data?.getCourse?.location)
            responseCourse.data.getCourse.assignment = JSON.parse(responseCourse.data?.getCourse?.assignment)
            setCourse(responseCourse.data.getCourse)



            let response = await API.graphql({
                query: getCourseStudent,
                variables: { id: courseStudentId }
            })
            response.data.getCourseStudent.assignment = JSON.parse(response.data?.getCourseStudent?.assignment);
            let courseSessions = response?.data?.getCourseStudent?.course?.sessions?.items.sort((a, b) => (new Date(a?.start) - new Date(b?.start)))
                .filter((session) => (!session.deleted && new Date(session?.start).getTime() < new Date().getTime()));



            let studentSessions = response?.data?.getCourseStudent?.student?.sessions?.items;



            courseSessions?.forEach((session) => {
                studentSessions?.forEach((studentSession) => {
                    if (session?.id === studentSession?.sessionId) {
                        session.studentDetails = studentSession
                    }
                })

            })
            response.data.getCourseStudent.courseSessions = courseSessions
            setCourseStudent(response?.data?.getCourseStudent)


            // new
            let StudentCourseData = []
            let submittedAssignment = response?.data?.getCourseStudent?.assignment || []
            let createdAssignment = responseCourse?.data?.getCourse?.assignment

            // console.log('createdAssignment', createdAssignment)
            // console.log('submittedAssignment', submittedAssignment)

            let merged = [];

            for (let i = 0; i < createdAssignment?.length; i++) {
                merged.push({
                    ...createdAssignment[i],
                    ...(submittedAssignment.find((itmInner) => itmInner?.id === createdAssignment[i]?.id))
                }
                );
            }
            setStudentSubmittedAssign(merged)

            showSpinner(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => { getData(); startData() }, [])


    const startData = () => {
        let data = []
        for (let i = 0; i < takeStars; i++) {
            data.push(false)
        }
        setStarsHighlights(data)
    }

    const handleStarClick = (index) => {
        let data = []
        for (let i = 0; i < takeStars; i++) {
            if (i <= index) data[i] = true
            else data[i] = false
        }
        setStarsHighlights(data)
    }

    const handleDownloadMedia = (link) => {
        saveAs(link, `${link.split('/public/')[1]}`)
    }

    const handleOnChange = (id, value) => {
        let data = courseStudent.assignment.map((item) => {
            if (item.id === id) {
                return ({ ...item, response: value })
            } else {
                return item
            }
        })

        // setCourseStudent(prev => ({ ...prev, assignment: data }))
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        let rating = 0;
        starsHighlights.forEach((star) => {
            if (star === true) {
                rating = rating + 1;
            }
        })
        if (!rating) {
            rating = null
        }
        handleUpdateCourseStudent(rating)
    }

    const handleUpdateCourseStudent = async (rating) => {
        try {
            await API.graphql({
                query: updateCourseStudent,
                variables: {
                    input: {
                        id: courseStudentId,
                        studentRating: rating,
                        studentFeedback: courseStudent?.studentFeedback
                    }
                }
            })
            toast.success('Assignment Updated');
            navigate(-1);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        courseStudent?.studentRating &&
            handleStarClick(courseStudent?.studentRating - 1)
    }, [courseStudent?.studentRating])

    return (
        <div>
            <Header title={course?.name} name={`${courseStudent?.student?.user?.firstName} ${courseStudent?.student?.user?.lastName}`}></Header>

            <div className="container-fluid">
                <CourseMenu id={id} />
                <hr />
            </div>
            <Spinner display={spinner}>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-8">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-header-title">Assignment</h4>
                                </div>
                                {studentSubmittedAssign &&
                                    <div className="card-body">
                                        <div className="list-group list-group-flush my-n3">
                                            {/* {
                                                courseStudent?.assignment && courseStudent?.assignment?.map((item) => {
                                                    return (
                                                        <div className="list-group-item" key={item?.name}>
                                                            <div>{item.name}</div>
                                                            <div className="text-muted">{item?.description}</div>
                                                            <textarea className="form-control mt-2" disabled={true} value={item?.response} ></textarea>
                                                            {typeof item?.attachment !== 'undefined' &&
                                                                <div className="d-flex">
                                                                    {item?.attachment?.map((link, index) => (
                                                                        <button className=" btn btn-sm btn-primary m-2" onClick={() => { handleDownloadMedia(link) }}>Attachment {index + 1}</button>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            } */}
                                            {
                                                studentSubmittedAssign && studentSubmittedAssign?.map((item) => {
                                                    return (
                                                        <div className="list-group-item" key={item?.id}>
                                                            <div>{item.name}</div>
                                                            <div className="text-muted">{item?.description}</div>
                                                            <textarea className="form-control mt-2" disabled={true} value={item?.response || ""} ></textarea>
                                                            {typeof item?.attachment !== 'undefined' &&
                                                                <div className="d-flex">
                                                                    {item?.attachment?.map((link, index) => (
                                                                        <button key={index} className=" btn btn-sm btn-primary m-2" onClick={() => { handleDownloadMedia(link) }}>Attachment {index + 1}</button>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                                <div className="card-footer">
                                    <div className="my-2">
                                        <strong>Student Feedback</strong>
                                        <div className="text-muted mt-2">
                                            {courseStudent?.hostRating !== 0 || !null && <div>
                                                {[...new Array(courseStudent?.hostRating)]?.map((start) => (<span className={`fa fa-star checked`}></span>))}
                                                {[...new Array(5 - courseStudent?.hostRating)]?.map((start) => (<span className={`fa fa-star stroke`}></span>))}
                                            </div>}

                                        </div>
                                        <textarea className="form-control mt-2" value={courseStudent?.hostFeedback || ""} disabled={true}></textarea>
                                    </div>
                                    <div className="my-5">
                                        <strong>Professor Feedback</strong>
                                        <div className="text-muted mt-2">
                                            {starsHighlights && starsHighlights.length > 0 && starsHighlights.map((elem, index) => {
                                                // return <i className="fe fe-star fe-solid me-2" style={{ color: `${elem}` }} onClick={() => handleStarClick(index)}></i>
                                                return <span key={index} className={`fa fa-star ${elem ? 'checked' : 'stroke'}`} onClick={() => handleStarClick(index)}></span>
                                            })}

                                        </div>
                                        <textarea className="form-control mt-2" onChange={(e) => { setCourseStudent((prev) => ({ ...prev, studentFeedback: e.target.value })) }} value={courseStudent?.studentFeedback || ""}>

                                        </textarea>
                                        <button className="btn btn-primary mt-2" onClick={(event) => { handleSubmit(event) }}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            {courseStudent?.courseSessions && courseStudent?.courseSessions?.map((session) => {
                                return (
                                    <div className="card" key={session?.id}>
                                        <div className="card-header">
                                            <h4 className="card-header-title">{session?.name}</h4>
                                        </div>
                                        <div className="card-body">

                                            {session?.studentDetails?.presentAt ? (<> <span className="text-muted me-2"> Checked In:</span>{moment(session?.studentDetails?.presentAt).format('MMMM DD, YYYY h:mm a')}</>) : 'No Show'}

                                        </div>
                                    </div>
                                )
                            })}

                            {/* <div className="card mt-3">
                                <div className="card-header">
                                    <h4 className="card-header-title">Coming Soon</h4>
                                </div>
                                <div className="card-body">
                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="card-header">
                                    <h4 className="card-header-title">Coming Soon</h4>
                                </div>
                                <div className="card-body">
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Spinner >
        </div >
    )
}

export default StudentDashboard