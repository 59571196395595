import React from 'react';

import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image';

const InfoCard = ({ icon, title, children }) => {
    return (
        <Card>
            <Card.Body>
                <div className='d-flex align-items-center' >
                    {icon != null && <div>
                        <Image src={icon} alt='...' height="40" width="40" />
                    </div>}
                    <div className="ml-3 text-nowrap" >
                        <h6 className='text-uppercase text-muted mb-2'>{title}</h6>
                        <span className='h2 mb-0'>{children}</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default InfoCard;