import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import { UserContext } from '../../../contexts/userContext';
import { isAdmin, isOwner, isTeacher } from '../../../utils';
import { getAllCourses } from '../../../excelHealper/courses';
import DatePicker from "react-datepicker";

const courseList = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostId
        host {
          id
          firstName
          lastName
        }
        name
        description
        duration
        start
        end
        location
        active
      }
      nextToken
    }
  }
`;

const CoursesByHostId = /* GraphQL */ `
   query CoursesByHostId(
    $hostId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByHostId(
      hostId: $hostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostId
        host {
          id
          firstName
          lastName
        }
        deleted
        name
        description
        duration
        start
        end
        location
        active
      }
      nextToken
    }
  }
`;

const searchCourses = /* GraphQL */ `
  query SearchCourses(
    $filter: SearchableCourseFilterInput
    $sort: [SearchableCourseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCourseAggregationInput]
  ) {
    searchCourses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        hostId
         host {
          id
          firstName
          lastName
        }
        name
        description
        duration
        start
        end
        deleted
        active
        rating
        createdAt
        updatedAt
        courseCityId
      }
      nextToken
      total
    }
  }
`;

const CourseList = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [spinner, showSpinner] = useState(false);
    const [items, setItems] = useState()
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState('');
    const [sortItem, setSortItem] = useState({ field: 'createdAt', direction: 'desc' })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [startDate, setStartDate] = useState(new Date("2023/04/01"));
    const [endDate, setEndDate] = useState(new Date("2023/04/01"));
    const [downloadStatus, setDownloadStatus] = useState(false)

    const limit = 100;

    const getList = async (from) => {
        try {
            showSpinner(true)
            let filter = { deleted: { eq: false } }

            if (search) {
                filter = {
                    and: {
                        deleted: { eq: false },
                        name: { matchPhrasePrefix: search }
                    }
                }
            }
            if (isTeacher(user.role)) {
                filter = { and: { deleted: { eq: false }, hosts: { eq: user?.id } } }
                if (search) {
                    filter = { and: { deleted: { eq: false }, hosts: { eq: user?.id }, name: { matchPhrasePrefix: search } } }
                }

                const response = await API.graphql({
                    query: searchCourses,
                    variables: {
                        filter,
                        sort: sortItem,
                        from,
                        limit
                    }
                })
                setTotalCount(response?.data?.searchCourses?.total)

                let pages = [];
                for (let i = 1; i <= Math.ceil(response?.data?.searchCourses?.total / limit); i++) {
                    pages.push(i)
                }
                setTotalPages(pages)
                setItems(response?.data?.searchCourses?.items)
                showSpinner(false)
            } else if (isAdmin(user.role) || isOwner(user.role)) {
                const response = await API.graphql({
                    query: searchCourses,
                    variables: {
                        filter,
                        sort: sortItem,
                        from,
                        limit
                    }
                })
                setTotalCount(response?.data?.searchCourses?.total)

                let pages = [];
                for (let i = 1; i <= Math.ceil(response?.data?.searchCourses?.total / limit); i++) {
                    pages.push(i)
                }
                setTotalPages(pages)
                setItems(response?.data?.searchCourses?.items)
                showSpinner(false)
            }



        } catch (error) {
            console.log(error)
        }
    }



    const handlePage = (payload) => {
        if (payload === -1 && currentPage >= 2) {
            getList((currentPage - 2) * limit)
            setCurrentPage((prev) => prev - 1)
        } else if (payload === 1 && currentPage < totalPages[totalPages.length - 1]) {
            getList(currentPage * limit)
            setCurrentPage((prev) => prev + 1)
        }
    }

    const hanldeSort = (fieldName) => {
        setSortItem((prev) => {
            if (prev?.field === fieldName) {
                if (prev.direction === 'asc') {
                    return ({ field: fieldName, direction: 'desc' })
                } else {
                    return ({ field: fieldName, direction: 'asc' })
                }
            } else {
                return ({ field: fieldName, direction: 'desc' })
            }
        })
    }

    const handleDownloadCourses = async (event) => {
        event.preventDefault()
        setDownloadStatus(true)
        await getAllCourses(startDate, endDate)
        setDownloadStatus(false)
    }
    useEffect(() => {
        showSpinner(true)
        setTimeout(() => {
            getList(0)
            showSpinner(false)
        }, 2000)
    }, [])
    useEffect(() => { getList(0); }, [user.role, search, sortItem])

    return (
        <div>
            <Header title='Youth Venture' name='Courses' count={totalCount}>
                <Link to='/youth-venture/course/create' className='btn btn-primary'>New Bootcamp</Link>
            </Header>


            <div className='container-fluid pb-3'>
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <form>
                                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                        <input className="form-control list-search" type="search" placeholder="Search" defaultValue={search} onChange={(e) => { setSearch(e?.target?.value) }} />
                                        <span className="input-group-text">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div className="col-auto">
                                {/* <button className="btn btn-sm btn-white" onClick={handleDownloadCourses}>
                                    Download
                                    <i className="fe fe-download m-2"></i>
                                </button> */}
                                <div className="dropdown">
                                    <button className="btn btn-sm btn-white " type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" >
                                        Download <i className="fe fe-download m-2"></i>
                                    </button>
                                    <form className="dropdown-menu dropdown-menu-end dropdown-menu-card " data-popper-placement="bottom-end" style={{ position: "absolute", inset: " 0px 0px auto auto", margin: "0px", transform: " translate(0px, 32px)" }}>
                                        <div className="card-header">
                                            <h4 className="card-header-title">
                                                Download Excel file
                                            </h4>
                                        </div>
                                        <div className="card-body" style={{ overflowY: 'initial' }}>
                                            <div className="list-group list-group-flush mt-n4 mb-4">
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>Start Date</small>

                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                selectsStart
                                                                startDate={startDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                endDate={endDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>End Date</small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => { setEndDate(new Date(date?.setHours(23, 59, 0, 0))) }}
                                                                selectsEnd
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                minDate={startDate}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn w-100 btn-primary" disabled={downloadStatus} onClick={handleDownloadCourses}>
                                                {downloadStatus ? "Downloading..." : "Download"}
                                            </button>

                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">

                        <table className="table table-sm table-hover card-table">
                            <thead >
                                <tr>
                                    <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('name') }} data-sort="item-name" >Course Name</span ></th>
                                    <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('hostId') }} data-sort="item-name" >Professor</span></th>
                                    <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('duration') }} data-sort="item-name" >Duration</span></th>
                                    <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('start') }} data-sort="item-name" >Start Date</span></th>
                                    <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('end') }} data-sort="item-name" >End Date</span></th>
                                </tr>
                            </thead>
                            <Spinner display={spinner}>
                                <tbody className="list fs-base ">
                                    {
                                        items && items?.map((course) => {
                                            return (
                                                <tr key={course?.id} onClick={() => { navigate(`/youth-venture/course/${course.id}/dashboard`) }} className='cursor-pointer'>
                                                    <td>
                                                        {course?.name}
                                                    </td>
                                                    <td>
                                                        {course?.host?.firstName} {course?.host?.lastName}
                                                    </td>
                                                    <td className='text-center'>
                                                        {course?.duration}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            course?.start && <>
                                                                {moment(course?.start).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(course?.start).format('h:mm a')}</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            course?.end && <>
                                                                {moment(course?.end).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(course?.end).format('h:mm a')}</div>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Spinner>
                        </table>
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer">
                                {currentPage > 1 ?
                                    <button className="page-link ps-0 pe-4 border-end" onClick={() => { handlePage(-1) }}>
                                        <i className="fe fe-arrow-left me-1"></i> Prev
                                    </button> : ""}

                            </li>
                        </ul>
                        <ul className="list-pagination pagination pagination-tabs card-pagination ">

                            {totalPages.map((page) => (
                                <li className={`cursor-pointer ${(currentPage === page) ? 'active' : ''}`} onClick={() => { getList((page - 1) * limit); setCurrentPage(page) }} key={page}>
                                    <span className='page'>{page}</span>
                                </li>))}
                        </ul>
                        <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                            <li className="page-item cursor-pointer">
                                {currentPage < totalPages[totalPages.length - 1] ?
                                    <button className="page-link ps-4 pe-0 border-start" onClick={() => { handlePage(1) }}>
                                        Next <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                    : ""}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CourseList;