import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import Spinner from '../../../components/Spinner';
import { searchInternships } from '../../../graphql/queries';
import { downloadInternships } from '../../../excelHealper/internships';
import DatePicker from "react-datepicker";


const InternshipList = () => {
    const [internships, setInternships] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [token, setToken] = useState([]);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [sortItem, setSortItem] = useState({ field: 'createdAt', direction: 'desc' })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [startDate, setStartDate] = useState(new Date("2023/04/01"));
    const [endDate, setEndDate] = useState(new Date("2023/04/01"));
    const [downloadStatus, setDownloadStatus] = useState(false)

    const navigate = useNavigate();

    const limit = 100;

    const getListInterships = async (pretoken, prev) => {
        setSpinner(true);
        let filter = {
            deleted: { eq: false },
        }

        if (search) {
            filter = {
                deleted: { eq: false },
                title: { matchPhrasePrefix: search },
            }
        }
        try {
            let response = await API.graphql({
                query: searchInternships,
                variables: {
                    filter: filter,
                    limit,
                    sort: sortItem,
                }
            })
            setTotalCount(response?.data?.searchInternships?.total)

            let pages = [];
            for (let i = 1; i <= Math.ceil(response?.data?.searchInternships?.total / limit); i++) {
                pages.push(i)
            }
            setTotalPages(pages)

            response?.data?.searchInternships?.items?.forEach((internship) => (internship.contactPerson = JSON.parse(internship.contactPerson)))
            setInternships(response?.data?.searchInternships?.items)

            setSpinner(false)

        } catch (error) {
            console.log(error);
        }
    }

    const onNext = () => {
        if (token[token.length - 1] !== null) {
            getListInterships(token[token.length - 1], true)
        }
    }

    const onPrev = () => {
        if (token.length <= 2) {
            getListInterships(null, false)
            setToken(prev => prev.slice(0, -1))
        } else if (token[token.length - 1] === null) {
            getListInterships(token[token.length - 4], false)
            setToken(token.slice(0, -3))
        }
        else {
            getListInterships(token[token.length - 2], false)
            setToken(token.slice(0, -1))
        }
    }

    const handlePage = (payload) => {
        if (payload === -1 && currentPage >= 2) {
            getListInterships((currentPage - 2) * limit)
            setCurrentPage((prev) => prev - 1)
        } else if (payload === 1 && currentPage < totalPages[totalPages.length - 1]) {
            getListInterships(currentPage * limit)
            setCurrentPage((prev) => prev + 1)
        }
    }

    const hanldeSort = (fieldName) => {
        setSortItem((prev) => {
            if (prev?.field === fieldName) {
                if (prev.direction === 'asc') {
                    return ({ field: fieldName, direction: 'desc' })
                } else {
                    return ({ field: fieldName, direction: 'asc' })
                }
            } else {
                return ({ field: fieldName, direction: 'desc' })
            }
        })
    }

    const handleDownloadInternships = async (event) => {
        event.preventDefault()
        setDownloadStatus(true)
        await downloadInternships(startDate, endDate)
        setDownloadStatus(false)
    }

    useEffect(() => {
        getListInterships(0)
    }, [search, sortItem])


    return (
        <div>
            <Header title='Youth Venture' name='Internships' count={totalCount}>
                <Link to='/youth-venture/internship/create' className='btn btn-primary'>New Internship</Link>
            </Header>
            <div className='container-fluid pb-3'>
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <form>
                                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                        <input className="form-control list-search" type="search" placeholder="Search" defaultValue={search} onChange={(e) => { setSearch(e?.target?.value) }} />
                                        <span className="input-group-text">
                                            <i className="fe fe-search"></i>
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div className="col-auto">
                                {/* <button className="btn btn-sm btn-white" onClick={handleDownloadInternships}>
                                    Download
                                    <i className="fe fe-download m-2"></i>
                                </button> */}
                                <div className="dropdown">
                                    <button className="btn btn-sm btn-white " type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside"  >
                                        Download <i className="fe fe-download m-2"></i>
                                    </button>
                                    <form className={`dropdown-menu dropdown-menu-end dropdown-menu-card`} data-popper-placement="bottom-end" style={{ position: "absolute", inset: " 0px 0px auto auto", margin: "0px", transform: " translate(0px, 32px)" }}>
                                        <div className="card-header">
                                            <h4 className="card-header-title">
                                                Download Excel file
                                            </h4>
                                        </div>
                                        <div className="card-body" style={{ overflowY: 'initial' }}>
                                            <div className="list-group list-group-flush mt-n4 mb-4">
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>Start Date</small>

                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                selectsStart
                                                                startDate={startDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                endDate={endDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small>End Date</small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => { setEndDate(new Date(date?.setHours(23, 59, 0, 0))) }}
                                                                selectsEnd
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                dateFormat="MMMM d, yyyy"
                                                                minDate={startDate}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn w-100 btn-primary" disabled={downloadStatus} onClick={handleDownloadInternships}>
                                                {downloadStatus ? "Downloading..." : "Download"}
                                            </button>

                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Spinner display={spinner}>
                            <table className="table table-sm table-hover card-table">
                                <thead >
                                    <tr>
                                        <th><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('title') }} data-sort="item-name" >Title</span></th>
                                        <th className='text-center'>Contact</th>
                                        <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('start') }} data-sort="item-name" >Start Date</span></th>
                                        <th className='text-center'><span className="list-sort text-muted asc cursor-pointer" onClick={() => { hanldeSort('end') }} data-sort="item-name" >End Date</span></th>
                                    </tr>
                                </thead>

                                <tbody className="list fs-base ">
                                    {
                                        internships && internships?.map((internship) => {
                                            return (
                                                <tr key={internship?.id} onClick={() => { navigate(`/youth-venture/internship/${internship.id}/dashboard`) }} className='cursor-pointer'>
                                                    <td>
                                                        {internship?.title}
                                                    </td>
                                                    <td className='text-center'>
                                                        {internship?.contactPerson?.name}
                                                        <div className='small text-muted'>{internship?.contactPerson?.phone}</div>
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            internship?.start && <>
                                                                {moment(internship?.start).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(internship?.start).format('h:mm a')}</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            internship?.end && <>
                                                                {moment(internship?.end).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(internship?.end).format('h:mm a')}</div>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Spinner >
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                            <li className="page-item cursor-pointer">
                                {currentPage > 1 ?
                                    <button className="page-link ps-0 pe-4 border-end" onClick={() => { handlePage(-1) }}>
                                        <i className="fe fe-arrow-left me-1"></i> Prev
                                    </button> : ""}

                            </li>
                        </ul>
                        <ul className="list-pagination pagination pagination-tabs card-pagination ">

                            {totalPages.map((page) => (
                                <li className={`cursor-pointer ${(currentPage === page) ? 'active' : ''}`} onClick={() => { getListInterships((page - 1) * limit); setCurrentPage(page) }} key={page}>
                                    <span className='page'>{page}</span>
                                </li>))}
                        </ul>
                        <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                            <li className="page-item cursor-pointer">
                                {currentPage < totalPages[totalPages.length - 1] ?
                                    <button className="page-link ps-4 pe-0 border-start" onClick={() => { handlePage(1) }}>
                                        Next <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                    : ""}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default InternshipList
