import { API } from 'aws-amplify';
import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getInternship } from '../../../graphql/queries';
import Header from "../../../components/Header";
import { UserContext } from "../../../contexts/userContext";
import { isOwner } from '../../../utils';
import InternshipMenu from './Menu';
import Spinner from '../../../components/Spinner';
import { deleteInternshipApplicant } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
import moment from 'moment';

const searchInternshipApplicants = /* GraphQL */ `
  query SearchInternshipApplicants(
    $filter: SearchableInternshipApplicantFilterInput
    $sort: [SearchableInternshipApplicantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipApplicantAggregationInput]
  ) {
    searchInternshipApplicants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        approved
        internshipId
        studentId
        createdAt
        updatedAt
         student {
          user {
            firstName
            lastName
            id
            phone
          }
        }
      }
      nextToken
      total
    }
  }
`;

const searchInternshipAttendees = /* GraphQL */ `
  query SearchInternshipAttendees(
    $filter: SearchableInternshipAttendeeFilterInput
    $sort: [SearchableInternshipAttendeeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInternshipAttendeeAggregationInput]
  ) {
    searchInternshipAttendees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
    }
  }
`;


const InternshipApplicants = () => {
    const navigate = useNavigate()
    const { user } = useContext(UserContext);
    const { id } = useParams();
    const [internship, setInternship] = useState();
    const [spinner, showSpinner] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);

    const limit = 100;

    const getInternshipById = async () => {
        showSpinner(true)
        try {
            let response = await API.graphql({
                query: getInternship,
                variables: { id }
            })
            response.data.getInternship.location = JSON.parse(response?.data?.getInternship.location)
            response.data.getInternship.contactPerson = JSON.parse(response?.data?.getInternship.contactPerson)
            setInternship(response?.data?.getInternship)
        } catch (error) {
            console.log(error)
            showSpinner(false)
        }
    }

    const getInternApplicants = async () => {
        showSpinner(true)
        try {
            let res = await API.graphql({
                query: searchInternshipApplicants,
                variables: {
                    filter: { internshipId: { eq: id } },
                    sort: { field: 'createdAt', direction: 'desc' }

                }
            })

            let pages = [];
            for (let i = 1; i <= Math.ceil(res?.data?.searchInternshipApplicants?.total / limit); i++) {
                pages.push(i)
            }
            setTotalPages(pages)
            for (const item of res?.data?.searchInternshipApplicants?.items) {
                item.attendence = await getInternshipAttendeesByStudentIdAndIntershipId(item)
            }
            setApplicants(res?.data?.searchInternshipApplicants?.items)
            showSpinner(false)

        } catch (error) {
            console.log(error)
            showSpinner(false)
        }
    }

    const getInternshipAttendeesByStudentIdAndIntershipId = async (data) => {
        try {
            let res = await API.graphql({
                query: searchInternshipAttendees,
                variables: {
                    filter: { and: { internshipId: { eq: id }, studentId: { eq: data?.studentId } } }
                }
            })
            return res?.data?.searchInternshipAttendees?.total || 0
        } catch (error) {
            console.log(error)
        }
    }

    const handlePage = (payload) => {
        if (payload === -1 && currentPage >= 2) {
            getInternApplicants((currentPage - 2) * limit)
            setCurrentPage((prev) => prev - 1)
        } else if (payload === 1 && currentPage < totalPages[totalPages.length - 1]) {
            getInternApplicants(currentPage * limit)
            setCurrentPage((prev) => prev + 1)
        }
    }

    const handleDeleteApplicant = async (id) => {
        try {
            await API.graphql({
                query: deleteInternshipApplicant,
                variables: { input: { id } }
            })
            setTimeout(() => {
                getInternApplicants()
            }, 2000)
            toast.success("Applicant removed successfully")


        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getInternshipById()
        getInternApplicants()
    }, [])


    return (
        <div>
            <Header title={internship?.title} name='Applicants'></Header>
            <div className="container-fluid">
                <InternshipMenu id={id} />
                <hr />
            </div>

            <div className='container-fluid pb-3'>
                <div className="card">
                    <div className="table-responsive">
                        <Spinner display={spinner}>
                            <table className="table table-sm table-hover card-table">
                                <thead >
                                    <tr>
                                        <th>Student Name</th>
                                        <th>Contact</th>
                                        <th className='text-center'>Enrolled On</th>
                                        <th className='text-center'>Attendance</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody className="list fs-base ">
                                    {
                                        (applicants.length > 0) ? applicants.map((applicant) => {

                                            return (
                                                <tr key={applicant.id} className={(applicant?.attendence > 0) ? "cursor-pointer" : ''} onClick={() => { applicant?.attendence && navigate(`${applicant?.studentId}`) }}>
                                                    <td>
                                                        {`${applicant?.student?.user?.firstName} ${`${applicant?.student?.user?.lastName}`}`}
                                                    </td>
                                                    <td >
                                                        {applicant?.student?.user?.phone}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            applicant?.createdAt ? <>
                                                                {moment(applicant?.createdAt).format('MMMM DD, YYYY')}
                                                                <div className='small text-muted'>{moment(applicant?.createdAt).format('h:mm a')}</div>
                                                            </> : "No data"
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {applicant?.attendence}
                                                    </td>
                                                    {isOwner(user?.role) &&
                                                        <td className='text-end text-muted' onClick={(event) => { event.stopPropagation() }}>
                                                            <div className='dropdown'>
                                                                <div className='dropdown-ellipses dropdown-toggle text-mute' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                                                    <i className='fe fe-more-vertical'></i>
                                                                </div>
                                                                <div className='dropdown-menu dropdown-menu-end'>
                                                                    <div className='dropdown-item cursor-pointer' onClick={(event) => { event.stopPropagation(); handleDeleteApplicant(applicant?.id) }}>
                                                                        Remove
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        }) :
                                            <tr className=' text-center text-muted'>
                                                <td colSpan={'4'}>
                                                    <h1 className='m-0'>
                                                        No data found
                                                    </h1>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spinner >
                        <div className="card-footer d-flex justify-content-between">
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item cursor-pointer">
                                    {currentPage > 1 ?
                                        <button className="page-link ps-0 pe-4 border-end" onClick={() => { handlePage(-1) }}>
                                            <i className="fe fe-arrow-left me-1"></i> Prev
                                        </button> : ""}

                                </li>
                            </ul>
                            <ul className="list-pagination pagination pagination-tabs card-pagination ">

                                {totalPages.map((page) => (
                                    <li className={`cursor-pointer ${(currentPage === page) ? 'active' : ''}`} onClick={() => { getInternApplicants((page - 1) * limit); setCurrentPage(page) }} key={page}>
                                        <span className='page'>{page}</span>
                                    </li>))}
                            </ul>
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination" >
                                <li className="page-item cursor-pointer">
                                    {currentPage < totalPages[totalPages.length - 1] ?
                                        <button className="page-link ps-4 pe-0 border-start" onClick={() => { handlePage(1) }}>
                                            Next <i className="fe fe-arrow-right ms-1"></i>
                                        </button>
                                        : ""}
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default InternshipApplicants
